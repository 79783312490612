// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { PlatformEntity } from "./JkPlatformjoint";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	platform: {
		"name": "platform",
		"type": "id",
		"isKey": false,
		"label": "Platform"
	} as FieldItemId,
	entityType: {
		"name": "entityType",
		"type": "id",
		"isKey": false,
		"label": "EntityType"
	} as FieldItemId,
	entityId: {
		"name": "entityId",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "EntityId"
	} as FieldItemString,
	content: {
		"name": "content",
		"isKey": false,
		"label": "Content"
	} as undefined,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.platform, fields.entityType, fields.entityId, fields.content, 
];

export const ui: UI = {
	label: "PlatformEntity",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: PlatformEntity):JSX.Element {
	return <>{uqStringify(item)}</>;
};
