// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { AuthorDeleted } from "./JkWebBuilder";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	name: {
		"name": "name",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Name"
	} as FieldItemString,
	penName: {
		"name": "penName",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "PenName"
	} as FieldItemString,
	mobile: {
		"name": "mobile",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Mobile"
	} as FieldItemString,
	email: {
		"name": "email",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Email"
	} as FieldItemString,
	employee: {
		"name": "employee",
		"type": "id",
		"isKey": false,
		"label": "Employee"
	} as FieldItemId,
	role: {
		"name": "role",
		"type": "id",
		"isKey": false,
		"label": "Role"
	} as FieldItemId,
	author: {
		"name": "author",
		"type": "id",
		"isKey": false,
		"label": "Author"
	} as FieldItemId,
	createDate: {
		"name": "createDate",
		"isKey": false,
		"label": "CreateDate"
	} as undefined,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.name, fields.penName, fields.mobile, fields.email, fields.employee, fields.role, fields.author, fields.createDate, 
];

export const ui: UI = {
	label: "AuthorDeleted",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: AuthorDeleted):JSX.Element {
	return <>{uqStringify(item)}</>;
};
