import { VPage, Page, EasyDate, Ax, nav, TabProp, TabCaptionComponent, Tabs } from 'tonva-react';
import { CPointProduct } from './CPointProduct';
import { observer } from 'mobx-react-lite';
import { CrPageHeaderTitle, pageHTitle } from 'tools/pageHeaderTitle';
import { ListTable } from 'tools/listTable';
import { makeObservable, observable } from 'mobx';
import { color } from 'order/VMyOrders';

export const ExchangeState: { [state: string | number]: string } = {
    12: "待发运",
    13: "已发运",
    14: "已完成",
    15: "已取消",
    "canceled": "已取消",
};
export class VExchangeHistory extends VPage<CPointProduct> {

    exchangeHistory: any[] = [];        /*积分产品列表 */

    private currentState: number = 0;
	private tabs: TabProp[];
	constructor(c: CPointProduct) {
        super(c);
        makeObservable(this, {
            exchangeHistory: observable
        });
    }
    async open(param?: any) {
        // this.exchangeHistory = this.toRepeat(param, "id");
        let arr: any[] = [];
        let obj: any = {};
        arr.filter((item:any) => obj[item["id"]] ? '' : (obj[item["id"]] = true));

        let oss = [
			{ caption: '待发运', state: 12, icon: 'desktop' },
			{ caption: '已发运', state: 13, icon: 'truck' },
			{ caption: '已取消', state: 15, icon: 'times-circle' },
			{ caption: '所有订单', state: 0, icon: 'file-text-o' },
		];
        this.tabs = oss.map(v => {
            let { caption, state, icon } = v;
            return {
                name: caption,
                caption: (selected: boolean) => TabCaptionComponent(caption, icon, color(selected)),
                content: () => {
                    return <div className='mb-3'>{this.historyListTable()}</div>
                },
                isSelected: this.currentState === state,
                load: async () => {
                    this.currentState = state;
                    this.exchangeHistory = await this.controller.getMyPointOrder(this.currentState);
                }
            };
        });
        this.openPage(this.page);
    }

    toRepeat = (arr: any[], key: string) => {
		let obj: any = {};
		return arr.filter(item => obj[item[key]] ? '' : (obj[item[key]] = true));
	};

    private renderExchangeHistory = (pointProduct: any, index: number) => {
        let { openOrderDetail } = this.controller;
        let { id, no, date, orderType } = pointProduct;
        return <div className="p-3 justify-content-between">
            <div className="mr-5" onClick={() => openOrderDetail(id, orderType)}><span className="small text-muted">兑换单号: </span><strong>{no}</strong></div>
            <div className="small text-muted"><EasyDate date={date} /></div>
        </div>;
    }

    private page = observer(() => {
        let header = CrPageHeaderTitle('兑换历史记录');
        return <Page header={header}>
            {pageHTitle('兑换历史记录')}
            <div className="d-none d-lg-block p-2 mt-2 breadcrumbs"><Ax href="/pointshop" >商城首页 </Ax> 兑换记录</div>
            {/* <List items={this.exchangeHistory} item={{ render: this.renderExchangeHistory }} none="暂无记录"></List> */}
            <Tabs tabs={this.tabs} tabPosition="top" />
            {/* {this.historyListTable()} */}
        </Page>;
    });

    historyListTable = () => {
        if (!this.exchangeHistory.length)
            return <div className="my-5 text-secondary d-flex justify-content-center">暂无记录</div>;
        let columns = [{ id: 1, name: '兑换单号' },{ id: 2, name: '日期' }, { id: 3, name: '状态' },{ id: 4, name: '详情' }];
        let content = <>{
            this.exchangeHistory.map((v: any) => {
                let { id, date, no, state } = v;
                return <tr className="article-product-list order-wrap-list" key={id}>
                    <td data-title={columns[0].name}>{no}</td>
                    <td data-title={columns[1].name}><EasyDate date={date} /></td>
                    <td data-title={columns[2].name}>{ExchangeState[state]}</td>
                    <td data-title={columns[3].name}>
                        <button onClick={() => nav.navigate("/pointshop/orderDetail/" + id) /* this.controller.openOrderDetail(id) */}
                            type='button' className="btn-primary w-4c">详情</button>
                    </td>
                </tr>
            })
        }</>;
        return <ListTable columns={columns} content={content} ></ListTable>;
    }
}