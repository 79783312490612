// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { ShelfBlockInOutHistory } from "./JkWarehouse";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	no: {
		"name": "no",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "No"
	} as FieldItemString,
	shelfBlock: {
		"name": "shelfBlock",
		"type": "id",
		"isKey": false,
		"label": "ShelfBlock"
	} as FieldItemId,
	product: {
		"name": "product",
		"type": "id",
		"isKey": false,
		"label": "Product"
	} as FieldItemId,
	pack: {
		"name": "pack",
		"type": "id",
		"isKey": false,
		"label": "Pack"
	} as FieldItemId,
	lotNumber: {
		"name": "lotNumber",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "LotNumber"
	} as FieldItemString,
	quantity: {
		"name": "quantity",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "Quantity"
	} as FieldItemInt,
	inOutType: {
		"name": "inOutType",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "InOutType"
	} as FieldItemInt,
	inOutReason: {
		"name": "inOutReason",
		"type": "id",
		"isKey": false,
		"label": "InOutReason"
	} as FieldItemId,
	biz: {
		"name": "biz",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Biz"
	} as FieldItemString,
	bizNo: {
		"name": "bizNo",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "BizNo"
	} as FieldItemString,
	parent: {
		"name": "parent",
		"type": "id",
		"isKey": false,
		"label": "Parent"
	} as FieldItemId,
	stockType: {
		"name": "stockType",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "StockType"
	} as FieldItemString,
	inOutBoundTime: {
		"name": "inOutBoundTime",
		"isKey": false,
		"label": "InOutBoundTime"
	} as undefined,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.no, fields.shelfBlock, fields.product, fields.pack, fields.lotNumber, fields.quantity, fields.inOutType, fields.inOutReason, fields.biz, fields.bizNo, fields.parent, fields.stockType, fields.inOutBoundTime, 
];

export const ui: UI = {
	label: "ShelfBlockInOutHistory",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: ShelfBlockInOutHistory):JSX.Element {
	return <>{uqStringify(item)}</>;
};
