import CryptoJS from 'crypto-js';

let AuthTokenKey = "jk-cart-next"; //AES密钥
let AuthTokenIv = 'jk-cart-next'; //AES向量

/*AES加密*/
export function Encrypt(data: any) {
    let dataStr = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    let encrypted = CryptoJS.AES.encrypt(dataStr, CryptoJS.enc.Latin1.parse(AuthTokenKey), {
        iv: CryptoJS.enc.Latin1.parse(AuthTokenIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

/*AES解密*/
export function Decrypt(data: any) {
    let decrypted = CryptoJS.AES.decrypt(data, CryptoJS.enc.Latin1.parse(AuthTokenKey), {
        iv: CryptoJS.enc.Latin1.parse(AuthTokenIv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}
