//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID, UqIDX, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/platformjoint ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidPlatform {
	id?: number;
	name: string;
}

export interface TuidCity {
	id?: number;
}

export interface TuidCounty {
	id?: number;
}

export interface TuidProvince {
	id?: number;
}

export interface TuidJDCity {
	id?: number;
	province: number;
	englishName: string;
	chineseName: string;
	isValid: number;
	order: number;
}

export interface TuidJDCounty {
	id?: number;
	city: number;
	englishName: string;
	chineseName: string;
	isValid: number;
	order: number;
}

export interface TuidJDTown {
	id?: number;
	county: number;
	englishName: string;
	chineseName: string;
	isValid: number;
	order: number;
}

export interface TuidJDProvince {
	id?: number;
	country: number;
	englishName: string;
	chineseName: string;
	isValid: number;
	order: number;
}

export interface TuidPlatformCustomer {
	id?: number;
	platform: number;
	idOnPlatform: string;
	name: string;
	organizationName: string;
	phone: string;
	departmentName: string;
	laboratoryName: string;
}

export interface TuidCustomer {
	id?: number;
}

export interface TuidEpecProvince {
	id?: number;
	country: number;
	englishName: string;
	chineseName: string;
	code: string;
	isValid: number;
	order: number;
}

export interface TuidEpecCity {
	id?: number;
	province: number;
	englishName: string;
	chineseName: string;
	code: string;
	isValid: number;
	order: number;
}

export interface TuidEpecCounty {
	id?: number;
	city: number;
	englishName: string;
	chineseName: string;
	code: string;
	isValid: number;
	order: number;
}

export interface TuidWebUser {
	id?: number;
}

export interface TuidEpecMessage {
	id?: number;
	content: string;
	createtime: any;
}

export interface TuidEpecMessageData {
	id?: number;
	epecMessage: number;
	messageId: number;
	messageSortNumber: number;
	messageBody: string;
	messageType: string;
	receivedTime: any;
	createtime: any;
}

export interface TuidApiRawContent {
	id?: number;
	platform: number;
	api: string;
	content: string;
	avoidDuplicationId: string;
	create: any;
}

export interface TuidExpressLogistics {
	id?: number;
}

export interface TuidPunchoutSetupRequest {
	id?: number;
	platform: number;
	fromDomain: string;
	fromIdentity: string;
	toDomain: string;
	toIdentity: string;
	senderDomain: string;
	senderIdentity: string;
	senderUserAgent: string;
	senderSharedSecret: string;
	browserFormPostUrl: string;
	buyerCookie: string;
	payloadID: string;
	body: string;
}

export interface TuidOrganization {
	id?: number;
}

export interface TuidPlatformCustomers {
	id?: number;
	platform: number;
	platformCustomerId: string;
	content: string;
}

export interface TuidMessage {
	id?: number;
	platform: number;
	messageBody: string;
	type: number;
	createtime: any;
	state: number;
}

export interface TuidTanMoProductSourceData {
	id?: number;
	platform: number;
	tmcode: number;
	origin: string;
	descriptionC: string;
	package: string;
	purity: string;
	cas: string;
	catalogPrice: number;
	brandName: string;
	discount: string;
	stock: number;
	deliveryTime: string;
	status: string;
	Category1: string;
	Category2: string;
	Category3: string;
	remark: string;
}

export interface TuidLabmai_Batch {
	id?: number;
	startPointer: number;
	endPointer: number;
	pointerCount: number;
	content: string;
	platformTaskNo: string;
	platformTaskNoStatus: string;
	result: number;
}

export interface TuidWshotoCategory {
	id?: number;
	wshotoId: number;
	parent_id: number;
	name: string;
	level: number;
	media_type: number;
	createtime: any;
}

export interface TuidPlatformOrder {
	id?: number;
	platform: number;
	platformOrderId: string;
	platformOrderContent: string;
	createdate: any;
}

export interface TuidPlatformOrganizations {
	id?: number;
	platform: number;
	platformOrganizationId: string;
	content: string;
}

export interface TuidPlatformOrderAuditReason {
	id?: number;
	name: string;
}

export interface TuidPushBatch {
	id?: number;
	platform: number;
	state: number;
	size: number;
	priority: number;
	action: string;
	createTime: any;
	content: string;
}

export interface TuidPushBatchHistory {
	id?: number;
	pushBatch: number;
	state: number;
	recommend: string;
	opreateTime: any;
	createTime: any;
}

export interface TuidNotifyPayment {
	id?: number;
	webuser: string;
	OrderNo: string;
	tradeNo: string;
	outTradeNo: string;
	payWay: string;
	totalFee: number;
	statues: string;
	createDate: any;
}

export interface TuidPlatformSalesTask {
	id?: number;
	platform: number;
	platformSalesTaskId: string;
	taskType: number;
	content: string;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface ParamAuditPlatformCustomerRefuse {
	platformCustomer: number;
	reason: string;
}
export interface ResultAuditPlatformCustomerRefuse {
}

export interface ParamApplyAuditPlatformCustomer {
	platformCustomer: number;
}
export interface ResultApplyAuditPlatformCustomer {
}

export interface ParamAddPlatformCustomerAuditPending {
	platformCustomer: number;
	comments: string;
	orderid: string;
	consigneeName: string;
	consigneePhone: string;
	receivingAddress: string;
	invoiceTitle: string;
	isCentralizedInspection: number;
	orderState: string;
	inspectionAddress: string;
	orderItemId: string;
	descriptionC: string;
	description: string;
	originalId: string;
	brandName: string;
	package: string;
	casFormat: string;
	catalogPrice: number;
	quantity: number;
	purity: string;
}
export interface ResultAddPlatformCustomerAuditPending {
}

export interface ParamSaveCancelOrder {
	pendingid: number;
	platform: number;
	order: number;
	reason: string;
	content: string;
	confirmStatus: number;
	refuseReason: string;
}
export interface ResultSaveCancelOrder {
}

export interface ParamSaveInvoiceRequest {
	pendingid: number;
	platform: number;
	invoiceRequestId: string;
	invoiceStatus: number;
	cancelReason: string;
	content: string;
}
export interface ResultSaveInvoiceRequest {
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamSaveMemberInfoRequest {
	pendingid: number;
	platform: number;
	webUser: number;
	companyId: string;
	companyName: string;
	memberId: string;
	memberName: string;
	realName: string;
	mobile: string;
	email: string;
	operateType: string;
	confirmResult: number;
	content: string;
}
export interface ResultSaveMemberInfoRequest {
}

export interface ParamAddOrderMap {
	id: number;
	no: string;
	platform: number;
}
export interface ResultAddOrderMap {
}

export interface ParamAddOrderDetailMap {
	id: number;
	no: string;
	platform: number;
}
export interface ResultAddOrderDetailMap {
}

export interface ParamSaveWshotoCategoryMap {
	wshotoCategory: number;
	categorys: {
		productCategory: number;
	}[];

}
export interface ResultSaveWshotoCategoryMap {
}

export interface ParamAuditPlatformOrderPending {
	id: number;
	platformCustomersId: number;
	auditor: number;
	countryId: string;
	privinceId: string;
	cityId: string;
	countyId: string;
	comments: string;
	platform: number;
	platformOrderId: string;
}
export interface ResultAuditPlatformOrderPending {
}

export interface ParamAuditPlatformOrderRefuse {
	id: number;
	auditor: number;
	comments: string;
}
export interface ResultAuditPlatformOrderRefuse {
}

export interface ParamActAuditPendingPlatformCustomer {
	id: number;
	customer: number;
	result: number;
	comments: string;
	employee: number;
}
export interface ResultActAuditPendingPlatformCustomer {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface ParamDelWshotoCategoryMap {
	id: number;
	wshotoCategory: number;
	productCategory: number;
}
export interface ResultDelWshotoCategoryMap {
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamGetplatformCustomerID {
	platform: number;
	idOnPlatform: string;
}
export interface ReturnGetplatformCustomerIDRet {
	id: number;
}
export interface ResultGetplatformCustomerID {
	ret: ReturnGetplatformCustomerIDRet[];
}

export interface ParamGetPlatformCustomerAuditOrderPending {
	platformCustomer: number;
}
export interface ReturnGetPlatformCustomerAuditOrderPendingRet {
	orderid: string;
	date: any;
}
export interface ResultGetPlatformCustomerAuditOrderPending {
	ret: ReturnGetPlatformCustomerAuditOrderPendingRet[];
}

export interface ParamSearchHavingAuditRefusePlatformCustomer {
}
export interface ReturnSearchHavingAuditRefusePlatformCustomer$page {
	date: any;
	platformcustomer: number;
	reason: string;
}
export interface ResultSearchHavingAuditRefusePlatformCustomer {
	$page: ReturnSearchHavingAuditRefusePlatformCustomer$page[];
}

export interface ParamGetOrderDetails {
	orderid: string;
}
export interface ReturnGetOrderDetailsRet {
	id: number;
	orderid: string;
	consigneeName: string;
	consigneePhone: string;
	receivingAddress: string;
	invoiceTitle: string;
	isCentralizedInspection: number;
	orderState: string;
	inspectionAddress: string;
	orderItemId: string;
	descriptionC: string;
	description: string;
	originalId: string;
	brandName: string;
	package: string;
	casFormat: string;
	catalogPrice: number;
	quantity: number;
	purity: string;
	comments: string;
}
export interface ResultGetOrderDetails {
	ret: ReturnGetOrderDetailsRet[];
}

export interface ParamSearchHavingAuditPlatformCustomer {
}
export interface ReturnSearchHavingAuditPlatformCustomer$page {
	date: any;
	platformcustomer: number;
	customer: number;
}
export interface ResultSearchHavingAuditPlatformCustomer {
	$page: ReturnSearchHavingAuditPlatformCustomer$page[];
}

export interface ParamGetPendingAuditPlatformCustomer {
}
export interface ReturnGetPendingAuditPlatformCustomerRet {
	platformcustomer: number;
}
export interface ResultGetPendingAuditPlatformCustomer {
	ret: ReturnGetPendingAuditPlatformCustomerRet[];
}

export interface ParamGetNewOrderItemId {
	platform: number;
}
export interface ResultGetNewOrderItemId {
}

export interface ParamGetNewOrderId {
	platform: number;
}
export interface ResultGetNewOrderId {
}

export interface ParamSearchEpecUser {
	key: string;
}
export interface ReturnSearchEpecUser$page {
	webUser: number;
	username: string;
	organizationName: string;
	mobile: string;
	email: string;
	isAdmin: number;
}
export interface ResultSearchEpecUser {
	$page: ReturnSearchEpecUser$page[];
}

export interface ParamSearchNeoTridentUser {
	key: string;
}
export interface ReturnSearchNeoTridentUserRet {
	sharedSecret: string;
	webUser: number;
	username: string;
	organization: string;
	team: string;
}
export interface ResultSearchNeoTridentUser {
	ret: ReturnSearchNeoTridentUserRet[];
}

export interface ParamSearchEpecOrganization {
	key: string;
}
export interface ReturnSearchEpecOrganization$page {
	organization: number;
	organizationId: string;
	organizationName: string;
}
export interface ResultSearchEpecOrganization {
	$page: ReturnSearchEpecOrganization$page[];
}

export interface ParamGetOrderIdByNo {
	orderNo: string;
}
export interface ReturnGetOrderIdByNoRet {
	orderId: number;
}
export interface ResultGetOrderIdByNo {
	ret: ReturnGetOrderIdByNoRet[];
}

export interface ParamSearchInvoiceRequestPending {
	invoiceRequestId: number;
}
export interface ReturnSearchInvoiceRequestPendingRet {
	id: number;
	platform: number;
	invoiceRequestId: string;
	content: string;
	createtime: any;
}
export interface ResultSearchInvoiceRequestPending {
	ret: ReturnSearchInvoiceRequestPendingRet[];
}

export interface ParamSearchCancelOrderPending {
	orderId: number;
}
export interface ReturnSearchCancelOrderPendingRet {
	id: number;
	platform: number;
	order: number;
	reason: string;
	content: string;
	createtime: any;
}
export interface ResultSearchCancelOrderPending {
	ret: ReturnSearchCancelOrderPendingRet[];
}

export interface ParamSearchInvoiceApplys {
}
export interface ReturnSearchInvoiceApplys$page {
	id: number;
	platform: number;
	invoiceRequestId: string;
	content: string;
	createtime: any;
}
export interface ResultSearchInvoiceApplys {
	$page: ReturnSearchInvoiceApplys$page[];
}

export interface ParamSearchCancelOrderApplys {
}
export interface ReturnSearchCancelOrderApplys$page {
	id: number;
	platform: number;
	order: number;
	reason: string;
	content: string;
	createtime: any;
}
export interface ResultSearchCancelOrderApplys {
	$page: ReturnSearchCancelOrderApplys$page[];
}

export interface ParamGetBatchInputFailedItem {
	batchInput: number;
}
export interface ReturnGetBatchInputFailedItemRet {
	id: number;
	queueInId: number;
	data: string;
	reason: string;
	moniker: number;
}
export interface ResultGetBatchInputFailedItem {
	ret: ReturnGetBatchInputFailedItemRet[];
}

export interface ParamGetBatchInputMoniker {
}
export interface ReturnGetBatchInputMonikerRet {
	id: number;
	name: string;
	description: string;
	moniker: string;
	monikerId: number;
}
export interface ResultGetBatchInputMoniker {
	ret: ReturnGetBatchInputMonikerRet[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface ParamSearchMemberApplys {
	key: string;
	state: string;
}
export interface ReturnSearchMemberApplys$page {
	id: number;
	platform: number;
	companyId: string;
	companyName: string;
	memberId: string;
	memberName: string;
	realName: string;
	mobile: string;
	email: string;
	operateType: string;
	content: string;
	createtime: any;
}
export interface ResultSearchMemberApplys {
	$page: ReturnSearchMemberApplys$page[];
}

export interface ParamGetAllPlatformCustomerAuditOrderPending {
}
export interface ReturnGetAllPlatformCustomerAuditOrderPendingRet {
	orderid: string;
	date: string;
	platformname: string;
	consigneename: string;
}
export interface ResultGetAllPlatformCustomerAuditOrderPending {
	ret: ReturnGetAllPlatformCustomerAuditOrderPendingRet[];
}

export interface ParamGetWshotoCategoryMapByWshotoId {
	wshotoCategory: number;
}
export interface ReturnGetWshotoCategoryMapByWshotoId$page {
	id: number;
	wshotoCategory: number;
	productcategory: number;
}
export interface ResultGetWshotoCategoryMapByWshotoId {
	$page: ReturnGetWshotoCategoryMapByWshotoId$page[];
}

export interface ParamGetMappedPlatformOrderId {
	id: number;
	platform: number;
}
export interface ReturnGetMappedPlatformOrderIdRet {
	id: number;
	no: string;
	platform: number;
}
export interface ResultGetMappedPlatformOrderId {
	ret: ReturnGetMappedPlatformOrderIdRet[];
}

export interface ParamGetMappedPlatformOrderDetailId {
	id: number;
	platform: number;
}
export interface ReturnGetMappedPlatformOrderDetailIdRet {
	id: number;
	no: string;
	platform: number;
}
export interface ResultGetMappedPlatformOrderDetailId {
	ret: ReturnGetMappedPlatformOrderDetailIdRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface ParamGetPendingAuditPlatformOrder {
}
export interface ReturnGetPendingAuditPlatformOrderRet {
	id: number;
	platform: number;
	platformorderid: string;
	platformordercontent: string;
}
export interface ResultGetPendingAuditPlatformOrder {
	ret: ReturnGetPendingAuditPlatformOrderRet[];
}

export interface ParamGetNeoTridentUserByUser {
	webUser: number;
}
export interface ReturnGetNeoTridentUserByUserRet {
	sharedSecret: string;
	webUser: number;
	username: string;
	organization: string;
	team: string;
}
export interface ResultGetNeoTridentUserByUser {
	ret: ReturnGetNeoTridentUserByUserRet[];
}

export interface ParamGetPlatformCustomers {
	platform: number;
	platformCustomerId: string;
}
export interface ReturnGetPlatformCustomersRet {
	webUser: number;
	platformCustomers: number;
}
export interface ResultGetPlatformCustomers {
	ret: ReturnGetPlatformCustomersRet[];
}

export interface ParamGetPlatformOrganizations {
	platform: number;
	platformOrganizationId: string;
}
export interface ReturnGetPlatformOrganizationsRet {
	id: number;
	content: string;
}
export interface ResultGetPlatformOrganizations {
	ret: ReturnGetPlatformOrganizationsRet[];
}

export interface ParamGetPlatformOrderPendingReason {
	platformOrderId: number;
}
export interface ReturnGetPlatformOrderPendingReasonRet {
	name: string;
	detail: string;
}
export interface ResultGetPlatformOrderPendingReason {
	ret: ReturnGetPlatformOrderPendingReasonRet[];
}

export interface ParamGetPlatformOrderAuditResult {
	platform: number;
	result: number;
	pagesize: number;
}
export interface ReturnGetPlatformOrderAuditResultRet {
	id: number;
	platformordercontent: string;
	auditor: number;
	comments: string;
	date: any;
}
export interface ResultGetPlatformOrderAuditResult {
	ret: ReturnGetPlatformOrderAuditResultRet[];
}

export interface ParamGetAuditPendingPlatformCustomers {
}
export interface ReturnGetAuditPendingPlatformCustomersRet {
	id: number;
	platform: number;
	platformCustomerId: string;
	content: string;
	total: number;
}
export interface ResultGetAuditPendingPlatformCustomers {
	ret: ReturnGetAuditPendingPlatformCustomersRet[];
}

export interface ParamGetPlatformCustomersAuditHistory {
	state: number;
}
export interface ReturnGetPlatformCustomersAuditHistory$page {
	id: number;
	date: any;
	platformCustomer: number;
	result: number;
	comments: string;
	auditor: number;
}
export interface ResultGetPlatformCustomersAuditHistory {
	$page: ReturnGetPlatformCustomersAuditHistory$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface ParamGetWshotoPostMapping {
	entityType: number;
}
export interface ReturnGetWshotoPostMapping$page {
	id: number;
	post: number;
	wshotoPost: number;
	entityId: string;
	content: string;
}
export interface ResultGetWshotoPostMapping {
	$page: ReturnGetWshotoPostMapping$page[];
}

export interface ParamGetWshotoFailPost {
}
export interface ReturnGetWshotoFailPost$page {
	id: number;
	post: number;
	comments: string;
	createTime: any;
}
export interface ResultGetWshotoFailPost {
	$page: ReturnGetWshotoFailPost$page[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface BatchInput {
	id?: number;
	BatchInputTemplate: number;
	name: string;
	creator: number;
}

export interface BatchInputFailedItem {
	id?: number;
	reason: string;
}

export interface EntityType {
	id?: number;
	name: string;
}

export interface PlatformEntity {
	id?: number;
	platform: number;
	entityType: number;
	entityId: string;
	content: string;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface WshotoFailPost {
	id?: number;
	post: number;
	comments: string;
	createTime: any;
}

export interface TaskType {
	id?: number;
	name: string;
}

export interface BatchInputTemplate {
	id?: number;
	name: string;
	description: string;
}

export interface BatchInputDx {
	id: number;
	count?: number;
	success?: number;
	failed?: number;
	enddate?: any;
	$act?: number;
}

export interface ActParamBatchInputDx {
	id: number|IDXValue;
	count?: number|IDXValue;
	success?: number|IDXValue;
	failed?: number|IDXValue;
	enddate?: any|IDXValue;
	$act?: number;
}

export interface BatchInputItem {
	ix: number;
	xi: number;
}

export interface BatchInputTemplateMoniker {
	ix: number;
	xi: number;
}

export interface ParamActs {
	$User?: $User[];
	batchInput?: BatchInput[];
	batchInputFailedItem?: BatchInputFailedItem[];
	entityType?: EntityType[];
	platformEntity?: PlatformEntity[];
	$phrase?: $phrase[];
	$UserSite?: $UserSite[];
	$Site?: $Site[];
	wshotoFailPost?: WshotoFailPost[];
	taskType?: TaskType[];
	batchInputTemplate?: BatchInputTemplate[];
	batchInputDx?: ActParamBatchInputDx[];
	batchInputItem?: BatchInputItem[];
	batchInputTemplateMoniker?: BatchInputTemplateMoniker[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	Platform: UqTuid<TuidPlatform>;
	City: UqTuid<TuidCity>;
	County: UqTuid<TuidCounty>;
	Province: UqTuid<TuidProvince>;
	JDCity: UqTuid<TuidJDCity>;
	JDCounty: UqTuid<TuidJDCounty>;
	JDTown: UqTuid<TuidJDTown>;
	JDProvince: UqTuid<TuidJDProvince>;
	PlatformCustomer: UqTuid<TuidPlatformCustomer>;
	Customer: UqTuid<TuidCustomer>;
	EpecProvince: UqTuid<TuidEpecProvince>;
	EpecCity: UqTuid<TuidEpecCity>;
	EpecCounty: UqTuid<TuidEpecCounty>;
	WebUser: UqTuid<TuidWebUser>;
	EpecMessage: UqTuid<TuidEpecMessage>;
	EpecMessageData: UqTuid<TuidEpecMessageData>;
	ApiRawContent: UqTuid<TuidApiRawContent>;
	ExpressLogistics: UqTuid<TuidExpressLogistics>;
	PunchoutSetupRequest: UqTuid<TuidPunchoutSetupRequest>;
	Organization: UqTuid<TuidOrganization>;
	PlatformCustomers: UqTuid<TuidPlatformCustomers>;
	Message: UqTuid<TuidMessage>;
	TanMoProductSourceData: UqTuid<TuidTanMoProductSourceData>;
	Labmai_Batch: UqTuid<TuidLabmai_Batch>;
	WshotoCategory: UqTuid<TuidWshotoCategory>;
	PlatformOrder: UqTuid<TuidPlatformOrder>;
	PlatformOrganizations: UqTuid<TuidPlatformOrganizations>;
	PlatformOrderAuditReason: UqTuid<TuidPlatformOrderAuditReason>;
	PushBatch: UqTuid<TuidPushBatch>;
	PushBatchHistory: UqTuid<TuidPushBatchHistory>;
	NotifyPayment: UqTuid<TuidNotifyPayment>;
	PlatformSalesTask: UqTuid<TuidPlatformSalesTask>;
	AuditPlatformCustomerRefuse: UqAction<ParamAuditPlatformCustomerRefuse, ResultAuditPlatformCustomerRefuse>;
	ApplyAuditPlatformCustomer: UqAction<ParamApplyAuditPlatformCustomer, ResultApplyAuditPlatformCustomer>;
	AddPlatformCustomerAuditPending: UqAction<ParamAddPlatformCustomerAuditPending, ResultAddPlatformCustomerAuditPending>;
	SaveCancelOrder: UqAction<ParamSaveCancelOrder, ResultSaveCancelOrder>;
	SaveInvoiceRequest: UqAction<ParamSaveInvoiceRequest, ResultSaveInvoiceRequest>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	SaveMemberInfoRequest: UqAction<ParamSaveMemberInfoRequest, ResultSaveMemberInfoRequest>;
	AddOrderMap: UqAction<ParamAddOrderMap, ResultAddOrderMap>;
	AddOrderDetailMap: UqAction<ParamAddOrderDetailMap, ResultAddOrderDetailMap>;
	SaveWshotoCategoryMap: UqAction<ParamSaveWshotoCategoryMap, ResultSaveWshotoCategoryMap>;
	AuditPlatformOrderPending: UqAction<ParamAuditPlatformOrderPending, ResultAuditPlatformOrderPending>;
	AuditPlatformOrderRefuse: UqAction<ParamAuditPlatformOrderRefuse, ResultAuditPlatformOrderRefuse>;
	ActAuditPendingPlatformCustomer: UqAction<ParamActAuditPendingPlatformCustomer, ResultActAuditPendingPlatformCustomer>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	DelWshotoCategoryMap: UqAction<ParamDelWshotoCategoryMap, ResultDelWshotoCategoryMap>;
	$poked: UqQuery<Param$poked, Result$poked>;
	GetplatformCustomerID: UqQuery<ParamGetplatformCustomerID, ResultGetplatformCustomerID>;
	GetPlatformCustomerAuditOrderPending: UqQuery<ParamGetPlatformCustomerAuditOrderPending, ResultGetPlatformCustomerAuditOrderPending>;
	SearchHavingAuditRefusePlatformCustomer: UqQuery<ParamSearchHavingAuditRefusePlatformCustomer, ResultSearchHavingAuditRefusePlatformCustomer>;
	GetOrderDetails: UqQuery<ParamGetOrderDetails, ResultGetOrderDetails>;
	SearchHavingAuditPlatformCustomer: UqQuery<ParamSearchHavingAuditPlatformCustomer, ResultSearchHavingAuditPlatformCustomer>;
	GetPendingAuditPlatformCustomer: UqQuery<ParamGetPendingAuditPlatformCustomer, ResultGetPendingAuditPlatformCustomer>;
	GetNewOrderItemId: UqQuery<ParamGetNewOrderItemId, ResultGetNewOrderItemId>;
	GetNewOrderId: UqQuery<ParamGetNewOrderId, ResultGetNewOrderId>;
	SearchEpecUser: UqQuery<ParamSearchEpecUser, ResultSearchEpecUser>;
	SearchNeoTridentUser: UqQuery<ParamSearchNeoTridentUser, ResultSearchNeoTridentUser>;
	SearchEpecOrganization: UqQuery<ParamSearchEpecOrganization, ResultSearchEpecOrganization>;
	GetOrderIdByNo: UqQuery<ParamGetOrderIdByNo, ResultGetOrderIdByNo>;
	SearchInvoiceRequestPending: UqQuery<ParamSearchInvoiceRequestPending, ResultSearchInvoiceRequestPending>;
	SearchCancelOrderPending: UqQuery<ParamSearchCancelOrderPending, ResultSearchCancelOrderPending>;
	SearchInvoiceApplys: UqQuery<ParamSearchInvoiceApplys, ResultSearchInvoiceApplys>;
	SearchCancelOrderApplys: UqQuery<ParamSearchCancelOrderApplys, ResultSearchCancelOrderApplys>;
	GetBatchInputFailedItem: UqQuery<ParamGetBatchInputFailedItem, ResultGetBatchInputFailedItem>;
	GetBatchInputMoniker: UqQuery<ParamGetBatchInputMoniker, ResultGetBatchInputMoniker>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	SearchMemberApplys: UqQuery<ParamSearchMemberApplys, ResultSearchMemberApplys>;
	GetAllPlatformCustomerAuditOrderPending: UqQuery<ParamGetAllPlatformCustomerAuditOrderPending, ResultGetAllPlatformCustomerAuditOrderPending>;
	GetWshotoCategoryMapByWshotoId: UqQuery<ParamGetWshotoCategoryMapByWshotoId, ResultGetWshotoCategoryMapByWshotoId>;
	GetMappedPlatformOrderId: UqQuery<ParamGetMappedPlatformOrderId, ResultGetMappedPlatformOrderId>;
	GetMappedPlatformOrderDetailId: UqQuery<ParamGetMappedPlatformOrderDetailId, ResultGetMappedPlatformOrderDetailId>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	GetPendingAuditPlatformOrder: UqQuery<ParamGetPendingAuditPlatformOrder, ResultGetPendingAuditPlatformOrder>;
	GetNeoTridentUserByUser: UqQuery<ParamGetNeoTridentUserByUser, ResultGetNeoTridentUserByUser>;
	GetPlatformCustomers: UqQuery<ParamGetPlatformCustomers, ResultGetPlatformCustomers>;
	GetPlatformOrganizations: UqQuery<ParamGetPlatformOrganizations, ResultGetPlatformOrganizations>;
	GetPlatformOrderPendingReason: UqQuery<ParamGetPlatformOrderPendingReason, ResultGetPlatformOrderPendingReason>;
	GetPlatformOrderAuditResult: UqQuery<ParamGetPlatformOrderAuditResult, ResultGetPlatformOrderAuditResult>;
	GetAuditPendingPlatformCustomers: UqQuery<ParamGetAuditPendingPlatformCustomers, ResultGetAuditPendingPlatformCustomers>;
	GetPlatformCustomersAuditHistory: UqQuery<ParamGetPlatformCustomersAuditHistory, ResultGetPlatformCustomersAuditHistory>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	GetWshotoPostMapping: UqQuery<ParamGetWshotoPostMapping, ResultGetWshotoPostMapping>;
	GetWshotoFailPost: UqQuery<ParamGetWshotoFailPost, ResultGetWshotoFailPost>;
	AccessToken: UqMap;
	JDCityMapping: UqMap;
	JDProvinceMapping: UqMap;
	JDCountyMapping: UqMap;
	EpecUser: UqMap;
	EpecCountyMapping: UqMap;
	EpecCityMapping: UqMap;
	NeoTridentUser: UqMap;
	EpecProvinceMapping: UqMap;
	OrderItemIds: UqMap;
	OrderItemIdPointer: UqMap;
	OrderIdPointer: UqMap;
	OrderIds: UqMap;
	TransCompanyMapping: UqMap;
	EpecOrganization: UqMap;
	PlatformInformation: UqMap;
	AccessTokenOut: UqMap;
	WebUserPlatformCustomers: UqMap;
	ProductPackOutMapping: UqMap;
	ProductPackMapping: UqMap;
	ProductPackInMapping: UqMap;
	WshotoCategoryMapping: UqMap;
	Map_Orderdetail: UqMap;
	Map_Order: UqMap;
	PlatformCustomersAddress: UqMap;
	PlatformOrderPending: UqMap;
	PlatformOrderPendingReason: UqMap;
	EntityAuditPending: UqMap;
	Map_Customer: UqMap;
	Map_SalesTask: UqMap;
	$User: UqID<any>;
	BatchInput: UqID<any>;
	BatchInputFailedItem: UqID<any>;
	EntityType: UqID<any>;
	PlatformEntity: UqID<any>;
	$phrase: UqID<any>;
	$UserSite: UqID<any>;
	$Site: UqID<any>;
	WshotoFailPost: UqID<any>;
	TaskType: UqID<any>;
	BatchInputTemplate: UqID<any>;
	BatchInputDx: UqIDX<any>;
	BatchInputItem: UqIX<any>;
	BatchInputTemplateMoniker: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
