//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/chemical ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidChemical {
	id?: number;
	no: number;
	CAS: string;
	description: string;
	descriptoinCN: string;
	molecularFomula: string;
	molecularWeight: number;
	mdlNumber: string;
}

export interface TuidStorageCondition {
	id?: number;
	name: string;
	no: string;
}

export interface TuidJNKRestrict {
	id?: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface ParamSearchChemical {
	key: string;
}
export interface ReturnSearchChemical$page {
	id: number;
	no: number;
	CAS: string;
	description: string;
	descriptoinCN: string;
	molecularFomula: string;
	molecularWeight: number;
	mdlNumber: string;
}
export interface ResultSearchChemical {
	$page: ReturnSearchChemical$page[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface ParamGetStorageCondition {
	language: number;
}
export interface ReturnGetStorageConditionRet {
	id: number;
	xi: number;
	description: string;
	no: string;
}
export interface ResultGetStorageCondition {
	ret: ReturnGetStorageConditionRet[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface ParamGetChemicalByNo {
	no: number;
}
export interface ReturnGetChemicalByNoRet {
	id: number;
	no: number;
	CAS: string;
	description: string;
	descriptoinCN: string;
	molecularFomula: string;
	molecularWeight: number;
	mdlNumber: string;
}
export interface ResultGetChemicalByNo {
	ret: ReturnGetChemicalByNoRet[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface StorageConditionSpecial {
	id?: number;
	no?: string;
	name: string;
	description: string;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface GHSPictogram {
	id?: number;
	no?: string;
	code: string;
	description: string;
}

export interface GHSHazardStatement {
	id?: number;
	no?: string;
	code: string;
	description: string;
}

export interface GHSPrecautionaryStatement {
	id?: number;
	no?: string;
	code: string;
	description: string;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface ParamActs {
	$User?: $User[];
	storageConditionSpecial?: StorageConditionSpecial[];
	$phrase?: $phrase[];
	gHSPictogram?: GHSPictogram[];
	gHSHazardStatement?: GHSHazardStatement[];
	gHSPrecautionaryStatement?: GHSPrecautionaryStatement[];
	$Site?: $Site[];
	$UserSite?: $UserSite[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	Chemical: UqTuid<TuidChemical>;
	StorageCondition: UqTuid<TuidStorageCondition>;
	JNKRestrict: UqTuid<TuidJNKRestrict>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	SearchChemical: UqQuery<ParamSearchChemical, ResultSearchChemical>;
	$poked: UqQuery<Param$poked, Result$poked>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	GetStorageCondition: UqQuery<ParamGetStorageCondition, ResultGetStorageCondition>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	GetChemicalByNo: UqQuery<ParamGetChemicalByNo, ResultGetChemicalByNo>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	ChemicalJNKRestrict: UqMap;
	$User: UqID<any>;
	StorageConditionSpecial: UqID<any>;
	$phrase: UqID<any>;
	GHSPictogram: UqID<any>;
	GHSHazardStatement: UqID<any>;
	GHSPrecautionaryStatement: UqID<any>;
	$Site: UqID<any>;
	$UserSite: UqID<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
