//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID, UqIDX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/webuser ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface TuidInvoiceInfo {
	id?: number;
	title: string;
	taxNo: string;
	address: string;
	telephone: string;
	bank: string;
	accountNo: string;
}

export interface TuidAddress {
	id?: number;
	country: number;
	province: number;
	city: number;
	county: number;
	zip: string;
	description: string;
}

export interface TuidSalesRegion {
	id?: number;
	name: string;
	currency: number;
	no: string;
}

export interface TuidCounty {
	id?: number;
	city: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidCustomer {
	id?: number;
	name: string;
	firstName: string;
	lastName: string;
	no: string;
	gender: string;
	salutation: string;
	birthDay: any;
	createTime: any;
}

export interface TuidWebUser {
	id?: number;
	name: string;
	firstName: string;
	lastName: string;
	gender: string;
	salutation: string;
	organizationName: string;
	departmentName: string;
	no: number;
}

export interface TuidAuditPendingUserRefuseReason {
	id?: number;
	description: string;
}

export interface TuidCity {
	id?: number;
	province: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidInvoiceType {
	id?: number;
	description: string;
}

export interface TuidBuyerAccount {
	id?: number;
}

export interface TuidCurrency {
	id?: number;
	name: string;
	suffix: string;
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidCountry {
	id?: number;
	code: string;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidProvince {
	id?: number;
	country: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidContact {
	id?: number;
	name: string;
	organizationName: string;
	mobile: string;
	telephone: string;
	email: string;
	address: number;
	addressString: string;
}

export interface TuidWebUserSettingType {
	id?: number;
	description: string;
}

export interface TuidVIPCardType {
	id?: number;
}

export interface TuidProductX {
	id?: number;
	brand: number;
}

export interface TuidBrand {
	id?: number;
	name: string;
	no: string;
}

export interface TuidChemical {
	id?: number;
}

export interface TuidPromotionPointMobile {
	id?: number;
	mobile: string;
	point: number;
	comments: string;
	expiredDate: any;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface ParamAuditPendingUser {
	id: number;
	customerId: number;
	buyerAccountId: number;
}
export interface ResultAuditPendingUser {
}

export interface ParamAuditPendingUserRefuse {
	id: number;
	reason: number;
	comments: string;
}
export interface ResultAuditPendingUserRefuse {
}

export interface ParamRecordLogin {
	webUser: number;
	ip: string;
	app: string;
}
export interface ResultRecordLogin {
}

export interface ParamSetCustomerMainWebUser {
	customer: number;
	webUser: number;
}
export interface ResultSetCustomerMainWebUser {
}

export interface ParamApplyAuditUser {
	webUser: number;
}
export interface ResultApplyAuditUser {
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamAcceptWebUserCreditsUsed {
	webUser: number;
	coupon: number;
	orderId: string;
}
export interface ResultAcceptWebUserCreditsUsed {
}

export interface ParamAcceptWebUserCouponUsed {
	webUser: number;
	coupon: number;
}
export interface ResultAcceptWebUserCouponUsed {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface ParamGetPendingAuditUser {
}
export interface ReturnGetPendingAuditUserRet {
	webUser: number;
}
export interface ResultGetPendingAuditUser {
	ret: ReturnGetPendingAuditUserRet[];
}

export interface ParamSearchHavingRefuseUser {
}
export interface ReturnSearchHavingRefuseUser$page {
	id: number;
	webUser: number;
	reason: number;
	comments: string;
	date: any;
}
export interface ResultSearchHavingRefuseUser {
	$page: ReturnSearchHavingRefuseUser$page[];
}

export interface ParamSearchHavingAuditUser {
}
export interface ReturnSearchHavingAuditUser$page {
	id: number;
	webUser: number;
	customer: number;
	date: any;
}
export interface ResultSearchHavingAuditUser {
	$page: ReturnSearchHavingAuditUser$page[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamGetMyFavirates {
	webUser: number;
	salesRegion: number;
}
export interface ReturnGetMyFavirates$page {
	seq: number;
	id: number;
	no: string;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	chemical: number;
	CAS: string;
	purity: string;
	molecularFomula: string;
	molecularWeight: string;
	discountinued: number;
}
export interface ResultGetMyFavirates {
	$page: ReturnGetMyFavirates$page[];
}

export interface ParamGetMyExpiredCoupon {
	webUser: number;
}
export interface ReturnGetMyExpiredCoupon$page {
	seq: number;
	id: number;
	code: number;
	type: number;
	createdate: any;
	expireddate: any;
}
export interface ResultGetMyExpiredCoupon {
	$page: ReturnGetMyExpiredCoupon$page[];
}

export interface ParamGetMyUsedCoupon {
	webUser: number;
}
export interface ReturnGetMyUsedCoupon$page {
	seq: number;
	id: number;
	code: number;
	type: number;
	useddate: any;
}
export interface ResultGetMyUsedCoupon {
	$page: ReturnGetMyUsedCoupon$page[];
}

export interface ParamSearchCouponReceive {
	coupon: number;
	type: number;
}
export interface ReturnSearchCouponReceiveRet {
	webUser: number;
	customer: number;
	createDate: any;
}
export interface ResultSearchCouponReceive {
	ret: ReturnSearchCouponReceiveRet[];
}

export interface ParamGetWebUserByCustomer {
	customer: number;
}
export interface ReturnGetWebUserByCustomerRet {
	id: number;
	name: string;
	firstName: string;
	lastName: string;
	organizationName: string;
	priority: number;
}
export interface ResultGetWebUserByCustomer {
	ret: ReturnGetWebUserByCustomerRet[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface ParamSearchHavingAuditUserByKey {
	key: string;
}
export interface ReturnSearchHavingAuditUserByKeyRet {
	webUser: number;
	customer: number;
	date: any;
}
export interface ResultSearchHavingAuditUserByKey {
	ret: ReturnSearchHavingAuditUserByKeyRet[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface ParamSearchWebUserContactsByKeyPager {
	key: string;
	webUser: number;
	page: number;
	pageSize: number;
}
export interface ReturnSearchWebUserContactsByKeyPagerRet {
	webUser: number;
	contact: number;
}
export interface ReturnSearchWebUserContactsByKeyPagerContactTotal {
	total: number;
}
export interface ResultSearchWebUserContactsByKeyPager {
	ret: ReturnSearchWebUserContactsByKeyPagerRet[];
	contactTotal: ReturnSearchWebUserContactsByKeyPagerContactTotal[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface AppAdmin {
	id: number;
	app?: string;
	$act?: number;
}

export interface ActParamAppAdmin {
	id: number|IDXValue;
	app?: string|IDXValue;
	$act?: number;
}

export interface ParamActs {
	$User?: $User[];
	$phrase?: $phrase[];
	$Site?: $Site[];
	$UserSite?: $UserSite[];
	appAdmin?: ActParamAppAdmin[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	InvoiceInfo: UqTuid<TuidInvoiceInfo>;
	Address: UqTuid<TuidAddress>;
	SalesRegion: UqTuid<TuidSalesRegion>;
	County: UqTuid<TuidCounty>;
	Customer: UqTuid<TuidCustomer>;
	WebUser: UqTuid<TuidWebUser>;
	AuditPendingUserRefuseReason: UqTuid<TuidAuditPendingUserRefuseReason>;
	City: UqTuid<TuidCity>;
	InvoiceType: UqTuid<TuidInvoiceType>;
	BuyerAccount: UqTuid<TuidBuyerAccount>;
	Currency: UqTuid<TuidCurrency>;
	$sheet: UqTuid<Tuid$sheet>;
	Country: UqTuid<TuidCountry>;
	Province: UqTuid<TuidProvince>;
	Contact: UqTuid<TuidContact>;
	WebUserSettingType: UqTuid<TuidWebUserSettingType>;
	VIPCardType: UqTuid<TuidVIPCardType>;
	ProductX: UqTuid<TuidProductX>;
	Brand: UqTuid<TuidBrand>;
	Chemical: UqTuid<TuidChemical>;
	PromotionPointMobile: UqTuid<TuidPromotionPointMobile>;
	AuditPendingUser: UqAction<ParamAuditPendingUser, ResultAuditPendingUser>;
	AuditPendingUserRefuse: UqAction<ParamAuditPendingUserRefuse, ResultAuditPendingUserRefuse>;
	RecordLogin: UqAction<ParamRecordLogin, ResultRecordLogin>;
	SetCustomerMainWebUser: UqAction<ParamSetCustomerMainWebUser, ResultSetCustomerMainWebUser>;
	ApplyAuditUser: UqAction<ParamApplyAuditUser, ResultApplyAuditUser>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	AcceptWebUserCreditsUsed: UqAction<ParamAcceptWebUserCreditsUsed, ResultAcceptWebUserCreditsUsed>;
	AcceptWebUserCouponUsed: UqAction<ParamAcceptWebUserCouponUsed, ResultAcceptWebUserCouponUsed>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	GetPendingAuditUser: UqQuery<ParamGetPendingAuditUser, ResultGetPendingAuditUser>;
	SearchHavingRefuseUser: UqQuery<ParamSearchHavingRefuseUser, ResultSearchHavingRefuseUser>;
	SearchHavingAuditUser: UqQuery<ParamSearchHavingAuditUser, ResultSearchHavingAuditUser>;
	$poked: UqQuery<Param$poked, Result$poked>;
	GetMyFavirates: UqQuery<ParamGetMyFavirates, ResultGetMyFavirates>;
	GetMyExpiredCoupon: UqQuery<ParamGetMyExpiredCoupon, ResultGetMyExpiredCoupon>;
	GetMyUsedCoupon: UqQuery<ParamGetMyUsedCoupon, ResultGetMyUsedCoupon>;
	SearchCouponReceive: UqQuery<ParamSearchCouponReceive, ResultSearchCouponReceive>;
	GetWebUserByCustomer: UqQuery<ParamGetWebUserByCustomer, ResultGetWebUserByCustomer>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	SearchHavingAuditUserByKey: UqQuery<ParamSearchHavingAuditUserByKey, ResultSearchHavingAuditUserByKey>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	SearchWebUserContactsByKeyPager: UqQuery<ParamSearchWebUserContactsByKeyPager, ResultSearchWebUserContactsByKeyPager>;
	WebUserBuyerAccount: UqMap;
	WebUserContact: UqMap;
	WebUserCustomer: UqMap;
	WebUserSettingAlter: UqMap;
	WebUserContacts: UqMap;
	WebUserSetting: UqMap;
	WebUserVIPCard: UqMap;
	WebUserCoupon: UqMap;
	WebUserCouponUsed: UqMap;
	MyFavorites: UqMap;
	WebUserCredits: UqMap;
	WebUserCreditsUsed: UqMap;
	$User: UqID<any>;
	$phrase: UqID<any>;
	$Site: UqID<any>;
	$UserSite: UqID<any>;
	AppAdmin: UqIDX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
