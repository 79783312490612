//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID, UqIDX, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/product ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidChemical {
	id?: number;
	CAS: string;
}

export interface TuidSalesRegion {
	id?: number;
	name: string;
	currency: number;
	no: string;
}

export interface TuidCurrency {
	id?: number;
	name: string;
	suffix: string;
}

export interface TuidPackType {
	id?: number;
	name: string;
	description: string;
}

export interface TuidLanguage {
	id?: number;
	no: string;
	description: string;
}

export interface TuidBrand {
	id?: number;
	name: string;
	no: string;
}

export interface TuidStuff {
	id?: number;
	name: string;
	packType: number;
}

export interface TuidProductX {
	id?: number;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	no: string;
	isValid: number;
}

export interface TuidProductCategory {
	id?: number;
	no: number;
	parent: number;
	isLeaf: number;
	orderWithinParent: number;
}

export interface TuidLot {
	id?: number;
	lotnumber: string;
	product: number;
}

export interface TuidPackSalesLevel {
	id?: number;
	name: string;
	no: string;
}

export interface TuidResearch {
	id?: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface ParamCountProductCategoryInclusion {
}
export interface ResultCountProductCategoryInclusion {
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamDeleteResearchProductCategory {
	research: number;
	productCategory: number;
}
export interface ResultDeleteResearchProductCategory {
}

export interface ParamAddResearchProductCategory {
	research: number;
	productCategory: number;
}
export interface ResultAddResearchProductCategory {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface ParamGetRootCategory {
	salesRegion: number;
	language: number;
}
export interface ReturnGetRootCategoryFirst {
	productCategory: number;
	name: string;
	total: number;
}
export interface ReturnGetRootCategorySecend {
	productCategory: number;
	parent: number;
	name: string;
	total: number;
}
export interface ReturnGetRootCategoryThird {
	productCategory: number;
	parent: number;
	name: string;
	total: number;
}
export interface ResultGetRootCategory {
	first: ReturnGetRootCategoryFirst[];
	secend: ReturnGetRootCategorySecend[];
	third: ReturnGetRootCategoryThird[];
}

export interface ParamGetChildrenCategory {
	parent: number;
	salesRegion: number;
	language: number;
}
export interface ReturnGetChildrenCategoryFirst {
	productCategory: number;
	parent: number;
	name: string;
	total: number;
}
export interface ReturnGetChildrenCategorySecend {
	productCategory: number;
	parent: number;
	name: string;
	total: number;
}
export interface ResultGetChildrenCategory {
	first: ReturnGetChildrenCategoryFirst[];
	secend: ReturnGetChildrenCategorySecend[];
}

export interface ParamSearchProductByCategory {
	productCategory: number;
	salesRegion: number;
	language: number;
}
export interface ReturnSearchProductByCategory$page {
	seq: number;
	id: number;
	no: string;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	chemical: number;
	CAS: string;
	purity: string;
	molecularFomula: string;
	molecularWeight: string;
}
export interface ResultSearchProductByCategory {
	$page: ReturnSearchProductByCategory$page[];
}

export interface ParamGetFutureDeliveryTime {
	product: number;
	salesRegion: number;
}
export interface ReturnGetFutureDeliveryTimeRet {
	minValue: number;
	maxValue: number;
	unit: string;
	deliveryTimeDescription: string;
	isRestrict: number;
}
export interface ResultGetFutureDeliveryTime {
	ret: ReturnGetFutureDeliveryTimeRet[];
}

export interface ParamSearchProduct {
	keyWord: string;
	salesRegion: number;
}
export interface ReturnSearchProduct$page {
	seq: number;
	id: number;
	no: string;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	chemical: number;
	CAS: string;
	purity: string;
	gradeCN: string;
	molecularFomula: string;
	molecularWeight: string;
}
export interface ResultSearchProduct {
	$page: ReturnSearchProduct$page[];
}

export interface ParamGetRootCategories {
	salesRegion: number;
	language: number;
}
export interface ReturnGetRootCategoriesRet {
	productCategory: number;
	name: string;
	total: number;
}
export interface ResultGetRootCategories {
	ret: ReturnGetRootCategoriesRet[];
}

export interface ParamGetPack {
	brandName: string;
	origin: string;
	radiox: number;
	radioy: number;
	unit: string;
}
export interface ReturnGetPackRet {
	product: number;
	pack: number;
	jkcat: string;
}
export interface ResultGetPack {
	ret: ReturnGetPackRet[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamSearchPointProduct {
	keyWord: string;
	salesRegion: number;
}
export interface ReturnSearchPointProduct$page {
	seq: number;
	id: number;
	product: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	radiox: number;
	radioy: number;
	unit: string;
	retail: number;
}
export interface ResultSearchPointProduct {
	$page: ReturnSearchPointProduct$page[];
}

export interface ParamGetProductByOrigin {
	origin: string;
	salesRegion: number;
}
export interface ReturnGetProductByOriginRet {
	id: number;
}
export interface ResultGetProductByOrigin {
	ret: ReturnGetProductByOriginRet[];
}

export interface ParamGetPointProductMoreBySource {
	pack: number;
	salesRegion: number;
}
export interface ReturnGetPointProductMoreBySourceRet {
	id: number;
	product: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	radiox: number;
	radioy: number;
	unit: string;
	retail: number;
}
export interface ResultGetPointProductMoreBySource {
	ret: ReturnGetPointProductMoreBySourceRet[];
}

export interface ParamGetLotByLotnumber {
	lotnumber: string;
	origin: string;
}
export interface ReturnGetLotByLotnumberRet {
	id: number;
	product: number;
}
export interface ResultGetLotByLotnumber {
	ret: ReturnGetLotByLotnumberRet[];
}

export interface ParamGetAvailableProductById {
	product: number;
	salesRegion: number;
}
export interface ReturnGetAvailableProductByIdRet {
	id: number;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	no: string;
	isValid: number;
}
export interface ResultGetAvailableProductById {
	ret: ReturnGetAvailableProductByIdRet[];
}

export interface ParamGetProductPackByOrigin {
	origin: string;
	salesRegion: number;
}
export interface ReturnGetProductPackByOriginRet {
	product: number;
	pack: number;
}
export interface ResultGetProductPackByOrigin {
	ret: ReturnGetProductPackByOriginRet[];
}

export interface ParamGetProductPrices {
	product: number;
	salesRegion: number;
}
export interface ReturnGetProductPricesRet {
	product: number;
	pack: number;
	salesRegion: number;
	retail: number;
	expireDate: any;
	discountinued: number;
	salesLevel: number;
}
export interface ResultGetProductPrices {
	ret: ReturnGetProductPricesRet[];
}

export interface ParamGetProductLotNumber {
	product: number;
}
export interface ReturnGetProductLotNumberRet {
	id: number;
	product: number;
	lotnumber: string;
}
export interface ResultGetProductLotNumber {
	ret: ReturnGetProductLotNumberRet[];
}

export interface ParamPriceXquery {
	product: number;
	pack: number;
	salesRegion: number;
}
export interface ReturnPriceXqueryRet {
	product: number;
	pack: number;
	salesRegion: number;
	expireDate: any;
	discountinued: number;
	retail: number;
}
export interface ResultPriceXquery {
	ret: ReturnPriceXqueryRet[];
}

export interface ParamGetProductByPackId {
	pack: number;
}
export interface ReturnGetProductByPackIdRet {
	product: number;
	origin: string;
	description: string;
	descriptionC: string;
	brand: number;
	brandName: string;
}
export interface ResultGetProductByPackId {
	ret: ReturnGetProductByPackIdRet[];
}

export interface ParamGetResearchByProductCategory {
	productCategory: number;
}
export interface ReturnGetResearchByProductCategoryRet {
	productCategory: number;
	research: number;
}
export interface ResultGetResearchByProductCategory {
	ret: ReturnGetResearchByProductCategoryRet[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface ParamGetBrandSalesRegion {
	salesRegion: number;
}
export interface ReturnGetBrandSalesRegionRet {
	id: number;
	name: string;
	brand: number;
	salesRegion: number;
	level: number;
}
export interface ResultGetBrandSalesRegion {
	ret: ReturnGetBrandSalesRegionRet[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface Product2c {
	id: number;
	createDate?: any;
	$act?: number;
}

export interface ActParamProduct2c {
	id: number|IDXValue;
	createDate?: any|IDXValue;
	$act?: number;
}

export interface ProductCategoryResearchDomain {
	ix: number;
	xi: number;
}

export interface ParamActs {
	$User?: $User[];
	$phrase?: $phrase[];
	$UserSite?: $UserSite[];
	$Site?: $Site[];
	product2c?: ActParamProduct2c[];
	productCategoryResearchDomain?: ProductCategoryResearchDomain[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	Chemical: UqTuid<TuidChemical>;
	SalesRegion: UqTuid<TuidSalesRegion>;
	Currency: UqTuid<TuidCurrency>;
	PackType: UqTuid<TuidPackType>;
	Language: UqTuid<TuidLanguage>;
	Brand: UqTuid<TuidBrand>;
	Stuff: UqTuid<TuidStuff>;
	ProductX: UqTuid<TuidProductX>;
	ProductCategory: UqTuid<TuidProductCategory>;
	Lot: UqTuid<TuidLot>;
	PackSalesLevel: UqTuid<TuidPackSalesLevel>;
	Research: UqTuid<TuidResearch>;
	CountProductCategoryInclusion: UqAction<ParamCountProductCategoryInclusion, ResultCountProductCategoryInclusion>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	DeleteResearchProductCategory: UqAction<ParamDeleteResearchProductCategory, ResultDeleteResearchProductCategory>;
	AddResearchProductCategory: UqAction<ParamAddResearchProductCategory, ResultAddResearchProductCategory>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	GetRootCategory: UqQuery<ParamGetRootCategory, ResultGetRootCategory>;
	GetChildrenCategory: UqQuery<ParamGetChildrenCategory, ResultGetChildrenCategory>;
	SearchProductByCategory: UqQuery<ParamSearchProductByCategory, ResultSearchProductByCategory>;
	GetFutureDeliveryTime: UqQuery<ParamGetFutureDeliveryTime, ResultGetFutureDeliveryTime>;
	SearchProduct: UqQuery<ParamSearchProduct, ResultSearchProduct>;
	GetRootCategories: UqQuery<ParamGetRootCategories, ResultGetRootCategories>;
	GetPack: UqQuery<ParamGetPack, ResultGetPack>;
	$poked: UqQuery<Param$poked, Result$poked>;
	SearchPointProduct: UqQuery<ParamSearchPointProduct, ResultSearchPointProduct>;
	GetProductByOrigin: UqQuery<ParamGetProductByOrigin, ResultGetProductByOrigin>;
	GetPointProductMoreBySource: UqQuery<ParamGetPointProductMoreBySource, ResultGetPointProductMoreBySource>;
	GetLotByLotnumber: UqQuery<ParamGetLotByLotnumber, ResultGetLotByLotnumber>;
	GetAvailableProductById: UqQuery<ParamGetAvailableProductById, ResultGetAvailableProductById>;
	GetProductPackByOrigin: UqQuery<ParamGetProductPackByOrigin, ResultGetProductPackByOrigin>;
	GetProductPrices: UqQuery<ParamGetProductPrices, ResultGetProductPrices>;
	GetProductLotNumber: UqQuery<ParamGetProductLotNumber, ResultGetProductLotNumber>;
	PriceXquery: UqQuery<ParamPriceXquery, ResultPriceXquery>;
	GetProductByPackId: UqQuery<ParamGetProductByPackId, ResultGetProductByPackId>;
	GetResearchByProductCategory: UqQuery<ParamGetResearchByProductCategory, ResultGetResearchByProductCategory>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	GetBrandSalesRegion: UqQuery<ParamGetBrandSalesRegion, ResultGetBrandSalesRegion>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	AgentPrice: UqMap;
	BrandSalesRegion: UqMap;
	BrandDeliveryTime: UqMap;
	ProductStuff: UqMap;
	PriceX: UqMap;
	ProductChemical: UqMap;
	ProductSalesRegion: UqMap;
	ProductLegallyProhibited: UqMap;
	ProductCache: UqMap;
	ProductProductCategory: UqMap;
	ProductCategoryInclusion: UqMap;
	ProductProductCategoryCache: UqMap;
	ProductMSDSFile: UqMap;
	ProductSpecFile: UqMap;
	ProductSalesRank: UqMap;
	ProductCategoryLeafCache: UqMap;
	COA: UqMap;
	ProductExtention: UqMap;
	ProductDeliveryTime: UqMap;
	ProductEmbargo: UqMap;
	ProductStandardSample: UqMap;
	ProductUserManualFile: UqMap;
	PackageEmbargo: UqMap;
	$User: UqID<any>;
	$phrase: UqID<any>;
	$UserSite: UqID<any>;
	$Site: UqID<any>;
	Product2c: UqIDX<any>;
	ProductCategoryResearchDomain: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
