// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { SalesmanRepurchaseRadio } from "./JkCoupon";

/*--fields--*/
const fields = {
	ix: {
		"name": "ix",
		"type": "id",
		"isKey": false,
		"label": "Ix"
	} as FieldItemId,
	xi: {
		"name": "xi",
		"isKey": false,
		"label": "Xi"
	} as undefined,
	periodDays: {
		"name": "periodDays",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "PeriodDays"
	} as FieldItemInt,
	repurchases: {
		"name": "repurchases",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "Repurchases"
	} as FieldItemInt,
	retentions: {
		"name": "retentions",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "Retentions"
	} as FieldItemInt,
	all: {
		"name": "all",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "All"
	} as FieldItemInt,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.xi, fields.periodDays, fields.repurchases, fields.retentions, fields.all, 
];

export const ui: UI = {
	label: "SalesmanRepurchaseRadio",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: SalesmanRepurchaseRadio):JSX.Element {
	return <>{uqStringify(item)}</>;
};
