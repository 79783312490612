import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { Dropdown, Flex, MenuProps } from "antd";
import { getCategoriesTree } from "tools/object";

// export const NavigationBar = ({ categories }: { categories: any }) => {
export const NavigationBar = () => {
    const navbarRef = useRef<any>(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const handleLinkClick = (event: any) => {
            // 确保点击的是导航栏内的链接
            if (navbarRef.current && navbarRef.current.contains(event.target)) {
                navbarRef.current.style.display = "none";
                setTimeout(() => {
                    navbarRef.current.removeAttribute("style");
                }, 200);
            }
        };
        document.addEventListener("click", handleLinkClick);
        const catalogue = async () => {
            const cata = await getCategoriesTree();
            setCategories(cata);
        };
        catalogue();
        return () => {
            // 组件卸载时移除事件监听器
            document.removeEventListener("click", handleLinkClick);
        };
    }, []); // 空依赖数组确保这个effect只在组件挂载和卸载时运行一次

    /**
     * 显示二级目录
     * @param param0 二级目录名称
     * @returns
     */
    const SecondaryDirectory = ({ name }: { name: string }) => {
        return (
            <Flex align="center">
                <div style={{ width: "90%" }}>{name}</div>
                <div style={{ width: "10%" }}>{">"}</div>
            </Flex>
        );
    };

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <a
                    href="https://www.findmolecule.cn/inventory"
                    target="_blank"
                    rel="noreferrer"
                >
                    库存管理系统
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a
                    href="https://www.findmolecule.cn/eln"
                    target="_blank"
                    rel="noreferrer"
                >
                    电子实验记录本
                </a>
            ),
        },
        {
            key: "3",
            label: (
                <a
                    href="https://fenji-blw.labvision.cn"
                    target="_blank"
                    rel="noreferrer"
                >
                    实验室安全等级评估
                </a>
            ),
        },
    ];
    return (
        <ul className={styles["main-menu"]}>
            <li className={styles["main-menu-item"]}>
                <a className="scrollto" href="#">
                    全部产品分类
                </a>
                <ul className={styles["sub-menu-1"]} ref={navbarRef}>
                    {categories?.map((e1: any, i1: number) => {
                        return (
                            <li
                                key={e1.id}
                                className={styles["sub-menu-1-item"]}
                            >
                                <img
                                    src={`/assets/img/icon/1-${i1 + 1}.png`}
                                    alt={e1.name}
                                    style={{
                                        height: "16px",
                                        width: "16px",
                                    }}
                                ></img>
                                {` ${e1.name} >`}
                                <ul className={styles["sub-menu-2"]}>
                                    {e1?.children?.map((e2: any) => (
                                        <li
                                            key={e2.id}
                                            className={
                                                styles["sub-menu-2-item"]
                                            }
                                        >
                                            <div>
                                                <SecondaryDirectory
                                                    name={e2.name}
                                                ></SecondaryDirectory>
                                            </div>
                                            <ul
                                                className={styles["sub-menu-3"]}
                                            >
                                                {e2?.children?.map(
                                                    (e3: any) => (
                                                        <li
                                                            key={e3.id}
                                                            className={
                                                                styles[
                                                                    "sub-menu-3-item"
                                                                ]
                                                            }
                                                        >
                                                            <a
                                                                href={`/search/${e3.name}?&type=3&categoryId=${e3.id}&pageIndex=1`}
                                                            >
                                                                {e3.name}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </li>
            <li className={styles["main-menu-item"]}>
                <a className="scrollto" href="/subjectpost/18">
                    特惠活动
                </a>
            </li>
            <li className={styles["main-menu-item"]}>
                <a className="scrollto" href="/pointshop">
                    积分商城
                </a>
            </li>
            <li className={styles["main-menu-item"]}>
                <a className="scrollto" href="/information">
                    资讯中心
                </a>
            </li>
            <li className={styles["main-menu-item"]}>
                <a
                    className="scrollto"
                    href="https://www.bio-vanguard.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    消毒抗菌
                </a>
            </li>
            <Dropdown className={styles["main-menu-item"]} menu={{ items }}>
                <a
                    className="scrollto"
                    href="https://www.findmolecule.cn/"
                    target="_blank"
                    rel="noreferrer"
                >
                    实验室管理系统
                </a>
            </Dropdown>
        </ul>
    );
};
