import { centerApi } from "centerApi";
import { ActEntity } from "./entity";

class Product extends ActEntity {
    protected get base() { return centerApi.apiV2.productApi };

    /**
     * 产品编号查询存在MSDS的产品列表
     */
    getProductsByOrigin = async (origin: string, lang: string = "CN", captcha: string) => {
        try {
            let url: string = `${centerApi.webApi.getProductByOirigin}${lang}/${origin}/${captcha}`;
            let res: any[] = await centerApi.webApi.get(url);
            return { success: true, message: "成功", result: res || [] };
        } catch (error: any) {
            return { success: false, message: error.message, result: [] as any[] };
        };
    };
};

export const JKProduct = new Product();