// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { RecommendCustomerPoolHistory } from "./JkCoupon";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	webUser: {
		"name": "webUser",
		"type": "id",
		"isKey": true,
		"label": "WebUser"
	} as FieldItemId,
	date: {
		"name": "date",
		"isKey": true,
		"label": "Date"
	} as undefined,
	times: {
		"name": "times",
		"type": "integer",
		"isKey": true,
		"widget": "updown",
		"label": "Times"
	} as FieldItemInt,
	customer: {
		"name": "customer",
		"type": "id",
		"isKey": false,
		"label": "Customer"
	} as FieldItemId,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.webUser, fields.date, fields.times, fields.customer, 
];

export const ui: UI = {
	label: "RecommendCustomerPoolHistory",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: RecommendCustomerPoolHistory):JSX.Element {
	return <>{uqStringify(item)}</>;
};
