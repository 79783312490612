import { centerApi } from "centerApi";
import { ActEntity } from "./entity";

class Header extends ActEntity {
    protected get base() {
        return centerApi.apiV2;
    }

    /**
     * 获取内容数据
     * @category 类别
     * @type 类型
     * @state 状态
     * @pageIndex 页码
     * @pageSize 页面大小
     */
    async getContentManageList(params?: any): Promise<any> {
        try {
            const res: any = await centerApi.apiV2.get(
                `/WebBuilder/GetContentManageList`,
                { params }
            );
            let { success, data } = res;
            if (success) return data;
        } catch (e: any) {
            console.log(e.message);
            return [];
        }
    }

    /**
     * 一级产品目录
     * @params {salesRegion = 1 ,languageId =196}
     */
    async getRootProductCategories(params?: any): Promise<any> {
        try {
            params = { salesRegion: 1, languageId: 196, ...params };
            const res: any = await centerApi.apiV2.get(
                `/ProductCategory/GetRootProductCategories`,
                { params }
            );
            let { success, data } = res;
            if (success) return data;
            return res;
        } catch (e: any) {
            console.log(e.message);
            return [];
        }
    }

    /**
     * 二级产品目录
     * @id 根产品目录id
     */
    async getSecondLevelProductCategories(
        id: string,
        params?: any
    ): Promise<any> {
        try {
            params = { salesRegionId: 1, languageId: 196, ...params };
            const res: any = await centerApi.apiV2.get(
                `/ProductCategory/${id}`,
                { params }
            );
            let { success, data } = res;
            if (success) return data;
        } catch (e: any) {
            console.log(e.message);
            return [];
        }
    }
}

export const JKHeader = new Header();
