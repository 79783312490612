/* eslint-disable */
import * as React from "react";
import { VPage, Ax, FA, View, A, Image, nav, env } from "tonva-react";
import { CApp } from "./CApp";
import { observer } from "mobx-react";
import * as qs from "querystringify";
import { makeObservable, observable } from "mobx";
import { Fragment } from "react";
import { ConfigProvider, Divider, Badge, Input } from "antd";
import { appApis } from "centerApi/actEntity";
import { SearchOutlined } from "@ant-design/icons";
import { NavigationBar } from "tools/NavigationBar";
import { getCategoriesTree } from "tools/object";

const { Search } = Input;
interface PCategoryId {
    id: number | string;
    id_test: number | string;
}

type Categorys = {
    name: number;
    label: string;
};

const ProductCategorys: { [name: string]: PCategoryId } = {
    有机化学: { id: 47, id_test: 7 },
    分析化学: { id: 470, id_test: 430 },
    生命科学: { id: 1013, id_test: 986 },
    材料科学: { id: 1219, id_test: 1214 },
    仪器耗材: { id: 1545, id_test: "#" },
};

export class VMainWebNav extends VPage<CApp> {
    content() {
        let { cHome } = this.controller;
        return cHome.tabContent();
    }
}

const categorys: Categorys[] = [
    { name: 1, label: "试剂/耗材" },
    { name: 2, label: "标准品" },
];

export class NavHeaderView extends View<CApp> {
    private searchKey: HTMLInputElement;
    private searchType: HTMLSelectElement;
    curCategory: number = 1;
    searchPhlder: string;
    relatedSearchTerms: any;
    rootCatalogue: any;

    constructor(c: CApp) {
        super(c);
        let query: any = qs.parse(location.search.toLowerCase());

        this.curCategory = Number(query?.type != 2 ? 1 : 2);
        makeObservable(this, {
            searchPhlder: observable,
            curCategory: observable,
            relatedSearchTerms: observable,
            rootCatalogue: observable,
        });
    }

    searchClick = (v: string) => {
        let keyValue: string = v.trim() || "";
        let url = "/search/" + encodeURIComponent(keyValue);
        if (keyValue !== "") url += "?type=" + this.curCategory;
        // this.navigate(url);
        window.location.href = url;
        return false;
    };

    getData = async () => {
        /**
         * 初始化数据-相关搜索词
         * @returns
         */
        if (this.relatedSearchTerms === undefined) {
            const params = {
                category: "relatedSearchTerms",
                pageIndex: 1,
                pageSize: 5,
            };
            const { items } = await appApis.JKHeader.getContentManageList(
                params
            );
            let result: any = {};

            for (let item of items || []) {
                if (result.hasOwnProperty(item.type)) {
                    result[item.type].push(item);
                } else {
                    result[item.type] = [item];
                }
            }
            this.relatedSearchTerms = result;
            // 初始化数据-目录树
            /* const rootCatalogue =
                await appApis.JKHeader.getRootProductCategories();
            const catalogue = await Promise.all(
                rootCatalogue.map(async (item: any) => {
                    const secendLevel =
                        await appApis.JKHeader.getSecondLevelProductCategories(
                            item.id
                        );
                    return secendLevel;
                })
            )
                .then((res: any) => {
                    return res;
                })
                .catch((err: any) => err); */
            // this.rootCatalogue = catalogue;
            // const catalogue = await getCategoriesTree();
            // this.rootCatalogue = catalogue;
        }
    };

    render() {
        return React.createElement(
            observer(() => {
                this.getData();
                let vLogin = React.createElement(
                    observer(() => {
                        let { user } = this.controller;
                        let v: any;
                        if (!user) {
                            v = (
                                <a href="/me">
                                    <i
                                        className="fa fa-user fa-2x"
                                        aria-hidden="true"
                                    ></i>
                                </a>
                            );
                        } else {
                            // let { name, nick, icon } = user;
                            v = (
                                <>
                                    <a href="/me">
                                        <i
                                            className="fa fa-user fa-2x"
                                            style={{
                                                color: "#ad2031",
                                            }}
                                            aria-hidden="true"
                                        ></i>
                                    </a>
                                    <a
                                        className="me-2"
                                        href="/logout"
                                        style={{
                                            fontSize: "12px",
                                            padding: "10px 6px",
                                        }}
                                    >
                                        退出
                                    </a>
                                </>
                            );
                        }
                        return (
                            <div
                                className="login"
                                style={{ fontSize: "14px", minWidth: "200" }}
                            >
                                {v}
                            </div>
                        );
                    })
                );
                let searchPhlderVal = (type: number) => {
                    if (type === 1) return "品名、编号、CAS...";
                    else return "产品CAS、名称、组分CAS、名称....";
                };

                let { search } = document.location;
                let query: any = { type: 1 };
                if (search) query = qs.parse(search.toLowerCase());
                this.searchPhlder = searchPhlderVal(Number(query?.type || 1));

                /* let searchTypeUI = React.createElement(
                    observer(() => {
                        return (
                            <>
                                <form
                                    onSubmit={(e: any) => {
                                        e.preventDefault();
                                        this.searchClick();
                                    }}
                                >
                                    <div className="search">
                                        <select
                                            id="header-sel"
                                            onChange={() => {
                                                this.searchPhlder =
                                                    searchPhlderVal(
                                                        Number(
                                                            this.searchType
                                                                ?.value || 1
                                                        )
                                                    );
                                            }}
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            ref={(v) => (this.searchType = v)}
                                            defaultValue={query?.type || 1}
                                            className="h-100 p-0 position-absolute input-group-sel select-r"
                                            style={{
                                                border: "1px solid #414042",
                                            }}
                                        >
                                            <option value="1">试剂/耗材</option>
                                            <option value="2">标准品</option>
                                        </select>
                                        <input
                                            ref={(v) => (this.searchKey = v)}
                                            id="header-ipt"
                                            type="text"
                                            className="searchTerm input-r"
                                            placeholder={this.searchPhlder}
                                        />
                                        <button
                                            type="submit"
                                            className="searchButton button-r"
                                        >
                                            <img
                                                src="/assets/img/Search.svg"
                                                width="20px"
                                                alt="search"
                                            />
                                        </button>
                                    </div>
                                </form>
                            </>
                        );
                    })
                ); */
                let { user } = this.controller;
                return (
                    <header style={{ boxShadow: "none", height: "198px" }}>
                        <div
                            id="topbar"
                            className="d-lg-flex align-items-center fixed-top topbar-transparent"
                        >
                            <div className="container d-flex justify-content-between">
                                <div>
                                    {/* 您好，欢迎来到百灵威化学试剂采购平台！ */}
                                </div>
                                <div className="text-end">
                                    <span className="pad">
                                        <a
                                            href="/register"
                                            style={
                                                user && user.name !== ""
                                                    ? { display: "none" }
                                                    : { color: "#9b2634" }
                                            }
                                        >
                                            免费注册
                                        </a>
                                    </span>
                                    <span className="pad">
                                        <a
                                            // href="/login"
                                            href={
                                                user && user.name !== ""
                                                    ? "/me"
                                                    : "/login"
                                            }
                                            style={{ color: "#9b2634" }}
                                        >
                                            {user?.name ?? "登录"}
                                        </a>
                                    </span>
                                    <span className="pad">
                                        <a href="/ch/about">联系我们</a>
                                    </span>
                                    <span className="pad">
                                        <i className="fa fa-phone"></i>
                                        &nbsp;客服热线：
                                        <span style={{ color: "#9b2634" }}>
                                            400-666-7788
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            id="header"
                            className="header fixed-top align-items-center header-transparent bg-white"
                        >
                            <div className="container d-lg-flex align-items-center px-0">
                                <div className="col-lg-2 p-0">
                                    <div className="logo mr-auto">
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                                location.href = "/";
                                            }}
                                        >
                                            <img
                                                src="/images/logo.svg"
                                                alt="logo"
                                                className="img-fluid"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 ml-34p px-3 py=1">
                                    <form
                                        onSubmit={(e) => {
                                            // searchKey(e);
                                        }}
                                    >
                                        <div className="search">
                                            <ul className="search-categorys">
                                                {categorys.map(
                                                    (
                                                        item: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <Fragment
                                                                key={item.name}
                                                            >
                                                                <li
                                                                    className={`search-category ${
                                                                        this
                                                                            .curCategory ===
                                                                        item.name
                                                                            ? "search-category-actived"
                                                                            : ""
                                                                    }`}
                                                                    onClick={() => {
                                                                        // searchPhlderVal
                                                                        this.curCategory =
                                                                            item.name;
                                                                    }}
                                                                >
                                                                    {item.label}
                                                                </li>
                                                                {index ===
                                                                    0 && (
                                                                    <Divider
                                                                        type="vertical"
                                                                        style={{
                                                                            marginTop:
                                                                                "5px",
                                                                            backgroundColor:
                                                                                "#000",
                                                                            height: "1.5rem",
                                                                        }}
                                                                    ></Divider>
                                                                )}
                                                            </Fragment>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                            <ConfigProvider
                                                theme={{
                                                    components: {
                                                        Input: {
                                                            activeBorderColor:
                                                                "#b02c38",
                                                            hoverBorderColor:
                                                                "rgba(176, 44, 56, 0.2)",
                                                            activeShadow:
                                                                "none",
                                                        },
                                                    },
                                                }}
                                            >
                                                <Search
                                                    name="category"
                                                    size="large"
                                                    enterButton={
                                                        <span>
                                                            <SearchOutlined
                                                                rev={undefined}
                                                            />
                                                            {" 搜索"}
                                                        </span>
                                                    }
                                                    placeholder={
                                                        this.searchPhlder
                                                    }
                                                    onSearch={(
                                                        v: string,
                                                        e: any
                                                    ) => {
                                                        e.preventDefault();
                                                        this.searchClick(v);
                                                    }}
                                                ></Search>
                                            </ConfigProvider>
                                            <ul className="recommend">
                                                {/* - 默认为中横线, 如果跟搜索类别关联则为curCategory */}
                                                {this.relatedSearchTerms?.[
                                                    "-"
                                                ]?.map(
                                                    (
                                                        item: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <li key={item.id}>
                                                                <a
                                                                    href={
                                                                        item.contentUrl
                                                                    }
                                                                    style={
                                                                        index ===
                                                                        0
                                                                            ? {
                                                                                  color: "#9b2634",
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                ) ?? (
                                                    <li
                                                        style={{
                                                            visibility:
                                                                "hidden",
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-3 d-flex justify-content-evenly align-items-center">
                                    <div className="cart d-flex align-items-center jk-cart">
                                        <a href="/cart">
                                            <Badge
                                                count={
                                                    this?.controller?.store
                                                        ?.cartCount
                                                }
                                                overflowCount={999}
                                                color="#ad2031"
                                                size="small"
                                            >
                                                <i
                                                    className="fa fa-shopping-cart text-muted fa-lg"
                                                    aria-hidden="true"
                                                ></i>
                                            </Badge>
                                            <span
                                                style={{
                                                    fontSize: "14px",
                                                    marginLeft: "1rem",
                                                }}
                                            >
                                                购物车
                                            </span>
                                        </a>
                                    </div>
                                    <div
                                        className="d-flex vertical-align-middle justify-content-evenly"
                                        style={{
                                            marginTop: "8px",
                                            marginLeft: "10px",
                                            minWidth: "70px",
                                        }}
                                    >
                                        {vLogin}
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderBottom: "1px solid #b02c38" }}>
                                <div className="container px-0">
                                    <nav id="navbar" className="navbar">
                                        <NavigationBar
                                        // categories={this.rootCatalogue}
                                        ></NavigationBar>
                                        <i className="bi bi-list mobile-nav-toggle d-none"></i>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                );
            })
        );
    }
}

export class NavFooterView extends View<CApp> {
    render() {
        return (
            <div className="footer footer-m" id="end-page-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-6 d-none d-sm-block d-md-block">
                            <img
                                src="/images/icon/footer-logo.svg"
                                className="footer-logo"
                                alt="footer-logo"
                            />
                            <p>服务科技与工业发展 造福人类</p>
                            <img
                                src="/images/qrcode.jpg"
                                style={{ width: 100 }}
                                className="mt-lg-50 qrcode"
                                alt="qrcode"
                            />
                            <p className="follow qrcode">关注微信公众号</p>
                            {/* <img src="/images/qrcode.jpg" className="w-75 mt-lg-28 qrcode" /> */}
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="footer-title">百灵威集团</div>
                            <ul>
                                <li>
                                    <a
                                        href="/ch/about"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        公司简介
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/ch/promise"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        企业承诺
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/ch/recommended-brand"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        合作品牌
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/job"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        招贤纳士
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6 d-none d-sm-block d-md-block">
                            <div className="footer-title">浏览</div>
                            <ul>
                                <li>
                                    <a href="/information" target="_blank">
                                        资讯中心
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/product/mscu/msds"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        安全说明书(SDS)
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/product/mscu/coa"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        质检报告(COA)
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="/product-catalog">产品</a>
                                </li>
                                <ul className="ml-3">
                                    {Object.keys(ProductCategorys).map(
                                        (name: string) => {
                                            let id =
                                                env.testing === true
                                                    ? ProductCategorys[name]
                                                          .id_test
                                                    : ProductCategorys[name].id;
                                            return (
                                                <li key={name}>
                                                    <a
                                                        href={`/product-catalog/${id}`}
                                                    >
                                                        {name}
                                                    </a>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul> */}
                                <li>
                                    <a
                                        href="/hazard-purchase"
                                        className="text-danger"
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        危险品购买提示
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="footer-title d-flex hidden">
                                <div className="footer-title">联系我们</div>
                                <img
                                    src="/assets/img/Location.svg"
                                    style={{
                                        marginBottom: "20px",
                                        width: "20px",
                                    }}
                                    alt="contact-us"
                                />
                                <ul
                                    className="nav nav-tabs"
                                    style={{ margin: "0" }}
                                >
                                    <li>
                                        <a
                                            className="active"
                                            data-toggle="tab"
                                            style={{ letterSpacing: "normal" }}
                                            href="#headquarter"
                                        >
                                            北京
                                        </a>
                                    </li>{" "}
                                    |
                                    <li>
                                        <a
                                            data-toggle="tab"
                                            style={{ letterSpacing: "normal" }}
                                            href="#menu1"
                                        >
                                            上海
                                        </a>
                                    </li>{" "}
                                    |
                                    <li>
                                        <a
                                            data-toggle="tab"
                                            style={{ letterSpacing: "normal" }}
                                            href="#menu2"
                                        >
                                            广州
                                        </a>
                                    </li>{" "}
                                    |
                                    <li>
                                        <a
                                            data-toggle="tab"
                                            style={{ letterSpacing: "normal" }}
                                            href="#menu3"
                                        >
                                            香港
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content bg-transparent px-0">
                                <div
                                    id="headquarter"
                                    className="tab-pane active"
                                >
                                    <div className="contact mb-0">
                                        北京市朝阳区北辰西路69号峻峰华亭A座5层
                                        100029
                                    </div>
                                    <div className="contact mb-0">
                                        电话(QQ): 400 666 7788
                                    </div>
                                    <div className="contact mb-0">
                                        电话：+86 10 59309000
                                    </div>
                                    <div className="contact mb-0">
                                        传真：+86 10 8284 9933
                                    </div>
                                    <div className="contact mb-0">
                                        电子邮件：
                                        <br />
                                        <a href="mailto:jkinfo@jkchemical.com">
                                            jkinfo@jkchemical.com
                                        </a>
                                        <br />
                                        <a href="mailto:jkinfo@jk-sci.com">
                                            jkinfo@jk-sci.com
                                        </a>
                                    </div>
                                </div>
                                <div id="menu1" className="tab-pane ">
                                    <div className="contact mb-0">
                                        上海市浦东新区世纪大道1777号东方希望大厦9楼B室
                                        200122
                                    </div>
                                    <div className="contact mb-0">
                                        电话：+86 21 6163 8833
                                    </div>
                                    <div className="contact mb-0">
                                        传真：+86 21 6163 8800
                                    </div>
                                    <div className="contact mb-0">
                                        电子邮件：
                                        <br />
                                        <a href="mailto:jkinfo@jkchemical.com">
                                            jkinfo@jkchemical.com
                                        </a>
                                    </div>
                                </div>
                                <div id="menu2" className="tab-pane">
                                    <div className="contact mb-0">
                                        广东省广州市番禺区南村镇汉溪大道东(延伸段)387号万达广场B2栋1622-1624室
                                        511446
                                    </div>
                                    <div className="contact mb-0">
                                        电话：+86 20 3888 9733
                                    </div>
                                    <div className="contact mb-0">
                                        传真：+86 20 3888 8285
                                    </div>
                                    <div className="contact mb-0">
                                        电子邮件：
                                        <br />
                                        <a href="mailto:jkgz@jkchemical.com ">
                                            jkgz@jkchemical.com{" "}
                                        </a>
                                        <br />
                                        <a href="mailto:jkgz@jk-sci.com">
                                            jkgz@jk-sci.com{" "}
                                        </a>
                                    </div>
                                </div>
                                <div id="menu3" className="tab-pane">
                                    <div className="contact mb-0">
                                        Unit 2007, 20/F, Westley Square, No. 48
                                        Hoi Yuen Road, Kwun Tong, Kowloon, Hong
                                        Kong
                                    </div>
                                    <div className="contact mb-0">
                                        电话：+852 2370 0093
                                    </div>
                                    <div className="contact mb-0">
                                        传真：+852 2370 0032
                                    </div>
                                    <div className="contact mb-0">
                                        电子邮件：
                                        <br />
                                        <a href="mailto:jkhk@jk-sci.com">
                                            jkhk@jk-sci.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom mt-2 p-0 bg-transparent">
                    <div
                        className="container line m-auto color-inherit"
                        style={{ borderLeft: "none" }}
                    >
                        <div className="text-center">
                            <div
                                className="col-lg-12 text-danger"
                                style={{ fontSize: "14px" }}
                            >
                                本网站销售的化学产品仅供研发或工业应用，不直接用于食品、药品、临床诊断或治疗。
                            </div>
                            <div className="col-lg-12">
                                严禁在未经同意的情况下使用本网站所有信息 |{" "}
                                <a
                                    href="/user-agreement"
                                    rel="nofollow"
                                    target="_blank"
                                >
                                    用户使用服务协议
                                </a>
                                |{" "}
                                <a href="/legal" rel="nofollow">
                                    法律声明
                                </a>
                                |{" "}
                                <a href="/privacy-cn" rel="nofollow">
                                    隐私政策
                                </a>
                                |{" "}
                                <a
                                    href="/terms-conditions"
                                    rel="nofollow"
                                    target="_blank"
                                >
                                    销售通用条款及条件
                                </a>
                            </div>
                            <div className="col-lg-12">
                                <img
                                    className="align-top"
                                    src="https://web.jkchemical.com/images/gongan.png"
                                    alt="gongan"
                                />
                                <a
                                    href="https://www.beian.gov.cn/portal/recordQuery"
                                    target="_blank"
                                >
                                    京公网安备 11010502044888号
                                </a>
                                &nbsp; |{" "}
                                <a
                                    href="https://beian.miit.gov.cn"
                                    target="_blank"
                                >
                                    京ICP备09071033号-3
                                </a>
                                &nbsp; |{" "}
                                <a
                                    href="/images/上海百灵威营业执照副本.pdf"
                                    target="_blank"
                                >
                                    上海工商
                                </a>
                                &nbsp; |{" "}
                                <a
                                    href="/images/危险化学品经营许可证副本.jpg"
                                    target="_blank"
                                >
                                    危险化学品经营许可证
                                </a>
                                |{" "}
                                <a
                                    href="/images/营业执照副本.jpg"
                                    target="_blank"
                                >
                                    {" "}
                                    营业执照(三证合一)
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
