import { VPage } from 'tonva-react';
import { COrder } from './COrder';
import { xs } from '../tools/browser';
import QRCode from 'qrcode.react';
import { makeObservable, observable } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

/*
1.获取支付二维码
2.成功-继续,同时轮询、计时， 失败-让客户刷新
3.轮询结果：
    成功：停止计时, 成功支付;
    失败（计时结束）：停止轮询，过期，让客户刷新
    失败（接口失败）：继续轮询
*/

const setTimeCount: number = 60 * 10;

export class VScanCodePayment extends VPage<COrder> {
    payInfo: any;                       /* 支付信息 */
    order: any;                         /* 支付订单信息 */
    count: number = setTimeCount;       /* 支付总计30分钟; */
    paymentTime: any;                   /* 支付倒计时 */
    invalidPayment: boolean = false;    /* 是否为无效支付,即倒计时结束, 还未支付 */
    isPaySuccess: boolean = false;      /* 是否支付成功 */
    timer: any;                         /* 支付结果轮询 */
    qrCodeUrl: string = "";             /* 支付url */
    isCanPayment: boolean = false;      /* 是否可以支付, 指：创建支付订单失败, 无法获取有效的二维码,需要重新创建 */

    constructor(c: COrder) {
        super(c);
        makeObservable(this, {
            count: observable,
            paymentTime: observable,
            invalidPayment: observable,
            isPaySuccess: observable,
            qrCodeUrl: observable,
            isCanPayment: observable,
        });
    };

    init(param: any) {
        let { payment, order } = param;
        let { pay } = payment;
        this.payInfo = payment;
        this.order = order;
        this.qrCodeUrl = pay?.paySign;
        this.isCanPayment = this.qrCodeUrl ? true : false;
        if (this.isCanPayment) this.initTimer();
    }

    header(){
        if (xs) return '下单支付';
        return false;
    }

    initTimer() {
        this.initPayTime();
        this.timer = setInterval(async () => {
            if (this.count === 0) {
                clearInterval(this.timer);
                this.invalidPayment = true;
            }
            await this.getOrderScanPayStatus();
            if (this.count > 0) {
                this.count -= 1;
                this.initPayTime();
            };
        }, 1000);
    }

    initPayTime() {
        let t: any = moment.duration(this.count, "seconds");
        let m: any = t.minutes();
        let s: any = t.seconds();
        this.paymentTime = moment({ m: m, s: s }).format(`${m ? "mm分" : ""}ss秒`);
    };

    /**
     * 获取订单支付时的支付状态
     */
    getOrderScanPayStatus = async () => {
        let { pay } = this.payInfo;
        let { outtradeno, orderno } = pay;
        let param: any = { "outTradeNo": outtradeno, "orderno": orderno };
        let result: any = await this.controller.getOrderScanPaymentStatus(param);
        if (result && result.outtradeno === outtradeno && result.orderno === orderno && result.statues === "TRADE_SUCCESS") {
            clearInterval(this.timer);
            this.isPaySuccess = true;
        };
    }

    /**
     * 重新支付
     */
    repayment = async () => {
        let { payWay } = this.payInfo;
        let { no, amount } = this.order;
        let param: any = { payWay: payWay, orderNo: no, amount: amount };
        let result: any = await this.controller.createScanPayment(param);
        if (result && result.paySign) {
            this.isCanPayment = true;
            this.payInfo.pay = result;
            this.qrCodeUrl = result.paySign;
            this.invalidPayment = false;
            this.count = setTimeCount;
            this.initTimer();
        } else {
            this.isCanPayment = false;
        };
    };

    content(): JSX.Element {
        return React.createElement(observer(() => {
            let contentUI: any;
            if (this.isPaySuccess) {
                contentUI = <div className='m-auto align-self-center text-center pt-5'  >
                    <div className='my-3' ><i className="fa fa-5x fa-check-circle text-success" aria-hidden="true" /></div>
                    <h3>付款成功！</h3>
                    <p className='text-muted small' >您已完成支付.</p>
                    <button onClick={()=>{ window.history.go() }} className='btn btn-sm btn-outline-primary px-3' >知道了</button>
                </div>
            } else {
                let payCodeUI: any, invalidPaymentUI: any;
                /*  */
                if (this.payInfo.type === "Alipay") {
                    payCodeUI = <iframe title={this.payInfo.type} width="100%" height="100%" scrolling='no'
                        style={{ border: 0 }} src={this.qrCodeUrl} allow="payment" ></iframe>;
                } else {
                    payCodeUI = <QRCode size={300} value={this.qrCodeUrl} />;
                };
                if (this.invalidPayment) {
                    invalidPaymentUI = <div className='position-absolute bg-light d-flex flex-column justify-content-center align-items-center'
                        style={{ top: 0, left: 0, right: 0, bottom: 0 }} >
                        <div>{!this.isCanPayment ? "二维码获取失败" : "二维码过期"} </div>
                        <div onClick={this.repayment} className='text-primary cursor-pointer' >
                            <i className="fa fa-rotate-right mr-1" aria-hidden="true" />点击刷新</div>
                    </div>
                } else { invalidPaymentUI = <></>; };

                contentUI = <div>
                    <div className='d-flex justify-content-between'>
                        <h5 className='font-weight-bold' >{this.payInfo.name}</h5>
                        <div className='text-muted '>
                            <small>应付总额：</small>
                            <span className='text-danger' >{this.order?.amount}元</span>
                        </div>
                    </div>
                    {this.isCanPayment && <div>
                        <p className='text-danger' >支付剩余：{this.paymentTime}</p>
                    </div>}
                    <div className='row mx-0 my-4' >
                        <div className='col-12 col-md-6 d-flex justify-content-center'>
                            <div style={{ width: 330 }} >
                                <div className='border d-flex justify-content-center align-items-center position-relative'
                                    style={{ width: 330, height: 330 }} >
                                    {invalidPaymentUI}
                                    <div style={{ width: 300, height: 300, background: "#e5e5e5" }} >
                                        {payCodeUI}
                                    </div>
                                </div>
                                <div className='mt-2 text-light small d-flex justify-content-center p-2'
                                    style={{ background: "rgb(235, 94, 74, .9)" }} >
                                    <img className='w-3c h-3c' src="/images/pay/scan.svg" alt="" />
                                    <span className='ml-4'>
                                        请使用{this.payInfo.alias}扫一扫 <br />扫描二维码支付
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 text-center mt-3 mt-md-0' ><img className='w-20c' src={this.payInfo.assistImg} alt={`${this.payInfo.name}帮助`} /></div>
                    </div>
                    <div className='text-primary cursor-pointer mb-5' onClick={() => {
                        clearInterval(this.timer);
                        this.closePage();
                    }} >
                        <i className="fa fa-angle-left mr-2" aria-hidden="true" />
                        选择其他支付方式
                    </div>
                </div>
            };

            return <div className='pt-3 px-md-4' style={{ minHeight: 600 }}>
                {contentUI}
            </div>;
        }));
    };
}