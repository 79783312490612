//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqSheet, UqQuery, UqMap, UqID, UqIDX, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/order ========
//===============================

export enum EnumFeeType {
	packing = 11,
	refrigeration = 12,
	freezing = 13,
	proxyFreight = 14
}

export enum EnumAmountDiff {
	packing = 11,
	refrigeration = 12,
	freezing = 13,
	proxyFreight = 14
}

export enum EnumOrderState {
	matching = 11,
	delivering = 12,
	delivered = 13,
	completed = 14,
	canceled = 15,
	pendingPayment = 21,
	paid = 22
}

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidCoupon {
	id?: number;
	code: string;
	user: number;
	customer: number;
	discount: number;
	preferential: number;
	validitydate: any;
	isValid: number;
}

export interface TuidChemical {
	id?: number;
	CAS: string;
}

export interface TuidSalesRegion {
	id?: number;
	name: string;
	currency: number;
	no: string;
}

export interface TuidCurrency {
	id?: number;
	name: string;
	suffix: string;
}

export interface TuidPackType {
	id?: number;
	name: string;
	description: string;
}

export interface TuidAddress {
	id?: number;
	country: number;
	province: number;
	city: number;
	county: number;
	description: string;
}

export interface TuidCountry {
	id?: number;
	code: string;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidProvince {
	id?: number;
	country: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidCity {
	id?: number;
	province: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidCounty {
	id?: number;
	city: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidInvoiceType {
	id?: number;
	description: string;
}

export interface TuidCustomer {
	id?: number;
	name: string;
}

export interface TuidOrganization {
	id?: number;
	name: string;
}

export interface TuidContact {
	id?: number;
	name: string;
	organizationName: string;
	mobile: string;
	telephone: string;
	email: string;
	address: number;
	addressString: string;
}

export interface TuidInvoiceInfo {
	id?: number;
	title: string;
	taxNo: string;
	address: string;
	telephone: string;
	bank: string;
	accountNo: string;
}

export interface TuidBrand {
	id?: number;
	name: string;
	no: string;
}

export interface TuidProductX {
	id?: number;
	brand: number;
}

export interface TuidWarehouse {
	id?: number;
	name: string;
}

export interface TuidWebUser {
	id?: number;
	name: string;
	no: number;
	password: string;
	icon: string;
}

export interface TuidBuyerAccount {
	id?: number;
}

export interface TuidCommonText {
	id?: number;
	content: string;
}

export interface TuidExpressLogistics {
	id?: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface ParamSetCart {
	product: number;
	pack: number;
	price: number;
	currency: number;
	quantity: number;
}
export interface ResultSetCart {
}

export interface ParamRemoveFromCart {
	rows: {
		product: number;
		pack: number;
	}[];

}
export interface ResultRemoveFromCart {
}

export interface ParamMergeCart {
	rows: {
		product: number;
		pack: number;
		quantity: number;
	}[];

}
export interface ResultMergeCart {
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface SheetOrder {
	webUser: number;
	organization: number;
	customer: number;
	shippingContact: number;
	invoiceContact: number;
	invoiceType: number;
	invoiceInfo: number;
	order2BC: number;
	amount: number;
	currency: number;
	freightFee: number;
	freightFeeRemitted: number;
	coupon: number;
	couponOffsetAmount: number;
	couponRemitted: number;
	point: number;
	comments: string;
	salesRegion: number;
	orderItems: {
		product: number;
		pack: number;
		price: number;
		quantity: number;
		subAmount: number;
		refrigerationFee: number;
		freezingFee: number;
		packingFee: number;
		proxyFreightFee: number;
	}[];
}
export interface VerifyOrder {
}

export interface ParamGetCart {
}
export interface ReturnGetCart$page {
	date: any;
	product: number;
	pack: number;
	price: number;
	currency: number;
	quantity: number;
}
export interface ResultGetCart {
	$page: ReturnGetCart$page[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamSearchOrders {
	keyWord: string;
	state: string;
	customer: number;
}
export interface ReturnSearchOrders$page {
	seq: number;
	id: number;
	no: string;
	date: any;
	state: string;
}
export interface ResultSearchOrders {
	$page: ReturnSearchOrders$page[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface ParamGetOrder {
	state: string;
	webUser: number;
}
export interface ReturnGetOrder$page {
	id: number;
	no: string;
	webUser: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	description: string;
	processing: number;
}
export interface ResultGetOrder {
	$page: ReturnGetOrder$page[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface ParamSearchOrderByBuyerAccount {
	buyeraccount: number;
}
export interface ReturnSearchOrderByBuyerAccount$page {
	seq: number;
	id: number;
	no: string;
	webUser: number;
	customer: number;
	buyerAccount: number;
	shippingContact: number;
	invoiceContact: number;
	invoiceType: number;
	invoiceInfo: number;
	order2BC: number;
	sumAmount: number;
	currency: number;
	couponNo: string;
	sheetId: number;
	createDate: any;
}
export interface ResultSearchOrderByBuyerAccount {
	$page: ReturnSearchOrderByBuyerAccount$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface OrderDetail {
	id?: number;
	main?: number;
	warehouse: number;
	item: number;
	product: number;
	quantity: number;
	price: number;
	amount: number;
	lotNumber: string;
	createDate: any;
}

export interface OrderMain {
	id?: number;
	no?: string;
	webUser: number;
	customer: number;
	buyerAccount: number;
	shippingContact: number;
	invoiceContact: number;
	invoiceType: number;
	invoiceInfo: number;
	order2BC: number;
	sumAmount: number;
	currency: number;
	couponNo: string;
	sheetId: number;
	createDate: any;
}

export interface ReturnDetail {
	id?: number;
	main?: number;
	orderDetail: number;
	item: number;
	product: number;
	quantity: number;
	price: number;
	amount: number;
	lotNumber: string;
	createDate: any;
}

export interface ReturnMain {
	id?: number;
	no?: string;
	customerAccount: number;
	webUser: number;
	shippingContact: number;
	invoiceContact: number;
	sumAmount: number;
	currency: number;
	createDate: any;
}

export interface OrderMainEx {
	id?: number;
	seller: number;
	salesman: number;
	salesRegion: number;
	organization: number;
	currency: number;
	poNumber: string;
	orderType: number;
	carrier: number;
	commentsAboutDeliver: string;
	invoiceDesc: string;
	createDate: any;
}

export interface OrderDetailEx {
	id?: number;
	orderItemId: string;
	endUser: number;
	brand: number;
	amountAfterTax: number;
	retail: number;
	retailCurrency: number;
	bottomPrice: number;
	bottomPriceCurrency: number;
	costPrice: number;
	costPriceCurrency: number;
	poItemNumber: string;
	mark: string;
	tradeType: string;
	taxRate: number;
	promotionId: string;
	salesCategory: string;
	createDate: any;
}

export interface ReturnDetailEx {
	id?: number;
	returnItemId: string;
	endUser: number;
	mark: string;
}

export interface ReturnMainEx {
	id?: number;
	seller: number;
	salesman: number;
	salesRegion: number;
}

export interface B2BC {
	id?: number;
	name: string;
	description: string;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface DxOrderMainState {
	id: number;
	state?: any;
	createDate?: any;
	$act?: number;
}

export interface BuyerAccount2BC {
	id: number;
	account2BC?: number;
	$act?: number;
}

export interface ActParamDxOrderMainState {
	id: number|IDXValue;
	state?: any|IDXValue;
	createDate?: any|IDXValue;
	$act?: number;
}

export interface ActParamBuyerAccount2BC {
	id: number|IDXValue;
	account2BC?: number|IDXValue;
	$act?: number;
}

export interface OrderDetailDeliver {
	ix: number;
	xi: number;
	deliverDone: number;
	deliverTime: any;
}

export interface OrderDetailReceive {
	ix: number;
	xi: number;
	receiveDone: number;
	receiveTime: any;
}

export interface $ixphrase {
	type: number;
}

export interface ParamActs {
	$User?: $User[];
	orderDetail?: OrderDetail[];
	orderMain?: OrderMain[];
	returnDetail?: ReturnDetail[];
	returnMain?: ReturnMain[];
	orderMainEx?: OrderMainEx[];
	orderDetailEx?: OrderDetailEx[];
	returnDetailEx?: ReturnDetailEx[];
	returnMainEx?: ReturnMainEx[];
	b2BC?: B2BC[];
	$phrase?: $phrase[];
	$UserSite?: $UserSite[];
	$Site?: $Site[];
	dxOrderMainState?: ActParamDxOrderMainState[];
	buyerAccount2BC?: ActParamBuyerAccount2BC[];
	orderDetailDeliver?: OrderDetailDeliver[];
	orderDetailReceive?: OrderDetailReceive[];
	$ixphrase?: $ixphrase[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	Coupon: UqTuid<TuidCoupon>;
	Chemical: UqTuid<TuidChemical>;
	SalesRegion: UqTuid<TuidSalesRegion>;
	Currency: UqTuid<TuidCurrency>;
	PackType: UqTuid<TuidPackType>;
	Address: UqTuid<TuidAddress>;
	Country: UqTuid<TuidCountry>;
	Province: UqTuid<TuidProvince>;
	City: UqTuid<TuidCity>;
	County: UqTuid<TuidCounty>;
	InvoiceType: UqTuid<TuidInvoiceType>;
	Customer: UqTuid<TuidCustomer>;
	Organization: UqTuid<TuidOrganization>;
	Contact: UqTuid<TuidContact>;
	InvoiceInfo: UqTuid<TuidInvoiceInfo>;
	Brand: UqTuid<TuidBrand>;
	ProductX: UqTuid<TuidProductX>;
	Warehouse: UqTuid<TuidWarehouse>;
	WebUser: UqTuid<TuidWebUser>;
	BuyerAccount: UqTuid<TuidBuyerAccount>;
	CommonText: UqTuid<TuidCommonText>;
	ExpressLogistics: UqTuid<TuidExpressLogistics>;
	SetCart: UqAction<ParamSetCart, ResultSetCart>;
	RemoveFromCart: UqAction<ParamRemoveFromCart, ResultRemoveFromCart>;
	MergeCart: UqAction<ParamMergeCart, ResultMergeCart>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	Order: UqSheet<SheetOrder, VerifyOrder>;
	GetCart: UqQuery<ParamGetCart, ResultGetCart>;
	$poked: UqQuery<Param$poked, Result$poked>;
	SearchOrders: UqQuery<ParamSearchOrders, ResultSearchOrders>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	GetOrder: UqQuery<ParamGetOrder, ResultGetOrder>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	SearchOrderByBuyerAccount: UqQuery<ParamSearchOrderByBuyerAccount, ResultSearchOrderByBuyerAccount>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	$sites: UqQuery<Param$sites, Result$sites>;
	SalesRegionWarehouse: UqMap;
	OrderBuyerAccount: UqMap;
	CurrencyExchangeRate: UqMap;
	OrderDetailSplit: UqMap;
	$User: UqID<any>;
	OrderDetail: UqID<any>;
	OrderMain: UqID<any>;
	ReturnDetail: UqID<any>;
	ReturnMain: UqID<any>;
	OrderMainEx: UqID<any>;
	OrderDetailEx: UqID<any>;
	ReturnDetailEx: UqID<any>;
	ReturnMainEx: UqID<any>;
	B2BC: UqID<any>;
	$phrase: UqID<any>;
	$UserSite: UqID<any>;
	$Site: UqID<any>;
	DxOrderMainState: UqIDX<any>;
	BuyerAccount2BC: UqIDX<any>;
	OrderDetailDeliver: UqIX<any>;
	OrderDetailReceive: UqIX<any>;
	$ixphrase: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
