import { centerApi } from "centerApi";
import { ActEntity } from "./entity";
import _ from "lodash";
import dayjs from 'dayjs';

class Order extends ActEntity {
    protected get base() { return centerApi.apiV2.orderApi };

    /**
     * 获取订单物流轨迹
     */
    getOrdertrans = async (deliverno: any) => {
        try {
            let url: string = this.base.getOrdertrans + deliverno;
            let res: any = await centerApi.apiV2.get(url);
            let { success, message, data } = res;
            let resultData: any = {
                result: success, time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                mailno: deliverno, steps: { step: data || [] }
            };
            return { success: success, message: message, result: resultData };
        } catch (error: any) {
            return { success: false, message: error.message, result: undefined };
        };
    };


};

export const JKOrder = new Order();