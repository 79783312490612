import { ApiEntity, Hosts } from "./entity";

class WebApi extends ApiEntity {
    protected get base() {
        return this.env.testing ? Hosts.web : Hosts.web;
    }

    getMsds = "/partial/productMsdsFileByOrigin/";
    getSpec = "/partial/productSpecFileByOrigin/";
    getUM = "/partial/productUserManualFileByOrigin/";
    getCoa = "/partial/coaFileLotNumber/";

    /* 产品id获取MSDS */
    getMsdsById = "/partial/productMsdsFileByProductId/";
    /* 产品编号查询存在MSDS的产品列表 */
    getProductByOirigin = "/partial/productMsdsListFileInfoByOrigin/";
}

export const webApi = new WebApi();
