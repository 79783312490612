// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { StatuteChemical } from "./JkChemicalSecurity";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	main: {
		"name": "main",
		"type": "id",
		"isKey": false,
		"label": "Main"
	} as FieldItemId,
	hazardClass: {
		"name": "hazardClass",
		"type": "id",
		"isKey": false,
		"label": "HazardClass"
	} as FieldItemId,
	standardName: {
		"name": "standardName",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "StandardName"
	} as FieldItemString,
	cas: {
		"name": "cas",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Cas"
	} as FieldItemString,
	json: {
		"name": "json",
		"isKey": false,
		"label": "Json"
	} as undefined,
	createDate: {
		"name": "createDate",
		"isKey": false,
		"label": "CreateDate"
	} as undefined,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.main, fields.hazardClass, fields.standardName, fields.cas, fields.json, fields.createDate, 
];

export const ui: UI = {
	label: "StatuteChemical",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: StatuteChemical):JSX.Element {
	return <>{uqStringify(item)}</>;
};
