class ApiHttp {
    static async staticFetch(url: string = '', options: any = {}) {
        const defaultOptions: any = {
            mode: 'cors',
            headers: {},
        };
        if (options.method === 'POST' || 'PUT') {
            defaultOptions.headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        const newOptions = { ...defaultOptions, ...options };
        return await fetch(url, newOptions);
    }

    async get(url: string, option: any = {}) {
        const options = Object.assign({ method: 'GET' }, option);
        return ApiHttp.staticFetch(url, options);
    }

    async post(url: string, param: any = {}, option: any = {}) {
        const options = Object.assign({ method: 'POST' }, option);
        options.body = JSON.stringify(param);
        if (options.type === 'FormData' && options.body !== 'undefined') {
            let params = new FormData();
            for (let key of Object.keys(options.body)) {
                params.append(key, options.body[key]);
            }
            options.body = params;
        };
        return ApiHttp.staticFetch(url, options);
    };

    async put(url: string, param: any = {}, option: any = {}) {
        const options = Object.assign({ method: 'PUT' }, option);
        options.body = JSON.stringify(param);
        return ApiHttp.staticFetch(url, options);
    };

    async del(url: string, option: any = {}) {
        const options = Object.assign({ method: 'DELETE' }, option);
        return ApiHttp.staticFetch(url, options);
    };
};

const requestFun = new ApiHttp();
export const { post, get, put, del } = requestFun;
export default requestFun;