//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID, UqIDX, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/customer ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface TuidCurrency {
	id?: number;
	name: string;
	suffix: string;
}

export interface TuidContact {
	id?: number;
	name: string;
	organizationName: string;
	mobile: string;
	telephone: string;
	email: string;
	addressString: string;
	address: number;
}

export interface TuidCustomer {
	id?: number;
	name: string;
	firstName: string;
	lastName: string;
	xyz: string;
	no: string;
	gender: string;
	salutation: string;
	birthDay: any;
	createTime: any;
	isValid: number;
}

export interface TuidInvoiceType {
	id?: number;
	description: string;
}

export interface TuidResearch {
	id?: number;
	name: string;
	parent: number;
	no: string;
	createTime: any;
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidProvince {
	id?: number;
	country: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidDepartment {
	id?: number;
	name: string;
	organization: number;
	no: string;
	createTime: any;
}

export interface TuidBuyerAccount {
	id?: number;
	description: string;
	organization: number;
	xyz: string;
	no: string;
	createTime: any;
	isValid: number;
}

export interface TuidOrganization {
	id?: number;
	name: string;
	no: string;
	createTime: any;
}

export interface TuidCountry {
	id?: number;
	code: string;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidInvoiceInfo {
	id?: number;
	title: string;
	taxNo: string;
	address: string;
	telephone: string;
	bank: string;
	accountNo: string;
	invoiceType: string;
}

export interface TuidCity {
	id?: number;
	province: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidPost {
	id?: number;
	name: string;
	no: string;
	createTime: any;
}

export interface TuidCounty {
	id?: number;
	city: number;
	englishName: string;
	chineseName: string;
	no: string;
}

export interface TuidSalesRegion {
	id?: number;
	name: string;
	currency: number;
	no: string;
}

export interface TuidEmployee {
	id?: number;
	no: string;
	name: string;
	firstName: string;
	lastName: string;
	title: string;
	Status: string;
	CreateTime: any;
}

export interface TuidAddress {
	id?: number;
	country: number;
	province: number;
	city: number;
	county: number;
	zip: string;
	description: string;
}

export interface TuidDomain {
	id?: number;
	name: string;
	parent: number;
	no: string;
	createTime: any;
}

export interface TuidProductX {
	id?: number;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
}

export interface TuidBrand {
	id?: number;
	name: string;
}

export interface TuidCustomerSettingType {
	id?: number;
	description: string;
}

export interface TuidVIPCardType {
	id?: number;
}

export interface TuidOrganizationType {
	id?: number;
	name: string;
	parent: number;
	no: string;
	createTime: any;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamIsValidCustomerExe {
	customer: number;
	comments: string;
	isValid: number;
}
export interface ResultIsValidCustomerExe {
}

export interface ParamAcceptCustomerCreditsUsed {
	customer: number;
	coupon: number;
	orderItemId: string;
}
export interface ResultAcceptCustomerCreditsUsed {
}

export interface ParamAcceptCustomerCouponUsed {
	customer: number;
	coupon: number;
}
export interface ResultAcceptCustomerCouponUsed {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface ParamSearchCustomer {
	key: string;
}
export interface ReturnSearchCustomer$page {
	id: number;
	no: string;
	name: string;
	firstName: string;
	lastName: string;
	gender: string;
	salutation: string;
}
export interface ResultSearchCustomer {
	$page: ReturnSearchCustomer$page[];
}

export interface ParamGetBuyerAccountByNo {
	buyAccountNo: string;
}
export interface ReturnGetBuyerAccountByNoRet {
	buyerAccount: number;
	organization: number;
	description: string;
	xyz: string;
	no: string;
	createTime: any;
	isValid: number;
}
export interface ResultGetBuyerAccountByNo {
	ret: ReturnGetBuyerAccountByNoRet[];
}

export interface ParamGetCustomerByNo {
	customerNo: string;
}
export interface ReturnGetCustomerByNoRet {
	customer: number;
}
export interface ResultGetCustomerByNo {
	ret: ReturnGetCustomerByNoRet[];
}

export interface ParamGetCustomerOrganization {
	customerId: number;
}
export interface ReturnGetCustomerOrganizationRet {
	organization: number;
	customer: number;
}
export interface ResultGetCustomerOrganization {
	ret: ReturnGetCustomerOrganizationRet[];
}

export interface ParamGetCustomerByKey {
	key: string;
}
export interface ReturnGetCustomerByKeyRet {
	customer: number;
}
export interface ResultGetCustomerByKey {
	ret: ReturnGetCustomerByKeyRet[];
}

export interface ParamSearchDomain {
	_parent: number;
}
export interface ReturnSearchDomain$page {
	id: number;
	name: string;
	counts: number;
}
export interface ResultSearchDomain {
	$page: ReturnSearchDomain$page[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamGetMyUsedCoupon {
	customer: number;
}
export interface ReturnGetMyUsedCoupon$page {
	seq: number;
	id: number;
	code: number;
	type: number;
	useddate: any;
}
export interface ResultGetMyUsedCoupon {
	$page: ReturnGetMyUsedCoupon$page[];
}

export interface ParamGetMyExpiredCoupon {
	customer: number;
}
export interface ReturnGetMyExpiredCoupon$page {
	seq: number;
	id: number;
	code: number;
	type: number;
	createdate: any;
	expireddate: any;
}
export interface ResultGetMyExpiredCoupon {
	$page: ReturnGetMyExpiredCoupon$page[];
}

export interface ParamGetResearchByParent {
	parent: number;
	key: string;
}
export interface ReturnGetResearchByParentRet {
	id: number;
	name: string;
	parent: number;
}
export interface ResultGetResearchByParent {
	ret: ReturnGetResearchByParentRet[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface ParamGetCustomerByBuyerAccount {
	buyerAccount: number;
	key: string;
}
export interface ReturnGetCustomerByBuyerAccount$page {
	id: number;
	customer: number;
	buyerAccount: number;
}
export interface ResultGetCustomerByBuyerAccount {
	$page: ReturnGetCustomerByBuyerAccount$page[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface Certificate {
	id?: number;
	name: string;
	description: string;
}

export interface CertificateSource {
	id?: number;
	certificate: number;
	buyeraccount: number;
	expiredDate: any;
	path: string;
	status: number;
	creator: number;
	createDate: any;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface ResearchGroup {
	id?: number;
	organization: number;
	name: string;
	managerCustomer: number;
	isValid: number;
	creator: number;
	createDate: any;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface ResearchGroupMemberRole {
	id?: number;
	name: string;
	description: string;
}

export interface DeepSalesVIPCustomerHistory {
	id?: number;
	customer: number;
	operation: number;
	comments: string;
	creator: number;
	createDate: any;
}

export interface CretificateSourceAuditHistory {
	id: number;
	status?: number;
	comments?: string;
	auditor?: number;
	$act?: number;
}

export interface DxPendingAuditCertificate {
	id: number;
	createDate?: any;
	$act?: number;
}

export interface DeepSalesVIPCustomer {
	id: number;
	creator?: number;
	createDate?: any;
	$act?: number;
}

export interface ActParamCretificateSourceAuditHistory {
	id: number|IDXValue;
	status?: number|IDXValue;
	comments?: string|IDXValue;
	auditor?: number|IDXValue;
	$act?: number;
}

export interface ActParamDxPendingAuditCertificate {
	id: number|IDXValue;
	createDate?: any|IDXValue;
	$act?: number;
}

export interface ActParamDeepSalesVIPCustomer {
	id: number|IDXValue;
	creator?: number|IDXValue;
	createDate?: any|IDXValue;
	$act?: number;
}

export interface BuyeraccountCertificate {
	ix: number;
	xi: number;
	expiredDate: any;
	path: string;
	createDate: any;
}

export interface ResearchGroupMember {
	ix: number;
	xi: number;
	role: number;
}

export interface ParamActs {
	$User?: $User[];
	certificate?: Certificate[];
	certificateSource?: CertificateSource[];
	$phrase?: $phrase[];
	$UserSite?: $UserSite[];
	researchGroup?: ResearchGroup[];
	$Site?: $Site[];
	researchGroupMemberRole?: ResearchGroupMemberRole[];
	deepSalesVIPCustomerHistory?: DeepSalesVIPCustomerHistory[];
	cretificateSourceAuditHistory?: ActParamCretificateSourceAuditHistory[];
	dxPendingAuditCertificate?: ActParamDxPendingAuditCertificate[];
	deepSalesVIPCustomer?: ActParamDeepSalesVIPCustomer[];
	buyeraccountCertificate?: BuyeraccountCertificate[];
	researchGroupMember?: ResearchGroupMember[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	Currency: UqTuid<TuidCurrency>;
	Contact: UqTuid<TuidContact>;
	Customer: UqTuid<TuidCustomer>;
	InvoiceType: UqTuid<TuidInvoiceType>;
	Research: UqTuid<TuidResearch>;
	$sheet: UqTuid<Tuid$sheet>;
	Province: UqTuid<TuidProvince>;
	Department: UqTuid<TuidDepartment>;
	BuyerAccount: UqTuid<TuidBuyerAccount>;
	Organization: UqTuid<TuidOrganization>;
	Country: UqTuid<TuidCountry>;
	InvoiceInfo: UqTuid<TuidInvoiceInfo>;
	City: UqTuid<TuidCity>;
	Post: UqTuid<TuidPost>;
	County: UqTuid<TuidCounty>;
	SalesRegion: UqTuid<TuidSalesRegion>;
	Employee: UqTuid<TuidEmployee>;
	Address: UqTuid<TuidAddress>;
	Domain: UqTuid<TuidDomain>;
	ProductX: UqTuid<TuidProductX>;
	Brand: UqTuid<TuidBrand>;
	CustomerSettingType: UqTuid<TuidCustomerSettingType>;
	VIPCardType: UqTuid<TuidVIPCardType>;
	OrganizationType: UqTuid<TuidOrganizationType>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	IsValidCustomerExe: UqAction<ParamIsValidCustomerExe, ResultIsValidCustomerExe>;
	AcceptCustomerCreditsUsed: UqAction<ParamAcceptCustomerCreditsUsed, ResultAcceptCustomerCreditsUsed>;
	AcceptCustomerCouponUsed: UqAction<ParamAcceptCustomerCouponUsed, ResultAcceptCustomerCouponUsed>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	SearchCustomer: UqQuery<ParamSearchCustomer, ResultSearchCustomer>;
	GetBuyerAccountByNo: UqQuery<ParamGetBuyerAccountByNo, ResultGetBuyerAccountByNo>;
	GetCustomerByNo: UqQuery<ParamGetCustomerByNo, ResultGetCustomerByNo>;
	GetCustomerOrganization: UqQuery<ParamGetCustomerOrganization, ResultGetCustomerOrganization>;
	GetCustomerByKey: UqQuery<ParamGetCustomerByKey, ResultGetCustomerByKey>;
	SearchDomain: UqQuery<ParamSearchDomain, ResultSearchDomain>;
	$poked: UqQuery<Param$poked, Result$poked>;
	GetMyUsedCoupon: UqQuery<ParamGetMyUsedCoupon, ResultGetMyUsedCoupon>;
	GetMyExpiredCoupon: UqQuery<ParamGetMyExpiredCoupon, ResultGetMyExpiredCoupon>;
	GetResearchByParent: UqQuery<ParamGetResearchByParent, ResultGetResearchByParent>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	GetCustomerByBuyerAccount: UqQuery<ParamGetCustomerByBuyerAccount, ResultGetCustomerByBuyerAccount>;
	CustomerDepartment: UqMap;
	CustomerSetting: UqMap;
	CustomerContractor: UqMap;
	CustomerHandler: UqMap;
	CustomerResearch: UqMap;
	CustomerBuyerAccount: UqMap;
	OrganizationCustomer: UqMap;
	PostRelation: UqMap;
	CustomerContacts: UqMap;
	CustomerPost: UqMap;
	CustomerDomain: UqMap;
	CustomerRelatedProducts: UqMap;
	ResearchDomain: UqMap;
	OrganizationSetting: UqMap;
	CustomerSettingAlter: UqMap;
	CustomerCreditsUsed: UqMap;
	CustomerCredits: UqMap;
	CustomerCoupon: UqMap;
	CustomerCouponUsed: UqMap;
	OrganizationOrganizationType: UqMap;
	$User: UqID<any>;
	Certificate: UqID<any>;
	CertificateSource: UqID<any>;
	$phrase: UqID<any>;
	$UserSite: UqID<any>;
	ResearchGroup: UqID<any>;
	$Site: UqID<any>;
	ResearchGroupMemberRole: UqID<any>;
	DeepSalesVIPCustomerHistory: UqID<any>;
	CretificateSourceAuditHistory: UqIDX<any>;
	DxPendingAuditCertificate: UqIDX<any>;
	DeepSalesVIPCustomer: UqIDX<any>;
	BuyeraccountCertificate: UqIX<any>;
	ResearchGroupMember: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
