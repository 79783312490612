import { appApis } from "centerApi";

/**
 * 获取目录树(三级结构)
 * @param categories 递至下一次的目录树层级
 * @returns 目录树层级
 */
export const getCategoriesTree = async () => {
    // 1. 获取根目录
    try {
        let rootCatalogue = await appApis.JKHeader.getRootProductCategories();

        // 2. 获取二级目录树
        const catalogue = await Promise.all(
            rootCatalogue.map(async (item: any) => {
                const nextLevel =
                    await appApis.JKHeader.getSecondLevelProductCategories(
                        item.id
                    );
                return nextLevel;
            })
        );
        // 3. 获取三级目录树
        const categoriesWithChildren = await Promise.all(
            catalogue.map(async (item: any) => {
                const childrenWithNextLevel = await Promise.all(
                    item.children.map(async (child: any) => {
                        const nextLevel =
                            await appApis.JKHeader.getSecondLevelProductCategories(
                                child.id
                            );
                        if (nextLevel.isLeaf) {
                            nextLevel.children = [nextLevel];
                        }
                        return nextLevel;
                    })
                );
                return { ...item, children: childrenWithNextLevel };
            })
        );
        return categoriesWithChildren;
    } catch (err) {
        console.error("🚨 ~ getCategoriesTree error:", err);
        throw err;
    }
};
