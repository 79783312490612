import * as React from 'react';
import { Image } from './image';

const imagePath = "https://static.jkchemical.com/Structure/";
const pointProductImagePath = "https://static.jkchemical.com/images/pointshop";
const altimagePath = "https://static.jkchemical.com/Structure/999.png";
const altimagePathA01 = "https://static.jkchemical.com/Structure/0-5095.png";
const altimagePathA10 = "https://static.jkchemical.com/Structure/0-1759.png";

interface ProductImageProps {
    className?: string;
    style?: React.CSSProperties;
    chemicalId: string;
    alt?: string;
    brandId?: number;
};

const altimagePaths: any = {
    "71": altimagePathA01,
    "18": altimagePathA10,
};

export function ProductImage(props: ProductImageProps) {

    let { style, className, chemicalId, alt, brandId } = props;

    let t = new Date().getTime();
    let altimagePathErr: string = (brandId && [71, 18].includes(brandId)) ? altimagePaths[brandId] : altimagePath;

    return <Image src={chemicalId && (imagePath + chemicalId.substr(0, 3) + '/' + chemicalId + '.png'+ "?t=" + t)}
        style={style} className={className} altImage={altimagePathErr} alt={alt} />;
}

// export function PointProductImage(props: ProductImageProps) {

//     let { style, className, chemicalId } = props;
//     return <Image src={chemicalId && (pointProductImagePath + '/' + chemicalId + '.png')}
//         style={style} className={className} altImage={altimagePath} />;
// }

export function PointProductImage(props: ProductImageProps) {
    // console.log('props',props);

    let { style, className, chemicalId } = props;
    if (chemicalId && /[0-9]$/.test(chemicalId))
        chemicalId = pointProductImagePath + '/' + chemicalId + '.png';

    if (chemicalId && chemicalId.startsWith(":"))
        chemicalId = imgfromTvJkchemical(chemicalId);

    return <Image src={chemicalId && chemicalId}
        style={style} className={className} altImage={altimagePath} />;
}

export function imgfromTvJkchemical(image: string) {
    if (!image) return '';
    return "https://shop.jkchemical.com/res/" + image.substr(1);
};