import { VPage } from 'tonva-react';
import { COrder } from './COrder';
import { xs } from '../tools/browser';
import { Checkbox, List, Modal } from 'antd';
import { makeObservable, observable } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';

export const paymentMods: { [type: string]: any } = {
    "WechatPay": {
        type: "WeChatpay", payWay: "WechatPay", name: "微信支付", alias: "微信",
        icon: "/images/pay/WeChatpay.svg",
        assistImg: "/images/pay/payAssist.png",
    },
    "AliPay": {
        type: "Alipay", payWay: "AliPay", name: "支付宝支付", alias: "支付宝",
        icon: "/images/pay/Alipay.svg",
        assistImg: "/images/pay/payAssist-ali.png",
    }
};

export class VOrderSuccessPayment extends VPage<COrder> {
    isOnlinePay: boolean = false;
    isChecked: boolean = false;
    timeOut: any;
    orderResult: any;
    payModes: any[] = [
        {
            type: "WeChatpay", payWay:"WechatPay", name: "微信支付", alias:"微信", icon: "/images/pay/WeChatpay.svg",
            assistImg: "/images/pay/payAssist.png",
        },
        {
            type: "Alipay", payWay:"AliPay", name: "支付宝支付", alias:"支付宝", icon: "/images/pay/Alipay.svg",
            assistImg: "/images/pay/payAssist-ali.png",
        }
    ];

    constructor(c: COrder) {
        super(c);
        makeObservable(this, {
            isOnlinePay: observable,
            isChecked: observable,
            timeOut: observable,
        });
    }

    init(param: any) {
        this.orderResult = param;
    }

    header() {
        if (xs) return '下单成功';
        return false;
    }

    reanderItem = (item: any) => {
        let { icon, name } = item;
        return <div className='border mb-2 text-center py-3' >
            <img src={icon} alt={name} className="h-1-5c" />
            <span className='ml-2'>{name}</span>
        </div>;
    };

    onChange = (e: any) => {
        this.isChecked = e.target.checked;
    };

    openScanCodePayment = async (item: any, spanId: string) => {
        if (!this.isChecked) {
            let elem:any = document.getElementById(spanId)
            if (elem) {
                elem.classList.add('shake')
                this.timeOut = setTimeout(() => {
                    elem.classList.remove('shake');
                }, 500);
                return;
            }
        }
        await this.controller.openScanCodePayment({ payment: item, order: this.orderResult });
    };

    content(): JSX.Element {
        return React.createElement(observer(() => {
            return <div style={{ minHeight: 600 }}>
                <div className='p-4' style={{ backgroundColor: "#9c2424" }} >
                    <div className='h4 text-light mb-3' >订单已提交成功</div>
                </div>
                <div className='p-4 mb-3'>
                    <div>订单编号：{this.orderResult?.no}</div>
                    <div>订单总额：{this.orderResult?.amount} 元</div>
                </div>
                <div className='opt-payment card border-0 mb-5' >
                    <ol className="mb-2" style={{ borderBottom: "1px dashed rgb(136, 136, 136,.2)" }}>
                        <li className='cart-title'>对公付款请联系客服</li>
                        <li className='cart-title'  >
                            如若在线支付，请点击<button onClick={() => { this.isOnlinePay = true }}
                                className='btn btn-sm btn-outline-primary border-0 font-weight-bolder bg-ali-primary'
                                style={{ fontSize: 16, }}
                            >在线支付</button>
                        </li>
                    </ol>
                    {/* <div className='cart-title py-2 px-4' style={{ borderBottom: "1px dashed rgb(136, 136, 136,.2)" }} >
                        如若在线支付，请点击<button onClick={() => { this.isOnlinePay = true }}
                            className='btn btn-sm btn-outline-success border-0' >在线支付</button></div> */}
                    {this.isOnlinePay
                        ? <div className='px-4 py-3'>
                            <List dataSource={this.payModes} grid={{ column: 4, gutter: 24, xs: 1, sm: 2, md: 3, }}
                                renderItem={(item) => (<List.Item onClick={() => {
                                    this.openScanCodePayment(item, "paymentStatement");
                                }} className='cursor-pointer mb-0' >{this.reanderItem(item)} </List.Item>)} />
                            <div className='position-relative' >
                                <span id="paymentStatement" className="position-absolute">
                                    <Checkbox  onChange={this.onChange}> <small>已阅读并同意
                                        <a href='https://www.jkchemical.com/payment-statement'
                                            className='text-primary' target='_blank' rel="noreferrer" >代付款声明</a></small> </Checkbox>
                                </span>
                            </div>
                        </div>
                        : <></>
                    }
                </div>
            </div>;
        }));
    };
};