import axios from "axios";
import Axios from '../axiosUtils';
import { centerApi } from ".";
import { env } from "tonva-react";

export interface envState {
    testing: boolean;
};

export enum Hosts {
    test = "https://test.jkchemical.com",
    shop = "https://shop.jkchemical.com",
    join = "https://joint.jkchemical.com",
    web = "https://web.jkchemical.com",
};

export abstract class ApiEntity {
    protected env: envState = { testing: env.testing === true };
    protected get base() { return "" };
    protected get axios(): Axios {
        return new Axios({ axiosConfig: { baseURL: this.base } });
    };

    /**
     * url中的?a=1&b=1参数传递
     * 1.url中拼接
     * 2.使用params： xxx.get(url, {params:{}});
     */
    get(url: string, params: any = {}) {
        return this.axios.get(url, params);
    };

    post(url: string, data: any = {}) {
        return this.axios.post(url, data);
    };

    put(url: string, data: any = {}) {
        return this.axios.put(url, data);
    };

    delete(url: string, data: any = {}) {
        return this.axios.delete(url, data);
    };

    constructor() { };
}