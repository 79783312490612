/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "./App.css";
// import 'antd/dist/antd.css';
import * as serviceWorker from "./serviceWorker";
import { NavView, start, nav, startPage, env } from "tonva-react";
import { CApp, CWeb, appConfig } from "tapp";
import * as qs from "querystringify";
import { GLOABLE } from "global";
import { changeTDKByPathname } from "tools/tdk";
import { setCookieUqToken } from "tools/uqToken";
import { StyleProvider } from "@ant-design/cssinjs";

/*
let elRoot = document.getElementById('root');
if (!elRoot) {
    elRoot = document.createElement('div');
    elRoot.style.display = 'none';
    document.body.append(elRoot);
}
ReactDOM.render(<App />, elRoot);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
*/

//let { location } = document;
//if (location.host && false) {

(async function () {
    nav.setSettings(appConfig);
    loadBaiduTongji();
    changeTDKByPathname();
    let onLogined: () => Promise<void>;

    if (env.isMobile || window.location.pathname.endsWith("/app")) {
        require("style-loader!./css/base.css");
        require("style-loader!./css/rst.css");
        require("style-loader!./css/style.css");
        onLogined = async () => {
            await start(CApp, appConfig);
        };
    } else {
        require("style-loader!./css/base.css");
        require("style-loader!./css/style.css");
        require("style-loader!./css/antd-reset.css");
        loadTopIcon();
        loadQQScript();
        onLogined = async () => {
            await startPage(CWeb, appConfig);
        };
    }

    let userPassword = async () => {
        let { search } = window.location;
        if (search) {
            let query: any = qs.parse(search.toLowerCase());
            if (search.startsWith("?lgtk=")) {
                let result = await fetch(
                    GLOABLE.EPEC.AUTOLOGIN + "?lgtk=" + query?.lgtk
                );
                if (result.ok) {
                    let res = await result.json();
                    return res;
                }
            }
        }
        return; /* { user: 'xxxx', password: 'xxxxx' }; */
    };

    const App: React.FC = () => {
        return (
            <NavView
                onLogined={onLogined}
                notLogined={onLogined}
                userPassword={userPassword}
            />
        );
    };

    let elRoot = document.getElementById("root");
    if (!elRoot) {
        elRoot = document.createElement("div");
        elRoot.style.display = "none";
        document.body.appendChild(elRoot);
    }
    ReactDOM.render(
        <React.StrictMode>
            <StyleProvider hashPriority="high">
                <App />
            </StyleProvider>
        </React.StrictMode>,
        elRoot
    );
    // document.getElementById('root')
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
})();

function loadQQScript() {
    let f = document.createElement("script");
    f.setAttribute("id", "qd30090316631b8acd883f52dc36130350d738250f75");
    f.type = "text/javascript";
    f.src =
        "https://wp.qiye.qq.com/qidian/3009031663/1b8acd883f52dc36130350d738250f75";
    // f.src = 'https://wpa.b.qq.com/cgi/wpa.php?key=XzkzODAzNjk3OV80OTI2NjBfNDAwNjY2Nzc4OF8';
    document.body.appendChild(f);
}

function loadBaiduTongji() {
    var _hmt: any[] = _hmt || [];
    (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?581e8230afda2afbd8a5870fad068d1e";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
}

function loadTopIcon() {
    let i = document.createElement("i");
    let a = document.createElement("a");
    i.setAttribute("class", "bi-arrow-up-short");
    a.setAttribute("class", "back-to-top");
    a.onclick = () => {
        document.body.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };
    a.appendChild(i);
    let s = document.body.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(a, s);
}
