//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID, UqIDX, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/coupon ========
//===============================

export enum EnumCouponType {
	coupon = 1,
	credits = 2,
	vipCard = 3
}

export enum EnumBoundType {
	assign = 1,
	coupon = 2,
	developed = 3,
	newCustomer = 4
}

export enum EnumBoundStrength {
	strong = 1,
	weak = 2
}

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidBrand {
	id?: number;
	name: string;
}

export interface TuidCustomer {
	id?: number;
}

export interface TuidOrganization {
	id?: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface ParamIsCanUseCoupon {
	code: string;
	customer: number;
}
export interface ReturnIsCanUseCouponRet {
	result: number;
	id: number;
	code: string;
	type: any;
	validityDate: any;
	isValid: number;
	creator: number;
}
export interface ResultIsCanUseCoupon {
	ret: ReturnIsCanUseCouponRet[];
}

export interface ParamCreateCoupon {
	type: any;
	validityDate: any;
}
export interface ReturnCreateCouponRet {
	coupon: number;
	code: string;
}
export interface ResultCreateCoupon {
	ret: ReturnCreateCouponRet[];
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamDeleteCustomerTag {
	id: number;
}
export interface ResultDeleteCustomerTag {
}

export interface ParamAuditPendingSalesRole {
	id: number;
	employee: number;
	role: number;
	result: number;
	comments: string;
}
export interface ResultAuditPendingSalesRole {
}

export interface ParamApplyAuditSalesRole {
	webUser: number;
	role: number;
}
export interface ResultApplyAuditSalesRole {
}

export interface ParamSaveCustomerDeveloping {
	employee: number;
	customer: number;
}
export interface ReturnSaveCustomerDevelopingRet {
	result: number;
}
export interface ResultSaveCustomerDeveloping {
	ret: ReturnSaveCustomerDevelopingRet[];
}

export interface ParamSaveSalesWorkReport {
	customer: number;
	content: string;
	creator: number;
}
export interface ReturnSaveSalesWorkReportRet {
	id: number;
}
export interface ResultSaveSalesWorkReport {
	ret: ReturnSaveSalesWorkReportRet[];
}

export interface ParamAcceptCustomerBoundedTransmit {
	from: number;
	to: number;
	customer: number;
}
export interface ResultAcceptCustomerBoundedTransmit {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface ParamSearchCoupon {
	key: string;
	type: any;
	isUsed: number;
}
export interface ReturnSearchCoupon$page {
	id: number;
	code: string;
	type: any;
	validityDate: any;
	isValid: number;
	usedTimes: number;
	creator: number;
	createDate: any;
}
export interface ResultSearchCoupon {
	$page: ReturnSearchCoupon$page[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamGetCouponUsedHistory {
	coupon: number;
}
export interface ReturnGetCouponUsedHistoryRet {
	coupon: number;
	customer: number;
	usedDate: any;
	orderId: number;
}
export interface ResultGetCouponUsedHistory {
	ret: ReturnGetCouponUsedHistoryRet[];
}

export interface ParamSearchBottomDiscount {
}
export interface ReturnSearchBottomDiscountRet {
	brand: number;
	discount: number;
}
export interface ResultSearchBottomDiscount {
	ret: ReturnSearchBottomDiscountRet[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface ParamGetDayCustomerPool {
	pool: number;
	createDate: any;
	salesman: number;
}
export interface ReturnGetDayCustomerPool$page {
	id: number;
	ix: number;
	customer: number;
	organization: number;
	createDate: any;
}
export interface ResultGetDayCustomerPool {
	$page: ReturnGetDayCustomerPool$page[];
}

export interface ParamGetCustomerSubscribingById {
	salesman: number;
	customer: number;
}
export interface ReturnGetCustomerSubscribingByIdRet {
	ix: number;
	xi: number;
	customer: number;
	createDate: any;
	customerSubNotify: number;
}
export interface ResultGetCustomerSubscribingById {
	ret: ReturnGetCustomerSubscribingByIdRet[];
}

export interface ParamGetPoolCustomerById {
	pool: number;
	customer: number;
}
export interface ReturnGetPoolCustomerByIdRet {
	ix: number;
	xi: number;
	customer: number;
	createDate: any;
}
export interface ResultGetPoolCustomerById {
	ret: ReturnGetPoolCustomerByIdRet[];
}

export interface ParamGetCustomerDevelopingById {
	salesman: number;
	customer: number;
}
export interface ReturnGetCustomerDevelopingByIdRet {
	ix: number;
	xi: number;
	customer: number;
	createDate: any;
	expiredDate: any;
}
export interface ResultGetCustomerDevelopingById {
	ret: ReturnGetCustomerDevelopingByIdRet[];
}

export interface ParamGetCustomerSubscribingPager {
	salesman: number;
}
export interface ReturnGetCustomerSubscribingPager$page {
	id: number;
	ix: number;
	xi: number;
	customer: number;
	createDate: any;
}
export interface ResultGetCustomerSubscribingPager {
	$page: ReturnGetCustomerSubscribingPager$page[];
}

export interface ParamGetSaleCustomerBound {
	salesman: number;
	customer: number;
}
export interface ReturnGetSaleCustomerBoundRet {
	customer: number;
	salesman: number;
	boundDate: any;
	boundDays: number;
	boundType: any;
}
export interface ResultGetSaleCustomerBound {
	ret: ReturnGetSaleCustomerBoundRet[];
}

export interface ParamGetCustomerWebsiteLeads {
}
export interface ReturnGetCustomerWebsiteLeadsRet {
	id: number;
	salesman: number;
	lastVisited: any;
	timesVisited: number;
}
export interface ResultGetCustomerWebsiteLeads {
	ret: ReturnGetCustomerWebsiteLeadsRet[];
}

export interface ParamGetCustomerBoundByCustomerTag {
	customerTag: number;
	salesman: number;
	start: any;
	end: any;
}
export interface ReturnGetCustomerBoundByCustomerTag$page {
	id: number;
	customer: number;
	salesman: number;
	boundDate: any;
	boundDays: number;
	boundType: any;
}
export interface ResultGetCustomerBoundByCustomerTag {
	$page: ReturnGetCustomerBoundByCustomerTag$page[];
}

export interface ParamGetApplySalesRoleTask {
}
export interface ReturnGetApplySalesRoleTaskRet {
	webUser: number;
	role: number;
	employee: number;
	result: number;
	comments: string;
}
export interface ResultGetApplySalesRoleTask {
	ret: ReturnGetApplySalesRoleTaskRet[];
}

export interface ParamGetApplySalesRoles {
}
export interface ReturnGetApplySalesRolesRet {
	webUser: number;
	role: number;
	name: string;
}
export interface ResultGetApplySalesRoles {
	ret: ReturnGetApplySalesRolesRet[];
}

export interface ParamGetDxWebuserRoleByEmployee {
	employee: number;
}
export interface ReturnGetDxWebuserRoleByEmployeeRet {
	webUser: number;
	employee: number;
	role: number;
}
export interface ResultGetDxWebuserRoleByEmployee {
	ret: ReturnGetDxWebuserRoleByEmployeeRet[];
}

export interface ParamGetSalesRoleAuditHistory {
	state: number;
}
export interface ReturnGetSalesRoleAuditHistory$page {
	id: number;
	webUser: number;
	role: number;
	employee: number;
	result: number;
	comments: string;
	date: any;
}
export interface ResultGetSalesRoleAuditHistory {
	$page: ReturnGetSalesRoleAuditHistory$page[];
}

export interface ParamGetRandCustomerPool {
	times: number;
}
export interface ReturnGetRandCustomerPoolRet {
	pool: number;
	times: number;
	customer: number;
	organization: number;
	buziDepartment: number;
}
export interface ResultGetRandCustomerPool {
	ret: ReturnGetRandCustomerPoolRet[];
}

export interface ParamGetRandCustomerValue {
	webUser: number;
	searchDate: any;
}
export interface ReturnGetRandCustomerValueRet {
	ix: number;
	xi: any;
	value: number;
}
export interface ResultGetRandCustomerValue {
	ret: ReturnGetRandCustomerValueRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface ParamGetMyCustomerInPoolTotal {
	salesman: number;
	createDate: any;
}
export interface ReturnGetMyCustomerInPoolTotalRet {
	total: number;
}
export interface ResultGetMyCustomerInPoolTotal {
	ret: ReturnGetMyCustomerInPoolTotalRet[];
}

export interface ParamSearchMyCustomerInPoolByName {
	salesman: number;
	customerName: string;
}
export interface ReturnSearchMyCustomerInPoolByNameRet {
	id: number;
	ix: number;
	customer: number;
	organization: number;
	createDate: any;
}
export interface ResultSearchMyCustomerInPoolByName {
	ret: ReturnSearchMyCustomerInPoolByNameRet[];
}

export interface ParamGetMyCustomerInPool {
	salesman: number;
}
export interface ReturnGetMyCustomerInPool$page {
	id: number;
	ix: number;
	customer: number;
	organization: number;
	createDate: any;
}
export interface ResultGetMyCustomerInPool {
	$page: ReturnGetMyCustomerInPool$page[];
}

export interface ParamGetSaleRepurchaseRadio {
	salesman: number;
	startDate: any;
	endDate: any;
}
export interface ReturnGetSaleRepurchaseRadioRet {
	salesman: number;
	date: any;
	periodDays: number;
	repurchases: number;
	retentions: number;
	all: number;
}
export interface ResultGetSaleRepurchaseRadio {
	ret: ReturnGetSaleRepurchaseRadioRet[];
}

export interface ParamGetSalesCustomerBoundOrders {
	salesman: number;
	keyWord: string;
}
export interface ReturnGetSalesCustomerBoundOrders$page {
	id: number;
	customer: number;
	organization: number;
	salesman: number;
	boundDate: any;
	boundDays: number;
	boundType: number;
}
export interface ResultGetSalesCustomerBoundOrders {
	$page: ReturnGetSalesCustomerBoundOrders$page[];
}

export interface ParamGetSalesCustomerBoundOrderTotal {
	salesman: number;
}
export interface ReturnGetSalesCustomerBoundOrderTotalRet {
	salesman: number;
	total: number;
}
export interface ResultGetSalesCustomerBoundOrderTotal {
	ret: ReturnGetSalesCustomerBoundOrderTotalRet[];
}

export interface ParamGetSalesCustomerReport {
	customer: number;
}
export interface ReturnGetSalesCustomerReport$page {
	id: number;
	reportId: number;
	no: string;
	customer: number;
	status: number;
	content: string;
	creator: number;
	createDate: any;
}
export interface ResultGetSalesCustomerReport {
	$page: ReturnGetSalesCustomerReport$page[];
}

export interface ParamGetSalesCustomerTagAlsoTotal {
	salesman: number;
}
export interface ReturnGetSalesCustomerTagAlsoTotalRet {
	id: number;
	name: string;
	description: string;
	salesman: number;
	total: number;
}
export interface ResultGetSalesCustomerTagAlsoTotal {
	ret: ReturnGetSalesCustomerTagAlsoTotalRet[];
}

export interface ParamGetSalesCustomerBoundWillInPoolTotal {
	salesman: number;
}
export interface ReturnGetSalesCustomerBoundWillInPoolTotalRet {
	salesman: number;
	total: number;
}
export interface ResultGetSalesCustomerBoundWillInPoolTotal {
	ret: ReturnGetSalesCustomerBoundWillInPoolTotalRet[];
}

export interface ParamGetsalesCustomerDevelopingByTag {
	salesman: number;
	customerTag: number;
}
export interface ReturnGetsalesCustomerDevelopingByTagRet {
	ix: number;
	xi: number;
	createDate: any;
	expiredDate: any;
	customer: number;
	organization: number;
}
export interface ResultGetsalesCustomerDevelopingByTag {
	ret: ReturnGetsalesCustomerDevelopingByTagRet[];
}

export interface ParamGetMyCustomerInPoolByTag {
	salesman: number;
	customerTag: number;
}
export interface ReturnGetMyCustomerInPoolByTag$page {
	id: number;
	ix: number;
	customer: number;
	organization: number;
	createDate: any;
}
export interface ResultGetMyCustomerInPoolByTag {
	$page: ReturnGetMyCustomerInPoolByTag$page[];
}

export interface ParamGetMyCustomerDeveloped {
	salesman: number;
	result: number;
}
export interface ReturnGetMyCustomerDeveloped$page {
	id: number;
	sales: number;
	customer: number;
	startDate: any;
	result: number;
	comments: string;
}
export interface ResultGetMyCustomerDeveloped {
	$page: ReturnGetMyCustomerDeveloped$page[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface ParamGetSalesCustomerBoundVIPTotal {
	salesman: number;
}
export interface ReturnGetSalesCustomerBoundVIPTotalRet {
	salesman: number;
	total: number;
}
export interface ResultGetSalesCustomerBoundVIPTotal {
	ret: ReturnGetSalesCustomerBoundVIPTotalRet[];
}

export interface ParamGetSalesCustomerBoundDeepVIP {
	salesman: number;
	keyWord: string;
}
export interface ReturnGetSalesCustomerBoundDeepVIP$page {
	id: number;
	customer: number;
	organization: number;
	salesman: number;
	boundDate: any;
	boundDays: number;
	boundType: number;
}
export interface ResultGetSalesCustomerBoundDeepVIP {
	$page: ReturnGetSalesCustomerBoundDeepVIP$page[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface Coupon {
	id?: number;
	code: string;
	type: any;
	validityDate: any;
	isValid: number;
	creator: number;
	createDate: any;
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface CustomerBoundHistory {
	id?: number;
	customer: number;
	boundTo: number;
	boundType: any;
	operation: number;
}

export interface CustomerTag {
	id?: number;
	name: string;
	description: string;
}

export interface Pool {
	id?: number;
	name: string;
	description: string;
}

export interface CustomerDeveloped {
	id?: number;
	sales: number;
	customer: number;
	startDate: any;
	result: number;
	comments: string;
}

export interface SalesRole {
	id?: number;
	name: string;
	description: string;
}

export interface BuziDepartment {
	id?: number;
	name: string;
	description: string;
}

export interface RecommendCustomerPoolHistory {
	id?: number;
	webUser: number;
	date: any;
	times: number;
	customer: number;
	buziDepartment: number;
}

export interface SalesWorkReport {
	id?: number;
	no?: string;
	customer: number;
	status: number;
	creator: number;
	createDate: any;
}

export interface SysFeature {
	id?: number;
	content: string;
	createDate: any;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface IxPoolCustomerHistory {
	id?: number;
	pool: number;
	customer: number;
	inout: number;
}

export interface CustomerBound {
	id: number;
	salesman?: number;
	boundDate?: any;
	boundDays?: number;
	boundType?: any;
	$act?: number;
}

export interface CustomerWebsiteLeads {
	id: number;
	salesman?: number;
	lastVisited?: any;
	timesVisited?: number;
	$act?: number;
}

export interface CustomerWebsiteLeadsExclude {
	id: number;
	salesman?: number;
	startDate?: any;
	excludeDays?: number;
	$act?: number;
}

export interface DxWebUserRole {
	id: number;
	employee?: number;
	role?: number;
	$act?: number;
}

export interface SysFeatureRemind {
	id: number;
	begin?: any;
	end?: any;
	$act?: number;
}

export interface ActParamCustomerBound {
	id: number|IDXValue;
	salesman?: number|IDXValue;
	boundDate?: any|IDXValue;
	boundDays?: number|IDXValue;
	boundType?: any|IDXValue;
	$act?: number;
}

export interface ActParamCustomerWebsiteLeads {
	id: number|IDXValue;
	salesman?: number|IDXValue;
	lastVisited?: any|IDXValue;
	timesVisited?: number|IDXValue;
	$act?: number;
}

export interface ActParamCustomerWebsiteLeadsExclude {
	id: number|IDXValue;
	salesman?: number|IDXValue;
	startDate?: any|IDXValue;
	excludeDays?: number|IDXValue;
	$act?: number;
}

export interface ActParamDxWebUserRole {
	id: number|IDXValue;
	employee?: number|IDXValue;
	role?: number|IDXValue;
	$act?: number;
}

export interface ActParamSysFeatureRemind {
	id: number|IDXValue;
	begin?: any|IDXValue;
	end?: any|IDXValue;
	$act?: number;
}

export interface IxOrderIntervalSum {
	ix: number;
	xi: number;
	value: number;
}

export interface SalesmanCustomerTag {
	ix: number;
	xi: number;
}

export interface CustomerTagCustomer {
	ix: number;
	xi: number;
}

export interface IxPoolCustomer {
	ix: number;
	xi: number;
	createDate: any;
}

export interface IxCustomerSubscribing {
	ix: number;
	xi: number;
	createDate: any;
}

export interface IxCustomerSubscribingNotify {
	ix: number;
	xi: number;
	createDate: any;
}

export interface IxCustomerDeveloping {
	ix: number;
	xi: number;
	createDate: any;
	expiredDate: any;
}

export interface IxRandCustomerPoolTimes {
	ix: number;
	xi: any;
	value: number;
}

export interface IxSalesNoDisplayCustomer {
	ix: number;
	xi: number;
	comments: string;
}

export interface SalesmanRepurchaseRadio {
	ix: number;
	xi: any;
	periodDays: number;
	repurchases: number;
	retentions: number;
	all: number;
}

export interface SysFeatureRemindDisabled {
	ix: number;
	xi: number;
}

export interface ParamActs {
	coupon?: Coupon[];
	$User?: $User[];
	customerBoundHistory?: CustomerBoundHistory[];
	customerTag?: CustomerTag[];
	pool?: Pool[];
	customerDeveloped?: CustomerDeveloped[];
	salesRole?: SalesRole[];
	buziDepartment?: BuziDepartment[];
	recommendCustomerPoolHistory?: RecommendCustomerPoolHistory[];
	salesWorkReport?: SalesWorkReport[];
	sysFeature?: SysFeature[];
	$phrase?: $phrase[];
	$UserSite?: $UserSite[];
	$Site?: $Site[];
	ixPoolCustomerHistory?: IxPoolCustomerHistory[];
	customerBound?: ActParamCustomerBound[];
	customerWebsiteLeads?: ActParamCustomerWebsiteLeads[];
	customerWebsiteLeadsExclude?: ActParamCustomerWebsiteLeadsExclude[];
	dxWebUserRole?: ActParamDxWebUserRole[];
	sysFeatureRemind?: ActParamSysFeatureRemind[];
	ixOrderIntervalSum?: IxOrderIntervalSum[];
	salesmanCustomerTag?: SalesmanCustomerTag[];
	customerTagCustomer?: CustomerTagCustomer[];
	ixPoolCustomer?: IxPoolCustomer[];
	ixCustomerSubscribing?: IxCustomerSubscribing[];
	ixCustomerSubscribingNotify?: IxCustomerSubscribingNotify[];
	ixCustomerDeveloping?: IxCustomerDeveloping[];
	ixRandCustomerPoolTimes?: IxRandCustomerPoolTimes[];
	ixSalesNoDisplayCustomer?: IxSalesNoDisplayCustomer[];
	salesmanRepurchaseRadio?: SalesmanRepurchaseRadio[];
	sysFeatureRemindDisabled?: SysFeatureRemindDisabled[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	Brand: UqTuid<TuidBrand>;
	Customer: UqTuid<TuidCustomer>;
	Organization: UqTuid<TuidOrganization>;
	IsCanUseCoupon: UqAction<ParamIsCanUseCoupon, ResultIsCanUseCoupon>;
	CreateCoupon: UqAction<ParamCreateCoupon, ResultCreateCoupon>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	DeleteCustomerTag: UqAction<ParamDeleteCustomerTag, ResultDeleteCustomerTag>;
	AuditPendingSalesRole: UqAction<ParamAuditPendingSalesRole, ResultAuditPendingSalesRole>;
	ApplyAuditSalesRole: UqAction<ParamApplyAuditSalesRole, ResultApplyAuditSalesRole>;
	SaveCustomerDeveloping: UqAction<ParamSaveCustomerDeveloping, ResultSaveCustomerDeveloping>;
	SaveSalesWorkReport: UqAction<ParamSaveSalesWorkReport, ResultSaveSalesWorkReport>;
	AcceptCustomerBoundedTransmit: UqAction<ParamAcceptCustomerBoundedTransmit, ResultAcceptCustomerBoundedTransmit>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	SearchCoupon: UqQuery<ParamSearchCoupon, ResultSearchCoupon>;
	$poked: UqQuery<Param$poked, Result$poked>;
	GetCouponUsedHistory: UqQuery<ParamGetCouponUsedHistory, ResultGetCouponUsedHistory>;
	SearchBottomDiscount: UqQuery<ParamSearchBottomDiscount, ResultSearchBottomDiscount>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	GetDayCustomerPool: UqQuery<ParamGetDayCustomerPool, ResultGetDayCustomerPool>;
	GetCustomerSubscribingById: UqQuery<ParamGetCustomerSubscribingById, ResultGetCustomerSubscribingById>;
	GetPoolCustomerById: UqQuery<ParamGetPoolCustomerById, ResultGetPoolCustomerById>;
	GetCustomerDevelopingById: UqQuery<ParamGetCustomerDevelopingById, ResultGetCustomerDevelopingById>;
	GetCustomerSubscribingPager: UqQuery<ParamGetCustomerSubscribingPager, ResultGetCustomerSubscribingPager>;
	GetSaleCustomerBound: UqQuery<ParamGetSaleCustomerBound, ResultGetSaleCustomerBound>;
	GetCustomerWebsiteLeads: UqQuery<ParamGetCustomerWebsiteLeads, ResultGetCustomerWebsiteLeads>;
	GetCustomerBoundByCustomerTag: UqQuery<ParamGetCustomerBoundByCustomerTag, ResultGetCustomerBoundByCustomerTag>;
	GetApplySalesRoleTask: UqQuery<ParamGetApplySalesRoleTask, ResultGetApplySalesRoleTask>;
	GetApplySalesRoles: UqQuery<ParamGetApplySalesRoles, ResultGetApplySalesRoles>;
	GetDxWebuserRoleByEmployee: UqQuery<ParamGetDxWebuserRoleByEmployee, ResultGetDxWebuserRoleByEmployee>;
	GetSalesRoleAuditHistory: UqQuery<ParamGetSalesRoleAuditHistory, ResultGetSalesRoleAuditHistory>;
	GetRandCustomerPool: UqQuery<ParamGetRandCustomerPool, ResultGetRandCustomerPool>;
	GetRandCustomerValue: UqQuery<ParamGetRandCustomerValue, ResultGetRandCustomerValue>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	GetMyCustomerInPoolTotal: UqQuery<ParamGetMyCustomerInPoolTotal, ResultGetMyCustomerInPoolTotal>;
	SearchMyCustomerInPoolByName: UqQuery<ParamSearchMyCustomerInPoolByName, ResultSearchMyCustomerInPoolByName>;
	GetMyCustomerInPool: UqQuery<ParamGetMyCustomerInPool, ResultGetMyCustomerInPool>;
	GetSaleRepurchaseRadio: UqQuery<ParamGetSaleRepurchaseRadio, ResultGetSaleRepurchaseRadio>;
	GetSalesCustomerBoundOrders: UqQuery<ParamGetSalesCustomerBoundOrders, ResultGetSalesCustomerBoundOrders>;
	GetSalesCustomerBoundOrderTotal: UqQuery<ParamGetSalesCustomerBoundOrderTotal, ResultGetSalesCustomerBoundOrderTotal>;
	GetSalesCustomerReport: UqQuery<ParamGetSalesCustomerReport, ResultGetSalesCustomerReport>;
	GetSalesCustomerTagAlsoTotal: UqQuery<ParamGetSalesCustomerTagAlsoTotal, ResultGetSalesCustomerTagAlsoTotal>;
	GetSalesCustomerBoundWillInPoolTotal: UqQuery<ParamGetSalesCustomerBoundWillInPoolTotal, ResultGetSalesCustomerBoundWillInPoolTotal>;
	GetsalesCustomerDevelopingByTag: UqQuery<ParamGetsalesCustomerDevelopingByTag, ResultGetsalesCustomerDevelopingByTag>;
	GetMyCustomerInPoolByTag: UqQuery<ParamGetMyCustomerInPoolByTag, ResultGetMyCustomerInPoolByTag>;
	GetMyCustomerDeveloped: UqQuery<ParamGetMyCustomerDeveloped, ResultGetMyCustomerDeveloped>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	GetSalesCustomerBoundVIPTotal: UqQuery<ParamGetSalesCustomerBoundVIPTotal, ResultGetSalesCustomerBoundVIPTotal>;
	GetSalesCustomerBoundDeepVIP: UqQuery<ParamGetSalesCustomerBoundDeepVIP, ResultGetSalesCustomerBoundDeepVIP>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	BottomDiscount: UqMap;
	VIPCardDiscount: UqMap;
	BrandSalesRegion: UqMap;
	Coupon: UqID<any>;
	$User: UqID<any>;
	CustomerBoundHistory: UqID<any>;
	CustomerTag: UqID<any>;
	Pool: UqID<any>;
	CustomerDeveloped: UqID<any>;
	SalesRole: UqID<any>;
	BuziDepartment: UqID<any>;
	RecommendCustomerPoolHistory: UqID<any>;
	SalesWorkReport: UqID<any>;
	SysFeature: UqID<any>;
	$phrase: UqID<any>;
	$UserSite: UqID<any>;
	$Site: UqID<any>;
	IxPoolCustomerHistory: UqID<any>;
	CustomerBound: UqIDX<any>;
	CustomerWebsiteLeads: UqIDX<any>;
	CustomerWebsiteLeadsExclude: UqIDX<any>;
	DxWebUserRole: UqIDX<any>;
	SysFeatureRemind: UqIDX<any>;
	IxOrderIntervalSum: UqIX<any>;
	SalesmanCustomerTag: UqIX<any>;
	CustomerTagCustomer: UqIX<any>;
	IxPoolCustomer: UqIX<any>;
	IxCustomerSubscribing: UqIX<any>;
	IxCustomerSubscribingNotify: UqIX<any>;
	IxCustomerDeveloping: UqIX<any>;
	IxRandCustomerPoolTimes: UqIX<any>;
	IxSalesNoDisplayCustomer: UqIX<any>;
	SalesmanRepurchaseRadio: UqIX<any>;
	SysFeatureRemindDisabled: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
