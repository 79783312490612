//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqID, UqIDX, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/deliver ========
//===============================

export enum EnumRole {
	all = 1,
	dev = 2,
	warehouseManager = 3,
	cutOffer = 4,
	tallyer = 5,
	checker = 6,
	picker = 7,
	packer = 8,
	dispatcher = 9
}

export enum EnumRoleOp {
	test = 1
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidCarrier {
	id?: number;
	name: string;
	no: string;
	isValid: number;
}

export interface TuidBrand {
	id?: number;
	name: string;
	no: string;
}

export interface TuidProductX {
	id?: number;
	brand: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface ParamCutOff {
	aWarehouse: number;
	cutOffType: number;
}
export interface ReturnCutOffMain {
	id: number;
	no: string;
}
export interface ResultCutOff {
	main: ReturnCutOffMain[];
}

export interface ParamDelivered {
	deliver: number;
}
export interface ResultDelivered {
}

export interface ParamTallyDoneSingle {
	deliverMain: number;
	deliverDetail: number;
	quantity: number;
}
export interface ResultTallyDoneSingle {
}

export interface ParamTallyDone {
	cutOffMain: number;
}
export interface ResultTallyDone {
}

export interface ParamUpdateDeliverCarrier {
	deliverMain: number;
	carrier: number;
}
export interface ResultUpdateDeliverCarrier {
}

export interface ParamUpdateWaybillNumber {
	deliverMain: number;
	carrier: number;
	waybillNumber: string;
}
export interface ResultUpdateWaybillNumber {
}

export interface ParamCutOffSelected {
	aWarehouse: number;
	cutOffType: number;
	detail: {
		aRequestDetail: number;
	}[];

}
export interface ReturnCutOffSelectedMain {
	id: number;
	no: string;
}
export interface ResultCutOffSelected {
	main: ReturnCutOffSelectedMain[];
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamTruckArrange {
	aWarehouse: number;
	detail: {
		aBiz: number;
	}[];

}
export interface ResultTruckArrange {
}

export interface ParamTrucked {
	truckMain: number;
	detail: {
		biz: number;
	}[];

}
export interface ResultTrucked {
}

export interface ParamAddTruckAdditionalTask {
	warehouse: number;
	contact: number;
	content: string;
	note: string;
	creator: number;
	requiredDate: any;
	json: string;
}
export interface ResultAddTruckAdditionalTask {
}

export interface ParamAddTruck {
	warehouse: number;
	biz: number;
	contact: number;
	json: string;
}
export interface ResultAddTruck {
}

export interface ParamTallyCancelSingle {
	deliverMain: number;
	deliverDetail: number;
}
export interface ResultTallyCancelSingle {
}

export interface ParamCancelTruckAdditionalTask {
	warehouse: number;
	truckAdditionalTask: number;
}
export interface ResultCancelTruckAdditionalTask {
}

export interface ParamCheckDone {
	cutOffMain: number;
	allQuantity: number;
	doneQuantity: number;
}
export interface ResultCheckDone {
}

export interface ParamGetCutOffMainNo {
	warehouseNo: string;
}
export interface ReturnGetCutOffMainNoRet {
	cutOffMainNo: string;
}
export interface ResultGetCutOffMainNo {
	ret: ReturnGetCutOffMainNoRet[];
}

export interface Param$role_Unit_Add_Admin {
	unit: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Unit_Add_Admin {
}

export interface Param$role_Unit_Del_Admin {
	unit: number;
	user: number;
	admin: number;
}
export interface Result$role_Unit_Del_Admin {
}

export interface Param$role_Unit_Add_User {
	unit: number;
	user: number;
	assigned: string;
}
export interface Result$role_Unit_Add_User {
}

export interface Param$role_Unit_User_Role {
	unit: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Unit_User_Role {
}

export interface Param$role_Unit_Quit_Owner {
	unit: number;
}
export interface Result$role_Unit_Quit_Owner {
}

export interface ParamApplyAuditDeliverRole {
	webUser: number;
	warehouse: number;
}
export interface ResultApplyAuditDeliverRole {
}

export interface ParamAuditPendingDeliverRole {
	id: number;
	employee: number;
	employeeName: string;
	employeeId: string;
	warehouse: number;
	result: number;
	comments: string;
	roles: {
		role: number;
		roleResult: number;
	}[];

}
export interface ResultAuditPendingDeliverRole {
}

export interface ParamCheckCheckQuantity {
	cutOffMain: number;
	allQuantity: number;
	doneQuantity: number;
}
export interface ReturnCheckCheckQuantityRet {
	ok: number;
}
export interface ResultCheckCheckQuantity {
	ret: ReturnCheckCheckQuantityRet[];
}

export interface ParamTallyIsAllDone {
	cutoffmain: number;
}
export interface ReturnTallyIsAllDoneRet {
	ok: number;
}
export interface ResultTallyIsAllDone {
	ret: ReturnTallyIsAllDoneRet[];
}

export interface ParamPackCancelSingle {
	deliverMain: number;
	deliverDetail: number;
}
export interface ResultPackCancelSingle {
}

export interface ParamPackIsAllDone {
	deliverMain: number;
}
export interface ReturnPackIsAllDoneRet {
	ok: number;
}
export interface ResultPackIsAllDone {
	ret: ReturnPackIsAllDoneRet[];
}

export interface ParamPackDoneSingle {
	deliverMain: number;
	deliverDetail: number;
	quantity: number;
}
export interface ResultPackDoneSingle {
}

export interface ParamSplitDeliverMain {
	deliverMain: number;
	detail: {
		deliverDetail: number;
		splitQuantity: number;
	}[];

}
export interface ReturnSplitDeliverMainRet {
	deliverMainNewId: number;
}
export interface ResultSplitDeliverMain {
	ret: ReturnSplitDeliverMainRet[];
}

export interface ParamSetDefaultWarehouse {
	webUser: number;
	warehouse: number;
}
export interface ResultSetDefaultWarehouse {
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamGetDeliver {
	deliver: number;
}
export interface ReturnGetDeliverMain {
	id: number;
	no: string;
	customerAccount: number;
	contact: number;
	warehouse: number;
	staff: number;
	rows: number;
	pickRows: number;
	deliverTime: any;
}
export interface ReturnGetDeliverDetail {
	id: number;
	orderDetail: number;
	deliverMain: number;
	item: number;
	lotNumber: string;
	deliverShould: number;
	deliverDone: number;
	packState: number;
	packer: number;
	returnDone: number;
	beforeSplit: number;
	insuredType: number;
	content: string;
}
export interface ResultGetDeliver {
	main: ReturnGetDeliverMain[];
	detail: ReturnGetDeliverDetail[];
}

export interface ParamGetReadyCutOffList {
	warehouseId: number;
	cutofftypeId: number;
}
export interface ReturnGetReadyCutOffList$page {
	id: number;
	orderDetail: number;
	item: number;
	shouldQuantity: number;
	contact: number;
	json: string;
	mark: string;
	create: any;
}
export interface ResultGetReadyCutOffList {
	$page: ReturnGetReadyCutOffList$page[];
}

export interface ParamGetCutOffMainList {
	warehouse: number;
}
export interface ReturnGetCutOffMainList$page {
	id: number;
	no: string;
	cutter: number;
	create: any;
	sumQuantity: number;
	deliverDone: number;
}
export interface ResultGetCutOffMainList {
	$page: ReturnGetCutOffMainList$page[];
}

export interface ParamGetCutOffMain {
	cutOffMain: number;
}
export interface ReturnGetCutOffMainMain {
	id: number;
	no: string;
	warehouse: number;
	cutter: number;
}
export interface ReturnGetCutOffMainDetail {
	deliverMain: number;
	deliverMainNo: string;
	trayNumber: number;
	contact: number;
	customerAccount: number;
	carrier: number;
	waybillNumber: string;
	deliverTime: any;
	deliverDetail: number;
	item: number;
	quantity: number;
	lotNumber: string;
	showPrice: number;
	insuredType: number;
	content: string;
}
export interface ResultGetCutOffMain {
	main: ReturnGetCutOffMainMain[];
	detail: ReturnGetCutOffMainDetail[];
}

export interface ParamGetOrderDetailTransportation {
	orderDetail: number;
}
export interface ReturnGetOrderDetailTransportationRet {
	orderDetail: number;
	carrier: number;
	waybillNumber: string;
	deliverTime: any;
}
export interface ResultGetOrderDetailTransportation {
	ret: ReturnGetOrderDetailTransportationRet[];
}

export interface ParamGetPointExchangeDetailTransportation {
	pointExchangeDetail: number;
}
export interface ReturnGetPointExchangeDetailTransportationRet {
	pointExchangeDetail: number;
	carrier: number;
	waybillNumber: string;
	deliverTime: any;
}
export interface ResultGetPointExchangeDetailTransportation {
	ret: ReturnGetPointExchangeDetailTransportationRet[];
}

export interface ParamGetCutOffTypeCount {
	warehouseId: number;
	cutofftypeId: number;
}
export interface ReturnGetCutOffTypeCountRet {
	warehouse: number;
	cutofftype: number;
	taskCount: number;
	quantitySum: number;
}
export interface ResultGetCutOffTypeCount {
	ret: ReturnGetCutOffTypeCountRet[];
}

export interface ParamGetCutOffTypeDefinition {
	cutOffType: number;
}
export interface ReturnGetCutOffTypeDefinitionList {
	deliverType: number;
	deliverTypeName: string;
	customerAccount: number;
}
export interface ResultGetCutOffTypeDefinition {
	list: ReturnGetCutOffTypeDefinitionList[];
}

export interface ParamGetInsuredTypeBrand {
	insuredType: number;
}
export interface ReturnGetInsuredTypeBrandList {
	insuredType: number;
	brand: number;
}
export interface ResultGetInsuredTypeBrand {
	list: ReturnGetInsuredTypeBrandList[];
}

export interface ParamGetInsuredTypeInterval {
	insuredType: number;
}
export interface ReturnGetInsuredTypeIntervalList {
	insuredType: number;
	insuredInterval: number;
	amount: number;
}
export interface ResultGetInsuredTypeInterval {
	list: ReturnGetInsuredTypeIntervalList[];
}

export interface ParamGetDeliverDetailTransportation {
	deliverDetail: number;
}
export interface ReturnGetDeliverDetailTransportationRet {
	deliverDetail: number;
	carrier: number;
	waybillNumber: string;
	deliverTime: any;
}
export interface ResultGetDeliverDetailTransportation {
	ret: ReturnGetDeliverDetailTransportationRet[];
}

export interface ParamGetReadyTruckTaslList {
	warehouse: number;
}
export interface ReturnGetReadyTruckTaslListRet {
	warehouse: number;
	deliverDetail: number;
	contact: number;
	json: string;
}
export interface ResultGetReadyTruckTaslList {
	ret: ReturnGetReadyTruckTaslListRet[];
}

export interface ParamGetTruckMain {
	truckMain: number;
}
export interface ReturnGetTruckMainMain {
	truckMain: number;
	no: string;
	contact: number;
	warehouse: number;
	staff: number;
	trucking: number;
}
export interface ReturnGetTruckMainDetail {
	truckDetail: number;
	biz: number;
	json: string;
}
export interface ResultGetTruckMain {
	main: ReturnGetTruckMainMain[];
	detail: ReturnGetTruckMainDetail[];
}

export interface ParamGetTruckHistoryList {
}
export interface ReturnGetTruckHistoryListRet {
	warehouse: number;
	truckMain: number;
	no: string;
	contact: number;
	staff: number;
}
export interface ResultGetTruckHistoryList {
	ret: ReturnGetTruckHistoryListRet[];
}

export interface ParamGetTruckAdditionalTask {
}
export interface ReturnGetTruckAdditionalTaskRet {
	id: number;
	contact: number;
	content: string;
	note: string;
	creator: number;
	requiredDate: any;
	staff: number;
	state: number;
	finishTime: any;
	create: any;
}
export interface ResultGetTruckAdditionalTask {
	ret: ReturnGetTruckAdditionalTaskRet[];
}

export interface ParamGetRoleOps {
}
export interface ReturnGetRoleOpsRet {
	role: any;
	op: any;
}
export interface ResultGetRoleOps {
	ret: ReturnGetRoleOpsRet[];
}

export interface ParamGetCutOffHistory {
	warehouse: number;
}
export interface ReturnGetCutOffHistory$page {
	id: number;
	cutOffMain: number;
	no: string;
	finishTime: any;
	staff: number;
}
export interface ResultGetCutOffHistory {
	$page: ReturnGetCutOffHistory$page[];
}

export interface ParamGetDeliverHistory {
	warehouse: number;
}
export interface ReturnGetDeliverHistory$page {
	id: number;
	deliverMain: number;
	no: string;
	cutOffMain: string;
	traynumber: number;
	finishTime: any;
	staff: number;
}
export interface ResultGetDeliverHistory {
	$page: ReturnGetDeliverHistory$page[];
}

export interface ParamGetReadyTruckCount {
}
export interface ReturnGetReadyTruckCountRet {
	warehouse: number;
	readyTruckCount: number;
}
export interface ResultGetReadyTruckCount {
	ret: ReturnGetReadyTruckCountRet[];
}

export interface ParamWarehouseChecks {
	warehouse: number;
}
export interface ReturnWarehouseChecksRet {
	warehouse: number;
	cutOffMain: number;
	no: string;
	staff: number;
	create: any;
}
export interface ResultWarehouseChecks {
	ret: ReturnWarehouseChecksRet[];
}

export interface ParamWarehouseTallys {
	warehouse: number;
}
export interface ReturnWarehouseTallysRet {
	warehouse: number;
	cutOffMain: number;
	no: string;
	create: any;
	staff: number;
}
export interface ResultWarehouseTallys {
	ret: ReturnWarehouseTallysRet[];
}

export interface ParamWarehouseTrucks {
}
export interface ReturnWarehouseTrucksRet {
	warehouse: number;
	truckMain: number;
	no: string;
	contact: number;
	staff: number;
}
export interface ResultWarehouseTrucks {
	ret: ReturnWarehouseTrucksRet[];
}

export interface ParamWarehouseDelivers {
	warehouse: number;
}
export interface ReturnWarehouseDeliversRet {
	warehouse: number;
	deliverMain: number;
	no: string;
	cutOffMain: string;
	traynumber: number;
	customerAccount: number;
	staff: number;
}
export interface ResultWarehouseDelivers {
	ret: ReturnWarehouseDeliversRet[];
}

export interface ParamGetCheckHistory {
	warehouse: number;
}
export interface ReturnGetCheckHistory$page {
	id: number;
	cutOffMain: number;
	no: string;
	finishTime: any;
	staff: number;
	checker: number;
	checkDone: number;
}
export interface ResultGetCheckHistory {
	$page: ReturnGetCheckHistory$page[];
}

export interface ParamGetCheckInfo {
	cutOffMain: number;
}
export interface ReturnGetCheckInfoMain {
	id: number;
	no: string;
	warehouse: number;
	staff: number;
	finishTime: any;
	checker: number;
	checkDone: number;
}
export interface ReturnGetCheckInfoDetail {
	deliverMain: number;
	trayNumber: number;
	deliverDetail: number;
	item: number;
	checkShould: number;
	lotNumber: string;
	content: string;
}
export interface ResultGetCheckInfo {
	main: ReturnGetCheckInfoMain[];
	detail: ReturnGetCheckInfoDetail[];
}

export interface ParamGetOrganizationTruckStaff {
	staff: number;
}
export interface ReturnGetOrganizationTruckStaffRet {
	truckStaff: number;
	organization: number;
	truckBatchId: number;
	truckBatchName: string;
}
export interface ResultGetOrganizationTruckStaff {
	ret: ReturnGetOrganizationTruckStaffRet[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface Param$role_Unit_Users {
	unit: number;
}
export interface Return$role_Unit_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Unit_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Unit_Users {
	users: Return$role_Unit_UsersUsers[];
	roles: Return$role_Unit_UsersRoles[];
}

export interface Param$role_My {
}
export interface Return$role_MyAdmins {
	id: number;
	unit: number;
	admin: number;
	entity: string;
	assigned: string;
}
export interface Return$role_MyRoles {
	unit: number;
	role: string;
}
export interface Return$role_MyUnitProps {
	unit: number;
	props: string;
}
export interface Result$role_My {
	admins: Return$role_MyAdmins[];
	roles: Return$role_MyRoles[];
	unitProps: Return$role_MyUnitProps[];
}

export interface ParamGetUserWarehouse {
	warehouse: number;
	webUser: number;
}
export interface ReturnGetUserWarehouseRet {
	id: number;
	user: number;
	warehouse: number;
	isDefault: number;
}
export interface ResultGetUserWarehouse {
	ret: ReturnGetUserWarehouseRet[];
}

export interface ParamGetDeliverRoleAuditHistory {
	result: number;
}
export interface ReturnGetDeliverRoleAuditHistory$page {
	id: number;
	webUser: number;
	employee: number;
	warehouse: number;
	role: number;
	result: number;
	comments: string;
	date: any;
}
export interface ResultGetDeliverRoleAuditHistory {
	$page: ReturnGetDeliverRoleAuditHistory$page[];
}

export interface ParamGetUserApplyWarehouseRole {
	webUser: number;
}
export interface ReturnGetUserApplyWarehouseRoleRet {
	webUser: number;
	warehouse: number;
}
export interface ResultGetUserApplyWarehouseRole {
	ret: ReturnGetUserApplyWarehouseRoleRet[];
}

export interface ParamGetApplyWarehouse {
	warehouse: number;
}
export interface ReturnGetApplyWarehouseRet {
	webUser: number;
	warehouse: number;
}
export interface ResultGetApplyWarehouse {
	ret: ReturnGetApplyWarehouseRet[];
}

export interface ParamGetTallyInfo {
	cutOffMain: number;
}
export interface ReturnGetTallyInfoMain {
	id: number;
	no: string;
	warehouse: number;
	staff: number;
	finishTime: any;
}
export interface ReturnGetTallyInfoDetail {
	deliverMain: number;
	trayNumber: number;
	contact: number;
	customerAccount: number;
	deliverDetail: number;
	item: number;
	tallyShould: number;
	tallyDone: number;
	tallyState: number;
	tallyMan: number;
	lotNumber: string;
	content: string;
}
export interface ResultGetTallyInfo {
	main: ReturnGetTallyInfoMain[];
	detail: ReturnGetTallyInfoDetail[];
}

export interface ParamGetTallyStateInfo {
	deliverDetail: number;
}
export interface ReturnGetTallyStateInfoRet {
	deliverDetail: number;
	tallyDone: number;
	tallyState: number;
	tallyMan: number;
}
export interface ResultGetTallyStateInfo {
	ret: ReturnGetTallyStateInfoRet[];
}

export interface ParamGetPackStateInfo {
	deliverDetail: number;
}
export interface ReturnGetPackStateInfoRet {
	deliverDetail: number;
	deliverDone: number;
	packState: number;
	packer: number;
}
export interface ResultGetPackStateInfo {
	ret: ReturnGetPackStateInfoRet[];
}

export interface ParamGetWarehouseCarrier {
	warehouse: number;
}
export interface ReturnGetWarehouseCarrierRet {
	id: number;
	name: string;
}
export interface ResultGetWarehouseCarrier {
	ret: ReturnGetWarehouseCarrierRet[];
}

export interface ParamGetInsuredRules {
}
export interface ReturnGetInsuredRulesRules {
	insuredId: number;
	insuredName: string;
	start: number;
	end: number;
	amount: number;
}
export interface ResultGetInsuredRules {
	rules: ReturnGetInsuredRulesRules[];
}

export interface OrderMain {
	id?: number;
	no?: string;
	customerAccount: number;
	contact: number;
	currency: number;
}

export interface OrderDetail {
	id?: number;
	main?: number;
	item: number;
	product: number;
	quantity: number;
	amount: number;
	price: number;
	lotNumber: string;
}

export interface $user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
}

export interface Warehouse {
	id?: number;
	name: string;
	no?: string;
}

export interface DeliverMain {
	id?: number;
	no?: string;
	customerAccount: number;
	contact: number;
	warehouse: number;
	cutOffMain: number;
	trayNumber: number;
}

export interface DeliverDetail {
	id?: number;
	main?: number;
	biz: number;
	item: number;
	quantity: number;
	lotNumber: string;
	showPrice: number;
	json: string;
	insuredType: number;
}

export interface DeliverMainEx {
	id?: number;
	deliverId: string;
	warehouseName: string;
	addressString: string;
}

export interface CutOffMain {
	id?: number;
	no?: string;
	warehouse: number;
	cutter: number;
}

export interface CutOffType {
	id?: number;
	name: string;
	description: string;
}

export interface DeliverType {
	id?: number;
	name: string;
	description: string;
}

export interface RequestDetail {
	id?: number;
	main?: number;
	biz: number;
	cutOffType: number;
	warehouse: number;
	item: number;
	quantity: number;
	lotNumber: string;
	showPrice: number;
	inboundConfirm: number;
	mark: string;
	json: string;
}

export interface Request {
	id?: number;
	customerAccount: number;
	contact: number;
}

export interface InsuredInterval {
	id?: number;
	start: number;
	end: number;
}

export interface InsuredType {
	id?: number;
	name: string;
	description: string;
}

export interface TruckDetail {
	id?: number;
	main?: number;
	biz: number;
	json: string;
}

export interface TruckMain {
	id?: number;
	no?: string;
	contact: number;
	warehouse: number;
}

export interface TruckAdditionalTask {
	id?: number;
	contact: number;
	content: string;
	note: string;
	creator: number;
	requiredDate: any;
	staff: number;
	finishTime: any;
	state: number;
}

export interface Role {
	id?: number;
	name: string;
	discription: string;
}

export interface TruckBatch {
	id?: number;
	name: string;
	description: string;
}

export interface TruckStaff {
	id?: number;
	name: string;
	webuser: number;
}

export interface ExchangeDetail {
	id?: number;
	main?: number;
	item: number;
	quantity: number;
	point: number;
	subAmount: number;
}

export interface ExchangeMain {
	id?: number;
	no?: string;
	customer: number;
	shippingContact: number;
	amount: number;
	sheetId: number;
	createDate: any;
}

export interface OrderBaseMain {
	id?: number;
	no?: string;
}

export interface OrderBaseDetail {
	id?: number;
	main?: number;
	item: number;
	quantity: number;
}

export interface User {
	id?: number;
	name: string;
	employeeId: string;
}

export interface UserWarehouse {
	id?: number;
	user: number;
	warehouse: number;
	isDefault: number;
}

export interface PackingMain {
	id?: number;
	no?: string;
	customerAccount: number;
	contact: number;
	warehouse: number;
	cutOffMain: number;
	trayNumber: number;
}

export interface PackingDetail {
	id?: number;
	main?: number;
	biz: number;
	item: number;
	quantity: number;
	lotNumber: string;
	showPrice: number;
	json: string;
}

export interface $userunit {
	id?: number;
	unit: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
}

export interface DxDeliverMain {
	id: number;
	rows?: number;
	pickRows?: number;
	carrier?: number;
	waybillNumber?: string;
	deliverTime?: any;
	staff?: number;
	startTime?: any;
	finishTime?: any;
	$act?: number;
}

export interface DxDeliverDetail {
	id: number;
	pickDone?: number;
	tallyDone?: number;
	tallyState?: number;
	tallyMan?: number;
	deliverDone?: number;
	packState?: number;
	packer?: number;
	deliverReturn?: number;
	returnDone?: number;
	beforeSplit?: number;
	$act?: number;
}

export interface DxDelivering {
	id: number;
	$act?: number;
}

export interface IxPendingRequest {
	id: number;
	$act?: number;
}

export interface DxCutOffTypeBuyerAccount {
	id: number;
	name?: string;
	description?: string;
	$act?: number;
}

export interface DxTruckMain {
	id: number;
	staff?: number;
	startTime?: any;
	finishTime?: any;
	$act?: number;
}

export interface DxTrucking {
	id: number;
	$act?: number;
}

export interface DxPackingDetail {
	id: number;
	packingDone?: number;
	deliverMain?: number;
	$act?: number;
}

export interface DxPackingMain {
	id: number;
	rows?: number;
	packingRows?: number;
	staff?: number;
	startTime?: any;
	finishTime?: any;
	$act?: number;
}

export interface DxTallyMain {
	id: number;
	staff?: number;
	finishTime?: any;
	$act?: number;
}

export interface ActParamDxDeliverMain {
	id: number|IDXValue;
	rows?: number|IDXValue;
	pickRows?: number|IDXValue;
	carrier?: number|IDXValue;
	waybillNumber?: string|IDXValue;
	deliverTime?: any|IDXValue;
	staff?: number|IDXValue;
	startTime?: any|IDXValue;
	finishTime?: any|IDXValue;
	$act?: number;
}

export interface ActParamDxDeliverDetail {
	id: number|IDXValue;
	pickDone?: number|IDXValue;
	tallyDone?: number|IDXValue;
	tallyState?: number|IDXValue;
	tallyMan?: number|IDXValue;
	deliverDone?: number|IDXValue;
	packState?: number|IDXValue;
	packer?: number|IDXValue;
	deliverReturn?: number|IDXValue;
	returnDone?: number|IDXValue;
	beforeSplit?: number|IDXValue;
	$act?: number;
}

export interface ActParamDxDelivering {
	id: number|IDXValue;
	$act?: number;
}

export interface ActParamIxPendingRequest {
	id: number|IDXValue;
	$act?: number;
}

export interface ActParamDxCutOffTypeBuyerAccount {
	id: number|IDXValue;
	name?: string|IDXValue;
	description?: string|IDXValue;
	$act?: number;
}

export interface ActParamDxTruckMain {
	id: number|IDXValue;
	staff?: number|IDXValue;
	startTime?: any|IDXValue;
	finishTime?: any|IDXValue;
	$act?: number;
}

export interface ActParamDxTrucking {
	id: number|IDXValue;
	$act?: number;
}

export interface ActParamDxPackingDetail {
	id: number|IDXValue;
	packingDone?: number|IDXValue;
	deliverMain?: number|IDXValue;
	$act?: number;
}

export interface ActParamDxPackingMain {
	id: number|IDXValue;
	rows?: number|IDXValue;
	packingRows?: number|IDXValue;
	staff?: number|IDXValue;
	startTime?: any|IDXValue;
	finishTime?: any|IDXValue;
	$act?: number;
}

export interface ActParamDxTallyMain {
	id: number|IDXValue;
	staff?: number|IDXValue;
	finishTime?: any|IDXValue;
	$act?: number;
}

export interface IxUserWarehouse {
	ix: number;
	xi: number;
}

export interface IxCutoffTypeDefinition {
	ixx: number;
	ix: number;
	xi: number;
}

export interface DeliverDetailExchangeDetail {
	ix: number;
	xi: number;
}

export interface DeliverDetailOrderDetail {
	ix: number;
	xi: number;
}

export interface InsuredTypeBrand {
	ix: number;
	xi: number;
}

export interface InsuredTypeInterval {
	ix: number;
	xi: number;
	amount: number;
}

export interface IxPendingTruck {
	ix: number;
	xi: number;
	contact: number;
	json: string;
}

export interface UserRole {
	ix: number;
	xi: number;
}

export interface IxOrganizationTruckSetting {
	ixx: number;
	ix: number;
	xi: number;
}

export interface IxUserWarehouseRole {
	ix: number;
	xi: number;
}

export interface IxCarrierWarehouse {
	ix: number;
	xi: number;
	createDate: any;
}

export interface ParamActs {
	orderMain?: OrderMain[];
	orderDetail?: OrderDetail[];
	$user?: $user[];
	warehouse?: Warehouse[];
	deliverMain?: DeliverMain[];
	deliverDetail?: DeliverDetail[];
	deliverMainEx?: DeliverMainEx[];
	cutOffMain?: CutOffMain[];
	cutOffType?: CutOffType[];
	deliverType?: DeliverType[];
	requestDetail?: RequestDetail[];
	request?: Request[];
	insuredInterval?: InsuredInterval[];
	insuredType?: InsuredType[];
	truckDetail?: TruckDetail[];
	truckMain?: TruckMain[];
	truckAdditionalTask?: TruckAdditionalTask[];
	role?: Role[];
	truckBatch?: TruckBatch[];
	truckStaff?: TruckStaff[];
	exchangeDetail?: ExchangeDetail[];
	exchangeMain?: ExchangeMain[];
	orderBaseMain?: OrderBaseMain[];
	orderBaseDetail?: OrderBaseDetail[];
	user?: User[];
	userWarehouse?: UserWarehouse[];
	packingMain?: PackingMain[];
	packingDetail?: PackingDetail[];
	$userunit?: $userunit[];
	$phrase?: $phrase[];
	dxDeliverMain?: ActParamDxDeliverMain[];
	dxDeliverDetail?: ActParamDxDeliverDetail[];
	dxDelivering?: ActParamDxDelivering[];
	ixPendingRequest?: ActParamIxPendingRequest[];
	dxCutOffTypeBuyerAccount?: ActParamDxCutOffTypeBuyerAccount[];
	dxTruckMain?: ActParamDxTruckMain[];
	dxTrucking?: ActParamDxTrucking[];
	dxPackingDetail?: ActParamDxPackingDetail[];
	dxPackingMain?: ActParamDxPackingMain[];
	dxTallyMain?: ActParamDxTallyMain[];
	ixUserWarehouse?: IxUserWarehouse[];
	ixCutoffTypeDefinition?: IxCutoffTypeDefinition[];
	deliverDetailExchangeDetail?: DeliverDetailExchangeDetail[];
	deliverDetailOrderDetail?: DeliverDetailOrderDetail[];
	insuredTypeBrand?: InsuredTypeBrand[];
	insuredTypeInterval?: InsuredTypeInterval[];
	ixPendingTruck?: IxPendingTruck[];
	userRole?: UserRole[];
	ixOrganizationTruckSetting?: IxOrganizationTruckSetting[];
	ixUserWarehouseRole?: IxUserWarehouseRole[];
	ixCarrierWarehouse?: IxCarrierWarehouse[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	Carrier: UqTuid<TuidCarrier>;
	Brand: UqTuid<TuidBrand>;
	ProductX: UqTuid<TuidProductX>;
	CutOff: UqAction<ParamCutOff, ResultCutOff>;
	Delivered: UqAction<ParamDelivered, ResultDelivered>;
	TallyDoneSingle: UqAction<ParamTallyDoneSingle, ResultTallyDoneSingle>;
	TallyDone: UqAction<ParamTallyDone, ResultTallyDone>;
	UpdateDeliverCarrier: UqAction<ParamUpdateDeliverCarrier, ResultUpdateDeliverCarrier>;
	UpdateWaybillNumber: UqAction<ParamUpdateWaybillNumber, ResultUpdateWaybillNumber>;
	CutOffSelected: UqAction<ParamCutOffSelected, ResultCutOffSelected>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	TruckArrange: UqAction<ParamTruckArrange, ResultTruckArrange>;
	Trucked: UqAction<ParamTrucked, ResultTrucked>;
	AddTruckAdditionalTask: UqAction<ParamAddTruckAdditionalTask, ResultAddTruckAdditionalTask>;
	AddTruck: UqAction<ParamAddTruck, ResultAddTruck>;
	TallyCancelSingle: UqAction<ParamTallyCancelSingle, ResultTallyCancelSingle>;
	CancelTruckAdditionalTask: UqAction<ParamCancelTruckAdditionalTask, ResultCancelTruckAdditionalTask>;
	CheckDone: UqAction<ParamCheckDone, ResultCheckDone>;
	GetCutOffMainNo: UqAction<ParamGetCutOffMainNo, ResultGetCutOffMainNo>;
	$role_Unit_Add_Admin: UqAction<Param$role_Unit_Add_Admin, Result$role_Unit_Add_Admin>;
	$role_Unit_Del_Admin: UqAction<Param$role_Unit_Del_Admin, Result$role_Unit_Del_Admin>;
	$role_Unit_Add_User: UqAction<Param$role_Unit_Add_User, Result$role_Unit_Add_User>;
	$role_Unit_User_Role: UqAction<Param$role_Unit_User_Role, Result$role_Unit_User_Role>;
	$role_Unit_Quit_Owner: UqAction<Param$role_Unit_Quit_Owner, Result$role_Unit_Quit_Owner>;
	ApplyAuditDeliverRole: UqAction<ParamApplyAuditDeliverRole, ResultApplyAuditDeliverRole>;
	AuditPendingDeliverRole: UqAction<ParamAuditPendingDeliverRole, ResultAuditPendingDeliverRole>;
	CheckCheckQuantity: UqAction<ParamCheckCheckQuantity, ResultCheckCheckQuantity>;
	TallyIsAllDone: UqAction<ParamTallyIsAllDone, ResultTallyIsAllDone>;
	PackCancelSingle: UqAction<ParamPackCancelSingle, ResultPackCancelSingle>;
	PackIsAllDone: UqAction<ParamPackIsAllDone, ResultPackIsAllDone>;
	PackDoneSingle: UqAction<ParamPackDoneSingle, ResultPackDoneSingle>;
	SplitDeliverMain: UqAction<ParamSplitDeliverMain, ResultSplitDeliverMain>;
	SetDefaultWarehouse: UqAction<ParamSetDefaultWarehouse, ResultSetDefaultWarehouse>;
	$poked: UqQuery<Param$poked, Result$poked>;
	GetDeliver: UqQuery<ParamGetDeliver, ResultGetDeliver>;
	GetReadyCutOffList: UqQuery<ParamGetReadyCutOffList, ResultGetReadyCutOffList>;
	GetCutOffMainList: UqQuery<ParamGetCutOffMainList, ResultGetCutOffMainList>;
	GetCutOffMain: UqQuery<ParamGetCutOffMain, ResultGetCutOffMain>;
	GetOrderDetailTransportation: UqQuery<ParamGetOrderDetailTransportation, ResultGetOrderDetailTransportation>;
	GetPointExchangeDetailTransportation: UqQuery<ParamGetPointExchangeDetailTransportation, ResultGetPointExchangeDetailTransportation>;
	GetCutOffTypeCount: UqQuery<ParamGetCutOffTypeCount, ResultGetCutOffTypeCount>;
	GetCutOffTypeDefinition: UqQuery<ParamGetCutOffTypeDefinition, ResultGetCutOffTypeDefinition>;
	GetInsuredTypeBrand: UqQuery<ParamGetInsuredTypeBrand, ResultGetInsuredTypeBrand>;
	GetInsuredTypeInterval: UqQuery<ParamGetInsuredTypeInterval, ResultGetInsuredTypeInterval>;
	GetDeliverDetailTransportation: UqQuery<ParamGetDeliverDetailTransportation, ResultGetDeliverDetailTransportation>;
	GetReadyTruckTaslList: UqQuery<ParamGetReadyTruckTaslList, ResultGetReadyTruckTaslList>;
	GetTruckMain: UqQuery<ParamGetTruckMain, ResultGetTruckMain>;
	GetTruckHistoryList: UqQuery<ParamGetTruckHistoryList, ResultGetTruckHistoryList>;
	GetTruckAdditionalTask: UqQuery<ParamGetTruckAdditionalTask, ResultGetTruckAdditionalTask>;
	GetRoleOps: UqQuery<ParamGetRoleOps, ResultGetRoleOps>;
	GetCutOffHistory: UqQuery<ParamGetCutOffHistory, ResultGetCutOffHistory>;
	GetDeliverHistory: UqQuery<ParamGetDeliverHistory, ResultGetDeliverHistory>;
	GetReadyTruckCount: UqQuery<ParamGetReadyTruckCount, ResultGetReadyTruckCount>;
	WarehouseChecks: UqQuery<ParamWarehouseChecks, ResultWarehouseChecks>;
	WarehouseTallys: UqQuery<ParamWarehouseTallys, ResultWarehouseTallys>;
	WarehouseTrucks: UqQuery<ParamWarehouseTrucks, ResultWarehouseTrucks>;
	WarehouseDelivers: UqQuery<ParamWarehouseDelivers, ResultWarehouseDelivers>;
	GetCheckHistory: UqQuery<ParamGetCheckHistory, ResultGetCheckHistory>;
	GetCheckInfo: UqQuery<ParamGetCheckInfo, ResultGetCheckInfo>;
	GetOrganizationTruckStaff: UqQuery<ParamGetOrganizationTruckStaff, ResultGetOrganizationTruckStaff>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	$role_Unit_Users: UqQuery<Param$role_Unit_Users, Result$role_Unit_Users>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	GetUserWarehouse: UqQuery<ParamGetUserWarehouse, ResultGetUserWarehouse>;
	GetDeliverRoleAuditHistory: UqQuery<ParamGetDeliverRoleAuditHistory, ResultGetDeliverRoleAuditHistory>;
	GetUserApplyWarehouseRole: UqQuery<ParamGetUserApplyWarehouseRole, ResultGetUserApplyWarehouseRole>;
	GetApplyWarehouse: UqQuery<ParamGetApplyWarehouse, ResultGetApplyWarehouse>;
	GetTallyInfo: UqQuery<ParamGetTallyInfo, ResultGetTallyInfo>;
	GetTallyStateInfo: UqQuery<ParamGetTallyStateInfo, ResultGetTallyStateInfo>;
	GetPackStateInfo: UqQuery<ParamGetPackStateInfo, ResultGetPackStateInfo>;
	GetWarehouseCarrier: UqQuery<ParamGetWarehouseCarrier, ResultGetWarehouseCarrier>;
	GetInsuredRules: UqQuery<ParamGetInsuredRules, ResultGetInsuredRules>;
	OrderMain: UqID<any>;
	OrderDetail: UqID<any>;
	$user: UqID<any>;
	Warehouse: UqID<any>;
	DeliverMain: UqID<any>;
	DeliverDetail: UqID<any>;
	DeliverMainEx: UqID<any>;
	CutOffMain: UqID<any>;
	CutOffType: UqID<any>;
	DeliverType: UqID<any>;
	RequestDetail: UqID<any>;
	Request: UqID<any>;
	InsuredInterval: UqID<any>;
	InsuredType: UqID<any>;
	TruckDetail: UqID<any>;
	TruckMain: UqID<any>;
	TruckAdditionalTask: UqID<any>;
	Role: UqID<any>;
	TruckBatch: UqID<any>;
	TruckStaff: UqID<any>;
	ExchangeDetail: UqID<any>;
	ExchangeMain: UqID<any>;
	OrderBaseMain: UqID<any>;
	OrderBaseDetail: UqID<any>;
	User: UqID<any>;
	UserWarehouse: UqID<any>;
	PackingMain: UqID<any>;
	PackingDetail: UqID<any>;
	$userunit: UqID<any>;
	$phrase: UqID<any>;
	DxDeliverMain: UqIDX<any>;
	DxDeliverDetail: UqIDX<any>;
	DxDelivering: UqIDX<any>;
	IxPendingRequest: UqIDX<any>;
	DxCutOffTypeBuyerAccount: UqIDX<any>;
	DxTruckMain: UqIDX<any>;
	DxTrucking: UqIDX<any>;
	DxPackingDetail: UqIDX<any>;
	DxPackingMain: UqIDX<any>;
	DxTallyMain: UqIDX<any>;
	IxUserWarehouse: UqIX<any>;
	IxCutoffTypeDefinition: UqIX<any>;
	DeliverDetailExchangeDetail: UqIX<any>;
	DeliverDetailOrderDetail: UqIX<any>;
	InsuredTypeBrand: UqIX<any>;
	InsuredTypeInterval: UqIX<any>;
	IxPendingTruck: UqIX<any>;
	UserRole: UqIX<any>;
	IxOrganizationTruckSetting: UqIX<any>;
	IxUserWarehouseRole: UqIX<any>;
	IxCarrierWarehouse: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
