//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqID, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/ChemicalSecurity ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface TuidJNKRestrict {
	id?: number;
	description: string;
	no: string;
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface ParamAddStatuteChemical {
	statute: number;
	cas: string;
	standardName: string;
	englishName: string;
	purity: string;
	customsCode: string;
	unit: string;
	note: string;
	hazardClass: number;
}
export interface ReturnAddStatuteChemicalRet {
	resultStatuteChemicalId: number;
}
export interface ResultAddStatuteChemical {
	ret: ReturnAddStatuteChemicalRet[];
}

export interface ParamDeleteStatute {
	id: number;
}
export interface ReturnDeleteStatuteRet {
	resultCode: number;
	resultMessage: string;
}
export interface ResultDeleteStatute {
	ret: ReturnDeleteStatuteRet[];
}

export interface ParamAddStatuteChemicalAlias {
	statuteChemical: number;
	chineseAlias: string;
	englishAlias: string;
}
export interface ResultAddStatuteChemicalAlias {
}

export interface ParamAddLicenseStatuteChemical {
	hazardClassId: number;
	licenseId: number;
}
export interface ResultAddLicenseStatuteChemical {
}

export interface ParamDeleteLicenseStatuteChemical {
	hazardClassId: number;
	licenseId: number;
}
export interface ResultDeleteLicenseStatuteChemical {
}

export interface ParamUpdateStatuteChemical {
	statuteChemical: number;
	statute: number;
	standardName: string;
	englishName: string;
	cas: string;
	purity: string;
	customsCode: string;
	unit: string;
	note: string;
	hazardClass: number;
	oldHazardClass: number;
}
export interface ReturnUpdateStatuteChemicalRet {
	resultCode: number;
	resultStatuteChemicalId: number;
}
export interface ResultUpdateStatuteChemical {
	ret: ReturnUpdateStatuteChemicalRet[];
}

export interface ParamDeleteStatuteChemical {
	statute: number;
	statuteChemical: number;
}
export interface ResultDeleteStatuteChemical {
}

export interface ParamDeleteStatuteLicense {
	statute: number;
	license: number;
}
export interface ResultDeleteStatuteLicense {
}

export interface ParamUpdateStatuteAttachment {
	statute: number;
	attachmentName: string;
	attachmentPath: string;
}
export interface ReturnUpdateStatuteAttachmentRet {
	resultCode: number;
	resultMessage: string;
}
export interface ResultUpdateStatuteAttachment {
	ret: ReturnUpdateStatuteAttachmentRet[];
}

export interface ParamDeleteStatuteAttachment {
	statute: number;
}
export interface ResultDeleteStatuteAttachment {
}

export interface ParamUpdateLicenseAttachment {
	license: number;
	attachmentName: string;
	attachmentPath: string;
}
export interface ReturnUpdateLicenseAttachmentRet {
	resultCode: number;
	resultMessage: string;
}
export interface ResultUpdateLicenseAttachment {
	ret: ReturnUpdateLicenseAttachmentRet[];
}

export interface ParamDeleteLicenseAttachment {
	license: number;
}
export interface ResultDeleteLicenseAttachment {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface ParamAddStatuteMatchingFeature {
	statuteChemical: number;
	keywords: string;
	minPurity: number;
	maxPurity: number;
}
export interface ResultAddStatuteMatchingFeature {
}

export interface ParamDeleteStatuteMatchingFeature {
	statuteChemical: number;
	statuteChemicalFeatureId: number;
}
export interface ResultDeleteStatuteMatchingFeature {
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface ParamGetBuziPhaseByLicense {
	license: number;
}
export interface ReturnGetBuziPhaseByLicenseRet {
	main: number;
	id: number;
	name: string;
}
export interface ResultGetBuziPhaseByLicense {
	ret: ReturnGetBuziPhaseByLicenseRet[];
}

export interface ParamGetStatuteChemicals {
	statute: number;
	key: string;
}
export interface ReturnGetStatuteChemicals$page {
	id: number;
	main: number;
	hazardclassCode: string;
	standardName: string;
	englishName: string;
	cas: string;
	purity: string;
	customsCode: string;
	unit: string;
	note: string;
	hazardClass: number;
	createDate: any;
}
export interface ResultGetStatuteChemicals {
	$page: ReturnGetStatuteChemicals$page[];
}

export interface ParamGetStatuteLicense {
	statute: number;
	key: string;
}
export interface ReturnGetStatuteLicense$page {
	id: number;
	main: number;
	name: string;
	note: string;
	attachmentName: string;
	attachmentPath: string;
}
export interface ResultGetStatuteLicense {
	$page: ReturnGetStatuteLicense$page[];
}

export interface ParamGetStatuteChemicalAlias {
	statuteChemical: number;
	chineseAlias: string;
	englishAlias: string;
}
export interface ReturnGetStatuteChemicalAliasRet {
	id: number;
	chineseAlias: string;
	englishAlias: string;
}
export interface ResultGetStatuteChemicalAlias {
	ret: ReturnGetStatuteChemicalAliasRet[];
}

export interface ParamGetStatutes {
	key: string;
}
export interface ReturnGetStatutesRet {
	id: number;
	name: string;
	description: string;
	organization: string;
	supervisor: string;
	createDate: any;
	publishDate: any;
	startDate: any;
	endDate: any;
	attachmentName: string;
	attachmentPath: string;
}
export interface ResultGetStatutes {
	ret: ReturnGetStatutesRet[];
}

export interface ParamGetBuziPhaseByHazardClass {
	hazardClass: number;
}
export interface ReturnGetBuziPhaseByHazardClassRet {
	main: number;
	id: number;
	name: string;
}
export interface ResultGetBuziPhaseByHazardClass {
	ret: ReturnGetBuziPhaseByHazardClassRet[];
}

export interface ParamGetHazrdClassByLicense {
	license: number;
}
export interface ReturnGetHazrdClassByLicenseRet {
	main: number;
	id: number;
	statute: number;
	code: string;
	description: string;
}
export interface ResultGetHazrdClassByLicense {
	ret: ReturnGetHazrdClassByLicenseRet[];
}

export interface ParamGetStatuteChemicalByKey {
	key: string;
	license: number;
}
export interface ReturnGetStatuteChemicalByKey$page {
	id: number;
	standardName: string;
	englishName: string;
	cas: string;
	purity: string;
	customsCode: string;
	createDate: any;
}
export interface ResultGetStatuteChemicalByKey {
	$page: ReturnGetStatuteChemicalByKey$page[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface ParamGetMatchingFeatures {
	statute: number;
}
export interface ReturnGetMatchingFeaturesRet {
	statute: number;
	id: number;
	keywords: string;
}
export interface ResultGetMatchingFeatures {
	ret: ReturnGetMatchingFeaturesRet[];
}

export interface ParamGetStatuteChemicalMatchingFeatures {
	statuteChemical: number;
}
export interface ReturnGetStatuteChemicalMatchingFeaturesRet {
	statuteChemical: number;
	id: number;
	keywords: string;
}
export interface ResultGetStatuteChemicalMatchingFeatures {
	ret: ReturnGetStatuteChemicalMatchingFeaturesRet[];
}

export interface Param$AllPhrases {
}
export interface Return$AllPhrasesMain {
	id: number;
	name: string;
	caption: string;
	type: any;
}
export interface Return$AllPhrasesDetail {
	id: number;
	name: string;
	caption: string;
	owner: number;
	type: any;
}
export interface Result$AllPhrases {
	main: Return$AllPhrasesMain[];
	detail: Return$AllPhrasesDetail[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface License {
	id?: number;
	main?: number;
	name: string;
	note: string;
	attachmentName: string;
	attachmentPath: string;
}

export interface BuziPhase {
	id?: number;
	name: string;
}

export interface Strategy {
	id?: number;
	name: string;
}

export interface OrganizationLicense {
	id?: number;
	organization: number;
	license: number;
	code: string;
	start: any;
	end: any;
	createDate: any;
}

export interface StatuteChemical {
	id?: number;
	cas: string;
	standardName: string;
	englishName: string;
	purity: string;
	customsCode: string;
	unit: string;
	note: string;
	createDate: any;
}

export interface Organization {
	id?: number;
	name: string;
}

export interface StatuteMatchingFeatures {
	id?: number;
	keywords: string;
	minPurity: number;
	maxPurity: number;
}

export interface StatuteChemicalAlias {
	id?: number;
	main?: number;
	chineseAlias: string;
	englishAlias: string;
	createDate: any;
}

export interface HazardClass {
	id?: number;
	main?: number;
	code: string;
	description: string;
}

export interface Statute {
	id?: number;
	name: string;
	description: string;
	organization: string;
	supervisor: string;
	publishDate: any;
	startDate: any;
	endDate: any;
	attachmentName: string;
	attachmentPath: string;
	createDate: any;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
	timeZone: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface ProductHazardClass {
	ix: number;
	xi: number;
}

export interface ChemicalControlled {
	ixx: number;
	ix: number;
	xi: number;
}

export interface MyOrganization {
	ix: number;
	xi: number;
}

export interface IxHazardClassStatuteChemical {
	ix: number;
	xi: number;
	createDate: any;
}

export interface IxStatuteChemical {
	ix: number;
	xi: number;
	createDate: any;
}

export interface IxLicenseStatuteChemical {
	ix: number;
	xi: number;
	createDate: any;
}

export interface IxBuziPhaseLicense {
	ix: number;
	xi: number;
}

export interface IxHazardClassLicense {
	ix: number;
	xi: number;
}

export interface IxBuziPhaseHazardClass {
	ix: number;
	xi: number;
}

export interface IxHazardClassFeatures {
	ix: number;
	xi: number;
}

export interface IxStatuteChemicalFeatures {
	ix: number;
	xi: number;
}

export interface IxStatuteFeatures {
	ix: number;
	xi: number;
}

export interface IxStatuteProduct {
	ix: number;
	xi: number;
}

export interface IxStatuteChemicalProduct {
	ix: number;
	xi: number;
}

export interface ParamActs {
	$User?: $User[];
	license?: License[];
	buziPhase?: BuziPhase[];
	strategy?: Strategy[];
	organizationLicense?: OrganizationLicense[];
	statuteChemical?: StatuteChemical[];
	organization?: Organization[];
	statuteMatchingFeatures?: StatuteMatchingFeatures[];
	statuteChemicalAlias?: StatuteChemicalAlias[];
	hazardClass?: HazardClass[];
	statute?: Statute[];
	$phrase?: $phrase[];
	$Site?: $Site[];
	$UserSite?: $UserSite[];
	productHazardClass?: ProductHazardClass[];
	chemicalControlled?: ChemicalControlled[];
	myOrganization?: MyOrganization[];
	ixHazardClassStatuteChemical?: IxHazardClassStatuteChemical[];
	ixStatuteChemical?: IxStatuteChemical[];
	ixLicenseStatuteChemical?: IxLicenseStatuteChemical[];
	ixBuziPhaseLicense?: IxBuziPhaseLicense[];
	ixHazardClassLicense?: IxHazardClassLicense[];
	ixBuziPhaseHazardClass?: IxBuziPhaseHazardClass[];
	ixHazardClassFeatures?: IxHazardClassFeatures[];
	ixStatuteChemicalFeatures?: IxStatuteChemicalFeatures[];
	ixStatuteFeatures?: IxStatuteFeatures[];
	ixStatuteProduct?: IxStatuteProduct[];
	ixStatuteChemicalProduct?: IxStatuteChemicalProduct[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	JNKRestrict: UqTuid<TuidJNKRestrict>;
	$sheet: UqTuid<Tuid$sheet>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	AddStatuteChemical: UqAction<ParamAddStatuteChemical, ResultAddStatuteChemical>;
	DeleteStatute: UqAction<ParamDeleteStatute, ResultDeleteStatute>;
	AddStatuteChemicalAlias: UqAction<ParamAddStatuteChemicalAlias, ResultAddStatuteChemicalAlias>;
	AddLicenseStatuteChemical: UqAction<ParamAddLicenseStatuteChemical, ResultAddLicenseStatuteChemical>;
	DeleteLicenseStatuteChemical: UqAction<ParamDeleteLicenseStatuteChemical, ResultDeleteLicenseStatuteChemical>;
	UpdateStatuteChemical: UqAction<ParamUpdateStatuteChemical, ResultUpdateStatuteChemical>;
	DeleteStatuteChemical: UqAction<ParamDeleteStatuteChemical, ResultDeleteStatuteChemical>;
	DeleteStatuteLicense: UqAction<ParamDeleteStatuteLicense, ResultDeleteStatuteLicense>;
	UpdateStatuteAttachment: UqAction<ParamUpdateStatuteAttachment, ResultUpdateStatuteAttachment>;
	DeleteStatuteAttachment: UqAction<ParamDeleteStatuteAttachment, ResultDeleteStatuteAttachment>;
	UpdateLicenseAttachment: UqAction<ParamUpdateLicenseAttachment, ResultUpdateLicenseAttachment>;
	DeleteLicenseAttachment: UqAction<ParamDeleteLicenseAttachment, ResultDeleteLicenseAttachment>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	AddStatuteMatchingFeature: UqAction<ParamAddStatuteMatchingFeature, ResultAddStatuteMatchingFeature>;
	DeleteStatuteMatchingFeature: UqAction<ParamDeleteStatuteMatchingFeature, ResultDeleteStatuteMatchingFeature>;
	$poked: UqQuery<Param$poked, Result$poked>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	GetBuziPhaseByLicense: UqQuery<ParamGetBuziPhaseByLicense, ResultGetBuziPhaseByLicense>;
	GetStatuteChemicals: UqQuery<ParamGetStatuteChemicals, ResultGetStatuteChemicals>;
	GetStatuteLicense: UqQuery<ParamGetStatuteLicense, ResultGetStatuteLicense>;
	GetStatuteChemicalAlias: UqQuery<ParamGetStatuteChemicalAlias, ResultGetStatuteChemicalAlias>;
	GetStatutes: UqQuery<ParamGetStatutes, ResultGetStatutes>;
	GetBuziPhaseByHazardClass: UqQuery<ParamGetBuziPhaseByHazardClass, ResultGetBuziPhaseByHazardClass>;
	GetHazrdClassByLicense: UqQuery<ParamGetHazrdClassByLicense, ResultGetHazrdClassByLicense>;
	GetStatuteChemicalByKey: UqQuery<ParamGetStatuteChemicalByKey, ResultGetStatuteChemicalByKey>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	GetMatchingFeatures: UqQuery<ParamGetMatchingFeatures, ResultGetMatchingFeatures>;
	GetStatuteChemicalMatchingFeatures: UqQuery<ParamGetStatuteChemicalMatchingFeatures, ResultGetStatuteChemicalMatchingFeatures>;
	$AllPhrases: UqQuery<Param$AllPhrases, Result$AllPhrases>;
	$User: UqID<any>;
	License: UqID<any>;
	BuziPhase: UqID<any>;
	Strategy: UqID<any>;
	OrganizationLicense: UqID<any>;
	StatuteChemical: UqID<any>;
	Organization: UqID<any>;
	StatuteMatchingFeatures: UqID<any>;
	StatuteChemicalAlias: UqID<any>;
	HazardClass: UqID<any>;
	Statute: UqID<any>;
	$phrase: UqID<any>;
	$Site: UqID<any>;
	$UserSite: UqID<any>;
	ProductHazardClass: UqIX<any>;
	ChemicalControlled: UqIX<any>;
	MyOrganization: UqIX<any>;
	IxHazardClassStatuteChemical: UqIX<any>;
	IxStatuteChemical: UqIX<any>;
	IxLicenseStatuteChemical: UqIX<any>;
	IxBuziPhaseLicense: UqIX<any>;
	IxHazardClassLicense: UqIX<any>;
	IxBuziPhaseHazardClass: UqIX<any>;
	IxHazardClassFeatures: UqIX<any>;
	IxStatuteChemicalFeatures: UqIX<any>;
	IxStatuteFeatures: UqIX<any>;
	IxStatuteProduct: UqIX<any>;
	IxStatuteChemicalProduct: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
