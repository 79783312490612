//=== UqApp builder created on Wed Oct 11 2023 16:59:05 GMT+0800 (中国标准时间) ===//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IDXValue, Uq, UqTuid, UqAction, UqQuery, UqMap, UqID, UqIX } from "tonva-react";


//===============================
//======= UQ 百灵威系统工程部/promotion ========
//===============================

export enum EnumBizType {
	atom = 1,
	sheet = 2,
	key = 11,
	prop = 12,
	assign = 13,
	permit = 14,
	with = 15,
	role = 16
}

export interface Tuid$sheet {
	id?: number;
	no: string;
	user: number;
	date: any;
	sheet: number;
	version: number;
	flow: number;
	app: number;
	state: number;
	discription: string;
	data: string;
	processing: number;
}

export interface TuidSalesRegion {
	id?: number;
	name: string;
	currency: number;
	no: string;
}

export interface TuidLanguage {
	id?: number;
	no: string;
	description: string;
}

export interface TuidCurrency {
	id?: number;
	name: string;
	suffix: string;
}

export interface TuidProductX {
	id?: number;
	brand: number;
}

export interface TuidBrand {
	id?: number;
	name: string;
	no: string;
}

export interface TuidPromotion {
	id?: number;
	name: string;
	type: number;
	status: number;
	startDate: any;
	endDate: any;
	createTime: any;
	no: string;
}

export interface TuidPromotionType {
	id?: number;
	no: string;
	description: string;
}

export interface TuidPromotionStatus {
	id?: number;
	no: string;
	description: string;
}

export interface TuidChemical {
	id?: number;
	CAS: string;
}

export interface TuidProductCategory {
	id?: number;
	no: number;
	parent: number;
	isLeaf: number;
	orderWithinParent: number;
}

export interface TuidCustomer {
	id?: number;
}

export interface Tuid$user {
	id?: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timezone: number;
}

export interface Param$setMyTimezone {
	_timezone: number;
}
export interface Result$setMyTimezone {
}

export interface Param$role_Site_User_Role {
	site: number;
	user: number;
	action: string;
	role: string;
}
export interface Result$role_Site_User_Role {
}

export interface Param$role_Site_Quit_Owner {
	site: number;
}
export interface Result$role_Site_Quit_Owner {
}

export interface Param$role_Site_Add_User {
	site: number;
	user: number;
	assigned: string;
}
export interface Result$role_Site_Add_User {
}

export interface Param$role_Site_Del_Admin {
	site: number;
	user: number;
	admin: number;
}
export interface Result$role_Site_Del_Admin {
}

export interface Param$role_Site_Add_Admin {
	site: number;
	user: number;
	admin: number;
	assigned: string;
}
export interface Result$role_Site_Add_Admin {
}

export interface Param$setSite {
	site: number;
}
export interface Result$setSite {
}

export interface ParamGetPromotionPack {
	product: number;
	pack: number;
	salesRegion: number;
	language: number;
}
export interface ReturnGetPromotionPackRet {
	id: number;
	salesRegion: number;
	description: string;
	url: string;
	product: number;
	pack: number;
	discount: number;
	mustHasStorage: number;
}
export interface ResultGetPromotionPack {
	ret: ReturnGetPromotionPackRet[];
}

export interface Param$poked {
}
export interface Return$pokedRet {
	poke: number;
}
export interface Result$poked {
	ret: Return$pokedRet[];
}

export interface ParamSearchPromotion {
	keyWord: string;
	promotion: number;
	salesRegion: number;
}
export interface ReturnSearchPromotion$page {
	seq: number;
	id: number;
	no: string;
	brand: number;
	origin: string;
	description: string;
	descriptionC: string;
	imageUrl: string;
	chemical: number;
	CAS: string;
	purity: string;
	molecularFomula: string;
	molecularWeight: string;
}
export interface ResultSearchPromotion {
	$page: ReturnSearchPromotion$page[];
}

export interface Param$getUnitTime {
}
export interface Return$getUnitTimeRet {
	timezone: number;
	unitTimeZone: number;
	unitBizMonth: number;
	unitBizDate: number;
}
export interface Result$getUnitTime {
	ret: Return$getUnitTimeRet[];
}

export interface Param$role_My {
}
export interface Return$role_MySites {
	id: number;
	site: number;
	admin: number;
	entity: string;
	assigned: string;
	def: number;
}
export interface Return$role_MyRoles {
	site: number;
	role: string;
}
export interface Return$role_MyPermits {
	site: number;
	permit: string;
}
export interface Result$role_My {
	sites: Return$role_MySites[];
	roles: Return$role_MyRoles[];
	permits: Return$role_MyPermits[];
}

export interface Param$sites {
}
export interface Return$sites$page {
	id: number;
	no: string;
	ex: string;
}
export interface Result$sites {
	$page: Return$sites$page[];
}

export interface Param$role_Site_Users {
	site: number;
}
export interface Return$role_Site_UsersUsers {
	id: number;
	user: number;
	admin: number;
	assigned: string;
	name: string;
	nick: string;
	icon: string;
	addBy: number;
}
export interface Return$role_Site_UsersRoles {
	user: number;
	role: string;
}
export interface Result$role_Site_Users {
	users: Return$role_Site_UsersUsers[];
	roles: Return$role_Site_UsersRoles[];
}

export interface $User {
	id?: number;
	tonwaUser: number;
	name: string;
	nick: string;
	icon: string;
	assigned: string;
	poke: number;
	timeZone: number;
	site: number;
}

export interface $phrase {
	id?: number;
	name: string;
	caption: string;
	base: number;
	valid: number;
	owner: number;
	type: number;
}

export interface $UserSite {
	id?: number;
	site: number;
	user: number;
	admin: number;
	assigned: string;
	addBy: number;
}

export interface $Site {
	id?: number;
	no?: string;
	ex: string;
}

export interface $ixphrase {
	type: number;
}

export interface ParamActs {
	$User?: $User[];
	$phrase?: $phrase[];
	$UserSite?: $UserSite[];
	$Site?: $Site[];
	$ixphrase?: $ixphrase[];
}


export interface UqExt extends Uq {
	Acts(param:ParamActs): Promise<any>;

	$sheet: UqTuid<Tuid$sheet>;
	SalesRegion: UqTuid<TuidSalesRegion>;
	Language: UqTuid<TuidLanguage>;
	Currency: UqTuid<TuidCurrency>;
	ProductX: UqTuid<TuidProductX>;
	Brand: UqTuid<TuidBrand>;
	Promotion: UqTuid<TuidPromotion>;
	PromotionType: UqTuid<TuidPromotionType>;
	PromotionStatus: UqTuid<TuidPromotionStatus>;
	Chemical: UqTuid<TuidChemical>;
	ProductCategory: UqTuid<TuidProductCategory>;
	Customer: UqTuid<TuidCustomer>;
	$setMyTimezone: UqAction<Param$setMyTimezone, Result$setMyTimezone>;
	$role_Site_User_Role: UqAction<Param$role_Site_User_Role, Result$role_Site_User_Role>;
	$role_Site_Quit_Owner: UqAction<Param$role_Site_Quit_Owner, Result$role_Site_Quit_Owner>;
	$role_Site_Add_User: UqAction<Param$role_Site_Add_User, Result$role_Site_Add_User>;
	$role_Site_Del_Admin: UqAction<Param$role_Site_Del_Admin, Result$role_Site_Del_Admin>;
	$role_Site_Add_Admin: UqAction<Param$role_Site_Add_Admin, Result$role_Site_Add_Admin>;
	$setSite: UqAction<Param$setSite, Result$setSite>;
	GetPromotionPack: UqQuery<ParamGetPromotionPack, ResultGetPromotionPack>;
	$poked: UqQuery<Param$poked, Result$poked>;
	SearchPromotion: UqQuery<ParamSearchPromotion, ResultSearchPromotion>;
	$getUnitTime: UqQuery<Param$getUnitTime, Result$getUnitTime>;
	$role_My: UqQuery<Param$role_My, Result$role_My>;
	$sites: UqQuery<Param$sites, Result$sites>;
	$role_Site_Users: UqQuery<Param$role_Site_Users, Result$role_Site_Users>;
	PromotionSalesRegion: UqMap;
	PromotionLanguage: UqMap;
	PromotionPackDiscount: UqMap;
	BrandMinDiscount: UqMap;
	PromotionCustomerBlacklist: UqMap;
	$User: UqID<any>;
	$phrase: UqID<any>;
	$UserSite: UqID<any>;
	$Site: UqID<any>;
	$ixphrase: UqIX<any>;
}

export function assign(uq: any, to:string, from:any): void {
	let hasEntity = uq.$.hasEntity(to);
	if (hasEntity === false) {
		return;
	}
	Object.assign((uq as any)[to], from);
}
