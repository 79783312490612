import axios, { AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import { centerToken } from 'tonva-react';
import { UqToken, getUqToken } from 'tonva-react/dist/net/appBridge';

export interface HttpAxiosConfig {
    axiosConfig: CreateAxiosDefaults,
    tokenConfig?: any;
};

interface IAxios {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
}

class Axios {
    private axios: AxiosInstance;
    protected tokenConfig: any;
    getUqTokenDiff: UqToken;
    constructor(configs: HttpAxiosConfig) {
        const { axiosConfig, tokenConfig } = configs;
        this.tokenConfig = tokenConfig || {};
        let defaultConfig: any = Object.assign({ timeout: 0 }, axiosConfig);
        this.axios = axios.create(defaultConfig);
        this.getUqTokenDiff = getUqToken("百灵威系统工程部/common");
        this.init();
    }

    // 初始化拦截器
    private init() {
        // 定义请求拦截器
        this.axios.interceptors.request.use(
            (config: any) => {
                config.headers.Authorization = `Bearer ${this.getUqTokenDiff?.token}`;
                return config;
            },
            (error: any) => { return Promise.reject(error) }
        );
        // 定义响应拦截器
        this.axios.interceptors.response.use(
            (response: any) => { if (response.status === 200) return response.data },
            (error: any) => { return Promise.reject(error); }
        );
    }

    public request<T = any>(config: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.axios.request<T>(config)
                .then((data: any) => { resolve(data) })
                .catch((error) => reject(error));
        });
    }

    public get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.axios.get<T>(url, config)
                .then((data: any) => { resolve(data) })
                .catch((error) => reject(error));
        });
    }

    public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.axios.post<T>(url, data, config)
                .then((data: any) => { resolve(data) })
                .catch((error) => reject(error));
        });
    }

    public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.axios.put<T>(url, data, config)
                .then((data: any) => { resolve(data) })
                .catch((error) => reject(error));
        });
    }

    public delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.axios.delete<T>(url, config)
                .then((data: any) => { resolve(data) })
                .catch((error) => reject(error));
        });
    };
};

export default Axios;
export type { IAxios };