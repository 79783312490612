import AnalyticalChemistry from './images/AnalyticalChemistry.png';
import LabSupplies from './images/LabSupplies.png';
import LifeScience from './images/LifeScience.png';
import MaterialScience from './images/MaterialScience.png';
import OrganicChemistry from './images/OrganicChemistry.png';

import AnalyticalChemistry_j from './images/AnalyticalChemistry.jpg';
import LabSupplies_j from './images/LabSupplies.jpg';
import LifeScience_j from './images/LifeScience.jpg';
import MaterialScience_j from './images/MaterialScience.jpg';
import OrganicChemistry_j from './images/OrganicChemistry.jpg';

import { env } from 'tonva-react';

enum EnumCategory { OrganicChemistry = 1, AnalyticalChemistry = 2, LifeScience = 3, MaterialScience = 4, LabSupplies = 5 };
const CategoryIds_Production: { [id: number]: EnumCategory } = {
	47: EnumCategory.OrganicChemistry,
	470: EnumCategory.AnalyticalChemistry,
	1013: EnumCategory.LifeScience,
	1219: EnumCategory.MaterialScience,
	1545: EnumCategory.LabSupplies,
};
const CategoryIds_Test: { [id: number]: EnumCategory } = {
	7: EnumCategory.OrganicChemistry,
	430: EnumCategory.AnalyticalChemistry,
	986: EnumCategory.LifeScience,
	1214: EnumCategory.MaterialScience,
	1545: EnumCategory.LabSupplies,
};

interface CategoryRes {
	src: string;		// 所用图片的src
	viceSrc: string;    // 副图片的src
	labelColor: string;
	bgClass: string;
}

const RootCategoryRes: { [key in EnumCategory]: CategoryRes } = {
	[EnumCategory.OrganicChemistry]: {
		src: OrganicChemistry,
		viceSrc: OrganicChemistry_j,
		labelColor: 'text-info',
		bgClass: "mint-bg",
	},
	[EnumCategory.AnalyticalChemistry]: {
		src: AnalyticalChemistry,
		viceSrc: AnalyticalChemistry_j,
		labelColor: 'text-success',
		bgClass: "green-bg",
	},
	[EnumCategory.LifeScience]: {
		src: LifeScience,
		viceSrc: LifeScience_j,
		labelColor: 'text-danger',
		bgClass: "red-bg",
	},
	[EnumCategory.MaterialScience]: {
		src: MaterialScience,
		viceSrc: MaterialScience_j,
		labelColor: 'text-warning',
		bgClass: "purple-bg",
	},
	[EnumCategory.LabSupplies]: {
		src: LabSupplies,
		viceSrc: LabSupplies_j,
		labelColor: 'text-primary',
		bgClass: "blue-bg",
	},
};

interface Language {
	id: number;
	no: "zh-CN";
	description: "华 -中国";
};

interface SaleRegion {
	id: number;
	name: string;
	currency: 5;
	no: "CN";
};

interface Global {
	CHINA: number;
	CHINESE: Language;
	SALESREGION_CN: SaleRegion;
	//TIPDISPLAYTIME: 3000;
	ANDROIDAPPADDRESS: string;
	PIRVACYURL: string;
	CONTENTSITE: string;
	EPEC: {
		AUTOLOGIN: string;
		PUSHORDERURL: string;
		ERRORINFORECORD: string;
	},
	PUNCHOUT: {
		PUNCHOUTXML: string;
	},
	LOGISTIC: {
		ORDERTRANS: string;
	},
	DRAWMOLECULEGWT: {
		HTML: string;
		JSSRC: string;
	},
	TCIGETPACK: string;
	PAY: {
		createPay: string;
		payStatus: string;
		orderPayStatus: string;
		paymentinfoByorderno: string;
	}
};

// 生产配置
const GLOABLE_PRODUCTION: Global = {
	CHINA: 44,
	CHINESE: { "id": 196, "no": "zh-CN", "description": "华 -中国" },
	SALESREGION_CN: { "id": 1, "name": "中国大陆", "currency": 5, "no": "CN" },
	//TIPDISPLAYTIME: 3000,
	ANDROIDAPPADDRESS: "https://shop.jkchemical.com/download/jk-shop.apk",
	PIRVACYURL: "https://shop.jkchemical.com/privacy/shop.txt",
	CONTENTSITE: "https://web.jkchemical.com",
	EPEC: {
		AUTOLOGIN: "https://web.jkchemical.com/api/joint/clientLogin",
		PUSHORDERURL: "https://joint.jkchemical.com/epec/pushOrder",
		ERRORINFORECORD: "https://joint.jkchemical.com/epec/errorInfoRecord"

	},
	PUNCHOUT: {
		PUNCHOUTXML: "https://joint.jkchemical.com/punchout/generatePunchOutXML"
	},
	LOGISTIC: {
		ORDERTRANS: "https://joint.jkchemical.com/logistics/getTravelInformation"
	},
	DRAWMOLECULEGWT: {
		HTML: "https://test.jkchemical.com/drawmoleculeGWT",
		JSSRC: "https://test.jkchemical.com/drawmoleculeGWT/drawmolecule/drawmolecule.nocache.js"
	},
	TCIGETPACK: "https://joint.jkchemical.com/tci/getNumberPacking",
	// TCIGETPACK: "https://joint.jkchemical.com/tci/getNumberPacking",
	// CONTENTSITE: "http://localhost:6061",
	PAY: {
		createPay: "https://joint.jkchemical.com/payment/order/createpayment",
		payStatus: "https://joint.jkchemical.com/payment/order/paymentStatues",
		orderPayStatus: "https://joint.jkchemical.com/api/order/getorderByPayStatue",
		paymentinfoByorderno: "https://joint.jkchemical.com/payment/order/paymentinfoByorderno",
	}
}

// 测试环境配置
const GLOABLE_TEST: Global = {
	CHINA: 43,
	CHINESE: { "id": 197, "no": "zh-CN", "description": "华 -中国" },
	SALESREGION_CN: { "id": 4, "name": "中国大陆", "currency": 5, "no": "CN" },
	ANDROIDAPPADDRESS: "https://shop.jkchemical.com/download/jk-shop.apk",
	PIRVACYURL: "https://test.jkchemical.com/privacy/shop.txt",
	CONTENTSITE: "https://test.jkchemical.com",
	EPEC: {
		AUTOLOGIN: "https://test.jkchemical.com/api/joint/clientLogin",
		PUSHORDERURL: "https://test.jkchemical.com/joint/epec/pushOrder",
		ERRORINFORECORD: "https://test.jkchemical.com/joint/epec/errorInfoRecord"
	},
	PUNCHOUT: {
		PUNCHOUTXML: "https://test.jkchemical.com/joint/punchout/generatePunchOutXML"
	},
	LOGISTIC: {
		ORDERTRANS: "https://test.jkchemical.com/joint/logistics/getTravelInformation"
	},
	DRAWMOLECULEGWT: {
		HTML: "https://test.jkchemical.com/drawmoleculeGWT",
		JSSRC: "https://test.jkchemical.com/drawmoleculeGWT/drawmolecule/drawmolecule.nocache.js"
	},
	TCIGETPACK: "https://test.jkchemical.com/joint/tci/getNumberPacking",
	PAY: {
		createPay: "https://test.jkchemical.com/joint/payment/order/createpayment",
		payStatus: "https://test.jkchemical.com/joint/payment/order/paymentStatues",
		orderPayStatus: "https://test.jkchemical.com/joint/api/order/getorderByPayStatue",
		paymentinfoByorderno: "https://test.jkchemical.com/joint/payment/order/paymentinfoByorderno",
	}
}

export const GLOABLE = env.testing === true ? GLOABLE_TEST : GLOABLE_PRODUCTION;
//export { GLOABLE_PRODUCTION as GLOABLE };
//export { GLOABLE_TEST as GLOABLE };

let categoryIdRes: { [id: number]: CategoryRes };
(function () {
	categoryIdRes = {};
	let categoryIds = env.testing === true ? CategoryIds_Test : CategoryIds_Production;
	for (let id in categoryIds) {
		categoryIdRes[id] = RootCategoryRes[categoryIds[id]];
	}
})();

export function rootCategroyResFromId(categoryId: number): CategoryRes {
	return categoryIdRes[categoryId];
}
