/* eslint-disable */
import * as React from 'react';
import { VPage, Page, BoxId, EasyDate, Ax, Tuid, nav, List, tv } from 'tonva-react';
import { COrder } from './COrder';
import { OrderItem } from './Order';
import { xs } from '../tools/browser';
import classNames from 'classnames';
import { observable, makeObservable } from 'mobx';
import { COUPONBASE } from 'coupon/VVIPCard';
//import { CartItem } from '../cart/Cart';

const payResult: { [type: string]: string } = {
    "TRADE_SUCCESS": "付款成功",
    "WAIT_BUYER_PAY": "待付款",
    "Refund": "退费"
};

export class VOrderDetail extends VPage<COrder> {
    /* orderTrans: any[] = [];

    constructor(c: COrder) {
        super(c);

        makeObservable(this, {
            orderTrans: observable
        });
    } */

    async open(order: any) {

        this.openPage(this.page, order);
    }


    private packsRow = (item: any, index: number) => {
        let { pack, quantity, price, currency } = item;
        let d = <div>{ this.controller.uqs.JkDeliver.OrderDetail}</div>
        return <div key={index} className={classNames('px-2 py-2', index !== 0 ? 'border-top' : '')}>
            <div className="d-flex align-items-center">
                <div className="flex-grow-1"><b>{tv(pack)}</b></div>
                <div className="w-12c mr-4 text-right">
                    <span className="text-danger h5"><small>¥</small>{parseFloat((price * quantity).toFixed(2))}</span>
                    <small className="text-muted">(¥{parseFloat(price.toFixed(2))} × {quantity})</small>
                </div>
            </div>
        </div>;
    }

    againCreatOrder = async (initialData: OrderItem[]) => {
		/*
        let { uqs, currentSalesRegion, cCart } = this.controller.cApp;
        let { product: p } = uqs;
        let { PriceX } = p;
        let promises: PromiseLike<void>[] = [];
        initialData.forEach((e: any) => e.packs.forEach((v: any) => {
            promises.push(PriceX.obj({ product: e.product, pack: v.pack, salesRegion: currentSalesRegion }))
        }));
        let prices: any[] = await Promise.all(promises);
        let orderData: CartItem[] = [];
        let productPromises: Promise<any>[] = [];
        for (let key of initialData) {
            let { product, packs } = key;
            let filtPacksByProductId = prices.filter((v: any) => Tuid.equ(v.product, product) && v.discountinued === 0 && v.expireDate > Date.now());
            if (!filtPacksByProductId.length) continue;
            for (let i of packs) {
                let findPack = filtPacksByProductId.find((v: any) => Tuid.equ(v.pack, i.pack));
                if (!findPack) continue;
                product = this.controller.cApp.getProduct(product.id);
                productPromises.push(product.loadListItem());
                orderData.push({
                    product: product,
                    packs: [{ pack: i.pack, quantity: i.quantity || 1, price: findPack.retail, retail: findPack.retail, currency: findPack.salesRegion?.obj?.currency }],
                    $isSelected: true,
                    $isDeleted: false,
                    createdate: Date.now()
                })
            };
        };
        await Promise.all(productPromises);
        orderData.forEach((v: any) => {
            let newPrices: any[] = v.product?.prices || [];
            let findPack = newPrices.find((i: any) => Tuid.equ(i.pack, v.packs[0].pack));
            if (findPack) {
                let { vipPrice, promotionPrice } = findPack;
                v.packs[0].price = this.minPrice(vipPrice, promotionPrice) || v.packs[0].price;
            };
        });
        cCart.againOrderCart(orderData);
		*/
		await this.controller.cApp.store.cart.againCreatOrder(initialData);
        nav.navigate('/cart');
    }

    private renderOrderItem = (orderItem: any /* OrderItem */, index: number) => {
        let { product, packs, param } = orderItem;
        let { id } = product;
        let { controller, packsRow } = this;
        let transportation: any;
        if (param?.transportation) {
            let { carrier, waybillNumber } = param.transportation;
            transportation = {transNumber: waybillNumber, expressLogistics: carrier};
        };
        let orderTransUI: JSX.Element;
        if (transportation) orderTransUI = <span className="mr-2 cursor-pointer btn btn-sm btn-info"
            style={{ background: "#17a2b8" }}
            onClick={() => controller.openOrderTrans(transportation)} >查看物流</span>;
        return <div className="row my-1 w-100 mx-0">
            <div className="col-lg-6 pb-3">{controller.renderOrderItemProduct(product)}</div>
            <div className="col-lg-6">{
                packs.map((p:any, index:number) => {
                    return packsRow(p, index);
                })
            }</div>
            <div className="text-right w-100 px-3">
                <Ax className="mx-2 text-info font-weight-bold" href={'/product/mscu/MSDS/' + id}>SDS</Ax>
                {orderTransUI}
                <div className="btn btn-sm btn-info float-left float-lg-right cursor-pointer"
                    style={{ background: "#17a2b8" }} onClick={() => { this.againCreatOrder([orderItem]) }}>
                    再次购买
                </div>
            </div>
        </div>
    }

    private page = (order: any) => {

        let { brief, data, payStatus, payInfo } = order;
        let { id, no, state, description, date } = brief;
        let { orderItems, currency, shippingContact, invoiceContact, invoiceType, invoiceInfo, amount, comments, couponOffsetAmount, couponRemitted
            , freightFee, freightFeeRemitted, coupon } = data;
        let couponUI;
        if (couponOffsetAmount || couponRemitted) {
            let offsetUI, remittedUI;
            if (couponOffsetAmount) {
                offsetUI = <div className="d-flex flex-row justify-content-between">
                    <div className="text-muted">折扣:</div>
                    <div className="text-right text-danger"><small>¥</small>{couponOffsetAmount}</div>
                </div>
            }
            if (couponRemitted) {
                remittedUI = <div className="d-flex flex-row justify-content-between">
                    <div className="text-muted">抵扣:</div>
                    <div className="text-right text-danger"><small>¥</small>{couponRemitted}</div>
                </div>
            }
            couponUI = <div className="bg-white row no-gutters p-3 my-1">
                <div className="col-3 text-muted">优惠券:</div>
                <div className="col-9">
                    {offsetUI}
                    {remittedUI}
                </div>
            </div>
        }

        /* let freightFeeUI, freightFeeRemittedUI;
        if (freightFee) {
            freightFeeUI = <>
                <div className="text-right text-danger"><small>¥</small>{freightFee}</div>
            </>
            if (freightFeeRemitted) {
                freightFeeRemittedUI = <>
                    <div className="text-right text-danger"><small>¥</small>{freightFeeRemitted}(减免)</div>
                </>
            }
        } */

        let CouponUI: JSX.Element;
        if (coupon) {
            let { code, types } = coupon;
            let CType: string = "";
            if (types) CType = COUPONBASE[types?.toLowerCase()]?.name;
            CouponUI = <div className="bg-white row no-gutters p-3 my-1">
                <div className="col-3 text-muted">卡券:</div>
                <div className="col-9 text-right">{CType}&nbsp;&nbsp;{code}</div>
            </div>;
        };

        let orderAgainUI = <div className="px-3 py-2 border-bottom" style={{userSelect: "none"}}>
            <span className="align-middle">若需再次购买该订单中所有产品，请 </span>
            <button className="btn btn-sm btn-secondary cursor-pointer" title='可直接下单再次购买订单中产品'
                style={{ background: "#6c757d" }}
                onClick={() => { this.againCreatOrder(orderItems) }}>再次下单</button>
            {/* <button className="btn btn-primary w-50" onClick={async () => { this.controller.orderAgain(order.data) }}>再次下单</button> */}
        </div>

        let commentsUI = comments ? <div className="bg-white row no-gutters p-3 my-1">
            <div className="col-3 text-muted">备注:</div>
            <div className="col-9">{comments}</div>
        </div> : null;

        let header: any
        if (xs) header = <>订单详情: {no}</>            //orderAgainUI
        let infoArr: any[] = [
            { name: "收货地址:", value: tv(shippingContact), isShow: shippingContact },
            { name: "发票地址:", value: tv(invoiceContact), isShow: invoiceContact },
            { name: "发票信息:", value: invoiceTemplate(invoiceType, invoiceInfo), isShow: invoiceType || invoiceInfo },
        ];

        let payBtn: JSX.Element, payInfoUI: JSX.Element;
        if (!payStatus) {
            payBtn = <div className='text-right' >
                <button onClick={() => {
                    let p: any = { no: brief.no, amount: data.amount }
                    this.controller.openOrderSuccessPay(p);
                    document.body.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                }} style={{ backgroundColor: "#dc3545" }}
                    className='btn btn-danger'>去支付</button>
            </div>;
            if (!data.amount) payBtn = <></>;
        } else {
            let payTypes: any = { "AliPay": "支付宝", "WechatPay": "微信" };
            let payTitle: string = payInfo ? payTypes[payInfo.payway] : "其他";
            payInfoUI = <div className='card p-2 small'>
                <h5>付款信息</h5>
                <div className='row mx-0' >
                    <span className='col-3' >付款方式</span>
                    <span className='col-9' > {payTitle}</span></div>
                <div className='row mx-0' >
                    <span className='col-3' >商户单号</span>
                    <span className='col-9' > {payInfo?.orderno || no}</span></div>
                {payInfo && <div className='row mx-0' >
                    <span className='col-3' >支付结果</span>
                    <span className='col-9' > {payResult[payInfo?.statues]}</span></div>}
                {payInfo && <div className='row mx-0' >
                    <span className='col-3' >交易单号</span>
                    <span className='col-9' > {payInfo?.outtradeno}</span></div>}
                {payInfo && <div className='row mx-0' >
                    <span className='col-3' >订单流水号</span>
                    <span className='col-9' > {payInfo?.tradeno}</span></div>}
            </div>;
        };

        return <Page header={header} footer={<></>}>
            {!xs && <div className="alert alert-info alert-signin mt-3">订单编号 {no}</div>}
            {orderAgainUI}
            <List items={orderItems} item={{ render: this.renderOrderItem }} />
            {
                infoArr.map((el: any) => {
                    if (!el.isShow) return null;
                    return <div className="bg-white row no-gutters p-3 my-1" key={el.name}>
                        <div className="col-3 text-muted">{el.name}</div>
                        <div className="col-9">{el.value}</div>
                    </div>
                })
            }
            {/* <div className="bg-white row no-gutters p-3 my-1">
                <div className="col-3 text-muted">运费:</div>
                <div className="col-9">{freightFeeUI}{freightFeeRemittedUI}</div>
            </div> */}
            {couponUI}
            {CouponUI}
            <div className="bg-white row no-gutters p-3 my-1">
                <div className="col-3 text-muted">下单时间:</div>
                <div className="col-9 text-right"><EasyDate date={date} /></div>
            </div>
            {commentsUI}
            {payInfoUI}
            <div className="bg-white p-3 my-1 text-right">
                <span className="text-danger font-weight-bold">总金额: {amount}{tv(currency)}</span>
            </div>
            {payBtn}
        </Page>
    }
}

function invoiceTemplate(invoiceType: BoxId, invoiceInfo: BoxId): JSX.Element {
    return <>
        {tv(invoiceType, invoiceTypeUI, undefined, () => <></>)}<br />
        {tv(invoiceInfo, invoiceInfoUI, undefined, () => <></>)}
    </>
}

function invoiceTypeUI(values: any) {
    let { id, description } = values;
    return <>{description}</>;
}

function invoiceInfoUI(values: any) {
    let { id, title, taxNo, address, telephone, bank, accountNo } = values;
    return <>{title}</>;
}