/* eslint-disable */
import * as React from "react";
import { View, nav, LMR, Image, FA, A, Ax } from "tonva-react";
import { CMe } from "./CMe";
import { observer } from "mobx-react";

/**
 * 在jk-web中使用的登录状态组件
 */
export class VLoginState extends View<CMe> {
    render() {
        return <this.page />;
    }

    private page = observer(() => {
        let { user } = this.controller;
        let v: any;
        /* if (!user) {
            v = <>
                <a className="a-light top-log-reg-l" style={{ padding: "5px 12px" }} href="/register" target="_self">注册</a>
                <a className="a-light top-log-reg-r" style={{ padding: "5px 12px" }} href="/login" target="_self">登录</a>
            </>;
        }
        else {
            let { name, nick, icon } = user;
            let Avatar: JSX.Element = !icon ? <FA name="user" size="2x" className="text-primary" /> : <Image className="w-2c h-2c" style={{ width: "1.8rem", height: "1.8rem" }} src={icon} />
            v = <>
                <a className="mr-2 nav-item dropdown" href="/me" target="_self">
                    {Avatar}
                    <span className="dropdown-menu dropdown-menu-left px-2 m-0">{nick || name}</span>
                </a>
                <a className="mr-2" href="/logout" target="_self">退出</a>
            </>;
        }
        return <>
            {v}
        </>; */
        if (!user) {
            v = (
                <a href="/me">
                    <i className="fa fa-user fa-2x" aria-hidden="true"></i>
                </a>
            );
        } else {
            // let { name, nick, icon } = user;
            v = (
                <>
                    <a href="/me">
                        <i
                            className="fa fa-user fa-2x"
                            style={{
                                color: "#ad2031",
                            }}
                            aria-hidden="true"
                        ></i>
                    </a>
                    <a
                        className="me-2"
                        href="/logout"
                        style={{
                            fontSize: "12px",
                            padding: "10px 6px",
                        }}
                    >
                        退出
                    </a>
                </>
            );
        }
        return (
            <div
                className="login"
                style={{ fontSize: "14px", minWidth: "200" }}
            >
                {v}
            </div>
        );
    });
}

export function userSpan(name: string, nick: string): JSX.Element {
    return nick ? (
        <>
            <b>
                {nick} &nbsp; <small className="muted">{name}</small>
            </b>
        </>
    ) : (
        <b>{name}</b>
    );
}
