/* eslint-disable */
import { User, nav, NavPage, BoxId, PageWebNav, centerToken, uqTokenApi, logoutApis } from "tonva-react";
//import { Cart/*, LOCALCARTNAME*/ } from "../cart/Cart";
import { CHome } from "../home";
import { CCart } from "../cart";
import { CProduct } from "../product";
import { COrder } from "../order/COrder";
import { CProductCategory } from "../productCategory/CProductCategory";
import { CMember } from "../member";
import { CMe } from "../me/CMe";
import { CUqApp } from "./CBase";
import { VMain } from "tapp/main";
import * as qs from "querystringify";
import { CCoupon } from "coupon/CCoupon";
import { CPointProduct } from "pointMarket/CPointProduct";
import { CYncProjects } from "ync/CYncProjects";
import { CFavorites } from "customer/CFavorites";
import { CLottery } from "pointMarket/lottery/CLottery";
import { CSignIn } from "pointMarket/signIn/CSignIn";
import { Store, Product } from "../store";
import { WebUser } from "CurrentUser";
import { GLOABLE } from "global";
import {
    CSelectInvoiceContact,
    CSelectShippingContact,
} from "customer/CSelectContact";
import { CAddress } from "../customer/CAddress";
import { CInvoiceInfo } from "customer/CInvoiceInfo";
import { CQuickOrder } from "../order/CQuickOrder";
import { CCertificate } from "customer/certificate/CCertificate";
import { defaultRemoveLocalStorage } from "tools/browser";
import Cookie from "react-cookies";
import { Encrypt } from "tools/cryptojs";
import { changeTDKByPathname } from "tools/tdk";
import { UqToken, getUqToken } from "tonva-react/dist/net/appBridge";
import { setCookieUqToken } from "tools/uqToken";

export class CApp extends CUqApp {
    store: Store;
    private cache: Map<number, Product>;
    //cart: Cart;
    currentSalesRegion: any;
    currentLanguage: any;
    currentUser: WebUser;

    topKey: any;

    cHome: CHome;
    cCart: CCart;

    cProduct: CProduct;
    cOrder: COrder;
    cQuickOrder: CQuickOrder;
    cCoupon: CCoupon;
    cProductCategory: CProductCategory;
    cMember: CMember;
    cMe: CMe;
    cPointProduct: CPointProduct;
    cFavorites: CFavorites;
    cYncProjects: CYncProjects;
    cLottery: CLottery;
    cSignIn: CSignIn;
    cSelectShippingContact: CSelectShippingContact;
    cSelectInvoiceContact: CSelectInvoiceContact;
    cAddress: CAddress;
    cInvoiceInfo: CInvoiceInfo;
    certificate: CCertificate;

    protected async beforeStart(): Promise<boolean> {
        if ((await super.beforeStart()) === false) return false;
        setCookieUqToken();
        logoutApis();

        this.store = new Store(this.uqs);
        this.currentSalesRegion = GLOABLE.SALESREGION_CN;
        this.currentLanguage = GLOABLE.CHINESE;
        this.store.currentSalesRegion = this.currentSalesRegion;
        this.store.currentLanguage = this.currentLanguage;

        this.cache = new Map();
        /*
        this.setUser();
        this.cart = new Cart(this);
        await this.cart.init();
        await this.cart.buildItems();
        */

        this.cHome = this.newC(CHome);
        this.cProductCategory = this.newC<CProductCategory>(CProductCategory);
        this.cProduct = this.newC(CProduct);
        this.cOrder = this.newC(COrder);
        this.cQuickOrder = this.newC(CQuickOrder);
        this.cCart = this.newC(CCart);
        this.cCoupon = this.newC(CCoupon);
        this.cMember = this.newC(CMember);
        this.cMe = this.newC(CMe);
        this.cPointProduct = this.newC(CPointProduct);
        this.cFavorites = this.newC(CFavorites);
        this.cYncProjects = this.newC(CYncProjects);
        this.cLottery = this.newC(CLottery);
        this.cSignIn = this.newC(CSignIn);
        this.cSelectShippingContact = this.newC(CSelectShippingContact);
        this.cSelectInvoiceContact = this.newC(CSelectInvoiceContact);
        this.cAddress = this.newC(CAddress);
        this.cInvoiceInfo = this.newC(CInvoiceInfo);
        this.certificate = this.newC(CCertificate);
        // await this.store.initCart();/* 暂时性初始化购物车 调整后可删除 */
        await this.store.getWarehouseNames();
        await this.cSelectShippingContact.getContactList();
        await this.cHome.getSlideShow();
        return true;
    }

    protected async internalStart(params: any) {
        // setCookieUqToken();
        let promises: PromiseLike<void>[] = [];
        promises.push(this.cProductCategory.start());
        await Promise.all(promises);

        let { location } = document;
        let { search, pathname } = location;
        if (search) {
            let query: any = qs.parse(search.toLowerCase());
            switch (query.type) {
                case "privacy":
                    this.showMain();
                    this.cMe.openPrivacy();
                    break;
                case "product":
                    this.showMain();
                    let prouductBoxId = await this.uqs.product.ProductX.boxId(
                        query.product
                    ).assure();
                    await this.cProduct.showProductDetail(prouductBoxId);
                    break;
                case "coupon":
                    this.showMain();
                    if (query.coupon)
                        await this.cCoupon.showSharedCoupon(query);
                    break;
                case "credits":
                    this.showMain();
                    if (query.credits) {
                        await this.cCoupon.showSharedCredits(query);
                    }
                    break;
                case "vipcard":
                    this.showMain();
                    if (query.vipcard)
                        await this.cCoupon.showSharedVIPCard(query);
                    break;
                case "login":
                    if (!this.isLogined)
                        this.cMe.showLogin(async (user: User) => {
                            window.location.href = window.location.origin;
                        });
                    break;
                case "loginout":
                    if (this.isLogined)
                        this.cMe.showLoginOut(async () => {
                            window.location.href = window.location.origin;
                        });
                    break;
                case "productlist":
                    this.cProduct.start(query.key);
                    break;
                case "me":
                    this.cMe.openMyOrders("all");
                    break;
                default:
                    this.showMain();
                    break;
            }
        } else {
            this.showMain();
            //this.openVPage(Entrance);
        }
    }

    protected async afterStart(): Promise<void> {
        // await super.afterStart();
        nav.onChangeLogin = (user: User) => this.onChangeLogin(user);
        await this.onChangeLogin(this.user);
        nav.resolveRoute();
        this.topKey = nav.topKey();
    }

    renderCartLabel() {
        return this.cCart.renderCartLabel();
    }

    /*
    private setUser() {
        this.currentUser = new WebUser(this.uqs); //this.cUqWebUser, this.cUqCustomer);
        this.currentUser.setUser(this.user);
    }
    */

    /**
     *
     * @returns
     */
    getPageWebNav(): PageWebNav {
        let webNav = this.getWebNav();
        if (webNav === undefined) return;
        let {
            VNavHeader,
            VNavRawHeader,
            VNavFooter,
            VNavRawFooter,
            renderPageHeader,
        } = webNav;
        let navHeader: JSX.Element;
        if (VNavHeader) navHeader = this.renderView(VNavHeader);
        let navRawHeader: JSX.Element;
        if (VNavRawHeader) navRawHeader = this.renderView(VNavRawHeader);
        let navFooter: JSX.Element;
        if (VNavFooter) navFooter = this.renderView(VNavFooter);
        let navRawFooter: JSX.Element;
        if (VNavRawFooter) navRawFooter = this.renderView(VNavRawFooter);
        let ret: PageWebNav = {
            navHeader,
            navRawHeader,
            navFooter,
            navRawFooter,
            renderPageHeader,
        };
        return ret;
    }

    showMain(initTabName?: string) {
        this.openVPage(VMain, initTabName);
    }

    getProduct(id: number | BoxId): Product {
        if (!id) return;
        // region, language 改变的时候，直接清cache
        /*
        let {currentSalesRegion, currentLanguage} = this;
        if (this.salesRegion !== currentSalesRegion
            || this.language != currentLanguage) {
            this.cache = new Map<number, Product>();
            this.salesRegion = currentSalesRegion;
            this.language = currentLanguage;
        }
        */
        if (typeof id === "object") id = id.id;
        let product = this.cache.get(id);
        if (!product) {
            product = new Product(this.store, id);
            this.cache.set(id, product);
        }
        return product;
    }

    /*
        // onRoute在beforeStart中调用。this.on的作用是将url和function的关系（即route)配置在导航基础结构中供使用
        // 导航的基本原理是：根据当前的location.href，从配置好的route中找到匹配项，执行对应的function。
        protected onRoute() {
            this.on(() => {
                this.showMain();
            });
            this.on({
                '/search/:key': (params: any, queryStr: any) => {
                    this.cProduct.start(params.key);
                },
                '/product/:id': (params: any, queryStr: any) => {
                    this.cProduct.showProductDetail(params.id);
                },
                '/cart': () => {
                    this.cCart.start();
                },
                '/product-catalog/:id': (params: any, queryStr: any) => {
                    this.cProduct.showProductDetail(params.id);
                },
                '/pointshop': () => {
                    // 积分商城是否需要登录后才能查看？
                    this.cPointProduct.openMyPoint();
                }
            });
        }
    */

    private navHome: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.cHome.getSlideShow();

        let promises: PromiseLike<void>[] = [];
        promises.push(this.cProductCategory.start());
        await Promise.all(promises);
        defaultRemoveLocalStorage();
        this.showMain();
    };

    private navSearch: NavPage = async (params: any) => {
        changeTDKByPathname();
        let { search } = document.location;
        let query: any = { type: 1 };
        if (search) query = qs.parse(search.toLowerCase());
        let type = query?.type && Number(query.type) === 2 ? query.type : 1;
        let promises: PromiseLike<void>[] = [];
        promises.push(this.cProductCategory.start());
        await Promise.all(promises);
        await this.store.buildCartItems();
        this.cProduct.start({ key: params?.key, type: type });
        defaultRemoveLocalStorage();
    };

    private navProduct: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.store.buildCartItems();
        this.cProduct.showProductDetail(params?.id);
        defaultRemoveLocalStorage();
    };

    private navProductMSCU: NavPage = async (params: any) => {
        changeTDKByPathname();
        // await this.assureLogin();
        this.cProduct.openMaterial(params?.type, params?.id);
        defaultRemoveLocalStorage();
    };

    private navCart: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.store.buildCartItems();
        await this.cSelectShippingContact.getContactList();
        await this.cCart.start();
    };

    private navProductCategory: NavPage = async (params: any) => {
        changeTDKByPathname();
        let id = params.id;
        if (id) id = Number(id);
        await this.store.buildCartItems();
        await this.cProductCategory.showCategoryPage(id);
        defaultRemoveLocalStorage();
    };

    private navProductCategoryHome: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.cProductCategory.showCategoryHome();
        defaultRemoveLocalStorage();
    };

    private navPointShop: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cPointProduct.openMyPoint();
    };

    private navAbout: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cMe.openAbout();
    };

    private navMe: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cMe.start();
        defaultRemoveLocalStorage();
    };

    private navContactList: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        this.cMe.openContactList();
    };

    private navInvoice: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        this.cMe.openInvoice();
        defaultRemoveLocalStorage();
    };

    private navMyOrders: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        this.cMe.openMyOrders("all");
        defaultRemoveLocalStorage();
    };

    private navOrderDetail: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        this.cOrder.openOrderDetail(Number(params.orderId));
        defaultRemoveLocalStorage();
    };

    private navCouponManage: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        this.cMe.openCouponManage();
        defaultRemoveLocalStorage();
    };

    private navFavorites: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        this.cMe.openFavorites();
        defaultRemoveLocalStorage();
    };

    private navPassword: NavPage = async (params: any) => {
        changeTDKByPathname();
        await this.assureLogin();
        await nav.changePassword();
    };

    private navMeInfo: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cMe.openMeInfo();
        defaultRemoveLocalStorage();
    };

    private navQuickOrder: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cQuickOrder.openQuickOrder();
        defaultRemoveLocalStorage();
    };

    private navPointProduct: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cPointProduct.openPointProduct(params);
    };

    private navPointProductDetail: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cPointProduct.openPointProductDetail(params);
        defaultRemoveLocalStorage();
    };

    private navPointProductOrder: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cPointProduct.openExchangeHistory();
        defaultRemoveLocalStorage();
    };

    private navPointProductOrderDetail: NavPage = async (params: any) => {
        changeTDKByPathname();
        let { id } = params;
        this.cPointProduct.openOrderDetail(Number(id));
        defaultRemoveLocalStorage();
    };

    private navUploadCertificate: NavPage = async (params?: any) => {
        changeTDKByPathname();
        this.certificate.openUpLoadCertificate();
        defaultRemoveLocalStorage();
    };

    private navUploadCertificateHistory: NavPage = async (params?: any) => {
        changeTDKByPathname();
        this.certificate.openCertificateHistory();
        defaultRemoveLocalStorage();
    };

    private navProductstructure: NavPage = async (params: any) => {
        changeTDKByPathname();
        this.cProduct.openStructuredSearch();
        defaultRemoveLocalStorage();
    };

    private orderSettle: NavPage = async (params?: any) => {
        let type: any = params?.type || 1;
        if (type == 2) {
            await this.cQuickOrder.overOrder2();
        } else {
            if (!this.store.cartCount) {
                window.location.href = "/cart";
                return;
            };
            /*
            若不是在路由处调用,那么就需要执行下列三行;
            */
            /* await this.store.initCart();
            await this.store.buildCartItems();
            */
            await this.cSelectShippingContact.getContactList();
            this.cCart.checkOut();
        }

        defaultRemoveLocalStorage();
    };

    private pendingHandleExchange: NavPage = async (params?: any) => {
        await this.cPointProduct.openExchangeOrder();
        defaultRemoveLocalStorage();
    };

    protected onNavRoutes() {
        let routes: { [route: string]: NavPage } = {
            "/app": this.navHome,
            "/index": this.navHome,
            "/home": this.navHome,
            "/search/structure": this.navProductstructure,
            "/search/:key": this.navSearch,
            "/search": this.navProductCategoryHome,
            "/product/:id": this.navProduct,
            "/product/mscu/:type/:id": this.navProductMSCU,
            "/product/mscu/:type": this.navProductMSCU,
            "/cart": this.navCart,
            "/cart/orderSettle": this.orderSettle,
            "/cart/orderSettle/:type": this.orderSettle,
            "/product-catalog/:id": this.navProductCategory,
            "/product-catalog": this.navProductCategoryHome,
            "/pointshop": this.navPointShop,
            "/pointshop/exchangeSettle": this.pendingHandleExchange,

            "/about": this.navAbout,
            "/me": this.navMe,

            "/contact": this.navContactList,
            "/invoice": this.navInvoice,
            "/myOrders": this.navMyOrders,
            "/orderDetail/:orderId": this.navOrderDetail,
            "/couponManage": this.navCouponManage,
            "/favorites": this.navFavorites,
            "/password": this.navPassword,
            "/meInfo": this.navMeInfo,

            "/quickOrder": this.navQuickOrder,

            "/pointshop/productLine/:id": this.navPointProduct,
            "/pointshop/product/:id": this.navPointProductDetail,
            "/pointshop/order": this.navPointProductOrder,
            "/pointshop/orderDetail/:id": this.navPointProductOrderDetail,

            "/upload/certificate": this.navUploadCertificate,
            "/upload/certificateHistory": this.navUploadCertificateHistory,
        };
        nav.onNavRoutes(routes);
        this.setHomeRoute();
    }

    setHomeRoute() {
        nav.onNavRoute(this.navHome);
    }

    async assureLogin(): Promise<void> {
        if (this.isLogined) return;
        return new Promise<void>((resolve, reject) => {
            let loginCallback = async (user: User) => {
                if (this.isWebNav) {
                    window.history.back();
                } else {
                    this.closePage(1);
                }
                resolve();
            };
            nav.showLogin(loginCallback, true);
        });
    }

    protected async onChangeLogin(user: User) {
        if (user) {
            await this.initLogined(user);
        } else {
            this.setCookieUserInfo({
                id: 0,
                name: "",
                isLogin: false,
                cartCount: 0,
            });
            this.initNotLogined();
        }
        await this.initUQs();
        await this.store.initCart();
        await this.setCookieUserInfo();
    }

    private async initLogined(user: User) {
        this.currentUser = new WebUser(this.uqs);
        await this.currentUser.setUser(this.user);
        this.store.currentUser = this.currentUser;
        await defaultRemoveLocalStorage();
        /*
        // 如果currentUser，也必须重置
        //if (this.currentUser === undefined)
        this.currentUser = new WebUser(this.uqs);
        await this.currentUser.setUser(user);
        await this.cart.mergeFromRemote();
        */
    }

    private initNotLogined() {
        // 退出的话把购物车清掉？
        this.currentUser = undefined;
        /* 切换账户,cOrder内容未清空,快捷方式 直击实例赋值 */
        this.cOrder = this.newC(COrder);
        /*
        localStorage.removeItem(LOCALCARTNAME);
        this.cart.count.set(0);
        this.cart.cartItems = [];
        */
    }

    protected onDispose() {
        this.store.dispose();
    }

    protected setCookieUserInfo(arg?: any) {
        let param: any = arg || {
            id: this.currentUser?.id || 0,
            name: this.user?.name,
            isLogin: this.isLogined || false,
            cartCount: this.store.cartCount,
        };
        let str: string = Encrypt(param);
        Cookie.save("jkUserInfo", str, { path: "/" });
        // Cookie.save("_jwt_", centerToken, { path: "/" });
    }
}
