import { BoxId, RowContext, QueryPager, nav, Tuid } from 'tonva-react';
import { CApp, CUqBase } from 'tapp';
import { observable, makeObservable } from 'mobx';
import { VPointProduct, VSelectedPointProduct } from 'pointMarket/VPointProduct';
import { VExchangeOrder } from './VExchangeOrder';
import { VMyPoint } from './VMyPoint';
import { CSelectShippingContact } from 'customer/CSelectContact';
import { OrderSuccess } from './OrderSuccess';
import { pointOrder, OrderItem } from './pointOrder';
import { VExchangeHistoryDetail } from './VExchangeHistoryDetail';
import { VExchangeHistory } from './VExchangeHistory';
import { VRevenueExpenditure } from './VRevenueExpenditure';
import { VPointProductDetail } from './VPointProductDetail';
import { VSelectedLable } from './VSelectedLable';
import { GLOABLE } from 'global';
import { VDefaultPost } from './view/VDefaultPost';
import moment from 'moment';
import { VShopSideBar } from './view/VShopSideBar';
import { VError } from 'tools/VError';
import { DxExchangeMainState } from '../uq-app/uqs/Jk积分商城/Jk积分商城';
import { EnumExchangeState } from 'uq-app/uqs/JkPointshop';
import { defaultRemoveLocalStorage } from 'tools/browser';

export const topicClump = {
    productGenre: '产品分类',
    newRecommend: '新品推荐',
    hotProduct: '热门产品',
}

export const Arr: any[] = [
    { id: 5000, name: topicClump.newRecommend, typeName: topicClump.newRecommend },
    { id: 5001, name: topicClump.hotProduct, typeName: topicClump.hotProduct },
];

function Arrtoobj(arr: any[]) {
    let obj: any = {};
    arr.forEach((el: any) => {
        obj[el.id] = el;
    });
    return obj;
}

export const topicClumps: { [id: number]: { id: number, name: string, typeName: string } } = Arrtoobj(Arr);

export const OrderSource = {
    EXCHANGEORDER: '兑换订单',
    PRIZEORDER: '奖品订单',
}

export const PointIntervals: { [state: string]: { startPoint: number, endPoint: number } } = {
    "below": { startPoint: 0, endPoint: 10000 },
    "firstLevel": { startPoint: 10000, endPoint: 50000 },
    "twoLevel": { startPoint: 50000, endPoint: 150000 },
    "above": { startPoint: 150000, endPoint: Infinity },
}

const PointShopCart: string = "point_shop_cart";

export class CPointProduct extends CUqBase {

    myPoints: any[] = [];                  /* 我的积分 */
    myEffectivePoints: number = 0;         /* 我的积分(计算后) */
    myTotalPoints: number = 0;             /* 我的积分(计算后) */
    myPointTobeExpired: number = 0;        /* 我的快过期积分 */

    pointProducts: any[] = [];             /* 可兑产品列表 */
    newPointProducts: any[] = [];          /* 新品推荐 */
    hotPointProducts: any[] = [];          /* 热门产品 */
    pointProductsSelected: any[] = [];     /* 已选择产品列表 */
    pointProductsDetail: any;              /* 详情产品 */
    pointToExchanging: number = 0;              /* 将要兑换的积分总计 */
    orderData: pointOrder = new pointOrder();   /* 正在提交的产品列表*/
    couponId: number;                      /* 积分码 */
    platformOrderId: any;                  /* 平台合同号 */
    platformOrder: any[] = [];             /* 平台合同 */
    pagePointHistory: QueryPager<any>;     /* 积分详情 */
    pointProductGenre: any[] = [];         /* 产品类型列表 */

    visible: boolean = false;

    constructor(cApp: CApp) {
        super(cApp);

        makeObservable(this, {
            myPoints: observable,
            myEffectivePoints: observable,
            myTotalPoints: observable,
            myPointTobeExpired: observable,
            pointProducts: observable,
            newPointProducts: observable,
            hotPointProducts: observable,
            pointProductsSelected: observable,
            pointProductsDetail: observable,
            pointToExchanging: observable,
            orderData: observable,
            couponId: observable,
            platformOrderId: observable,
            platformOrder: observable,
            pagePointHistory: observable,
            pointProductGenre: observable,
            visible: observable,
        });
    }

    protected async internalStart(param?: any) { }

    /**
     * 刷新积分
     */
    refreshMypoint = async () => {
        let { currentUser } = this.cApp;
        if (!currentUser) return;
        this.myPoints = await currentUser.getPoints();
        this.myEffectivePoints = this.myPoints.reduce((v, e) => { return v + e.effectiveLeftPoint }, 0);
        this.myTotalPoints = this.myPoints.reduce((v, e) => { return v + e.totalLeftPoint }, 0)
        let date = new Date();
        let dateYear = date.getFullYear();
        this.myPointTobeExpired = this.myPoints.reduce((v, e) => { return v + ((dateYear - e.pointYear) > 1 ? e.effectiveLeftPoint : 0) }, 0);
    }

    /**
     * 初始化可兑换产品列表(暂时弃用)
     */
    initPointProducts = () => {
        if (this.pointProductsSelected.length) {
            for (let i of this.pointProductsSelected) {
                this.pointProducts.forEach((el: any) => {
                    if (i.product.id === el.product.id) el.quantity = i.quantity;
                });
            }
        }
    }

    /**
     * 清空选择的积分产品
     */
    clearSelectedPointsProducts = () => {
        this.orderData.exchangeItems = undefined;
        this.pointProductsSelected.length = 0;
        this.pointToExchanging = 0;
        localStorage.removeItem(PointShopCart);
    }

    /**
     * 积分管理页面
     */
    openMyPoint = async (param?: any) => {
        await this.refreshMypoint();        /* 刷新积分 */
        await this.getPointProductGenre();  /* 获取产品类型 */
        this.newPointProducts = await this.getNewPointProducts();    /* 获取新品推荐 */
        this.hotPointProducts = await this.getHotPointProducts();    /* 获取热门产品 */
        await defaultRemoveLocalStorage();
        this.openVPage(VMyPoint);
    }

    /**
     * 可兑换产品页面
     */
    openPointProduct = async (param?: any) => {
        let result: any;
        if (param) {
            let { id } = param;
            result = topicClumps[id];
            if (!result) {
                let res: any = await this.uqs.积分商城.Genre.load(Number(id));
                result = res ? { ...res, typeName: topicClump.productGenre } : undefined;
            };
            if (result) {
                this.pointProducts = await this.getPointProductByDifferentPlot(result);
            };
        };
        await this.getPointProductGenre();
        await this.refreshMypoint();
        await defaultRemoveLocalStorage();
        this.openVPage(VPointProduct, result);
    }

    /**
     * 可兑换产品的详情(可生成浏览量)
     */
    openPointProductDetail = async (pointProduct: any) => {
        let { id, product } = pointProduct;
        if (!product) pointProduct.product = await this.uqs.积分商城.PointProductLib.boxId(Number(id));
        this.pointProductsDetail = pointProduct;
        if (pointProduct.product) {
            if (this.pointProductsSelected.length) {
                for (let i of this.pointProductsSelected) {
                    if (pointProduct.product.id === i.product.id)
                        this.pointProductsDetail.quantity = i.quantity;
                }
            } else
                this.pointProductsDetail.quantity = 0;
            let fm = 'YYYY-MM-DD HH:mm:ss';
            let findProduct = await this.getPointProductLibLoad(pointProduct.product.id);
            if (findProduct !== undefined && moment(undefined, fm) >= moment(findProduct.endDate, fm))
                this.pointProductsDetail.OffShelf = true;/* 是否可兑换（无法兑换） */
            this.pointProductsDetail.htmlFragment = await this.getPointProductDetailFragment(this.pointProductsDetail);
            await this.setPointProductVisits(pointProduct.product?.obj);//生成浏览量
        };
        await this.refreshMypoint();
        await this.getPointProductGenre();
        this.openVPage(VPointProductDetail);
    }

    renderShopSideBar = () => {
        return this.renderView(VShopSideBar);
    }

    onContactSelected = async (contact: BoxId) => {
        this.orderData.shippingContact = contact;
        this.visible = false;
    }

    renderContnet = () => {
        return this.cApp.cSelectShippingContact.renderContentList("pointOrder");
    }

    /**
     * 据商品id 获取对应的商品所有信息
     */
    getPointProductLibLoad = async (id: number) => {
        return await this.uqs.积分商城.PointProductLib.load(id);
    }

    /**
     * 获取积分商品详情的html片段(编译后)
     */
    getPointProductDetailFragment = async (pointProduct: any) => {
        // this.pointProductsDetail.htmlFragment = '<div style="color:red;text-align:center;margin-top:2rem;">帖文 待开发</div>';
        try {
            let result = await window.fetch(GLOABLE.CONTENTSITE + '/partial/pointproductdetail/' + pointProduct.product.id);
            if (result.ok) {
                let content = await result.text();
                return content;
            } else {
                return '';
            }
        } catch (error: any) {
            return '';
        }
    };

    /**
     * 积分收支明细页面
     */
    openRevenueExpenditure = async (topic?: any) => {
        await this.getPointHistory();
        this.openVPage(VRevenueExpenditure, topic)
    }


     searchExchangeOrders = async (state?: number) => {
        let result: QueryPager<any> = new QueryPager<any>(this.uqs.JkPointshop.SearchExchangeOrders, 1000, 1000);
        await result.first({
            customer: this.cApp.currentUser?.currentCustomer,
            state: state,
        });
        return result.items;
    }

    /**
     * 获取不同状态的兑换单
     */
    getMyPointOrder = async (state: number) => {
        let result: any[] = [];
        let isState: boolean = [EnumExchangeState.delivering, EnumExchangeState.delivered, EnumExchangeState.canceled, 0].includes(state);
        if (isState) {
            if (state === 0) state = undefined;
            result = await this.searchExchangeOrders(state);
            if (state === EnumExchangeState.canceled || !state) {
                let getCanceledOrder: any[] = await this.uqs.JkPointshop.PointExchangeSheet.getStateSheets("canceled", 1, -100);
                getCanceledOrder.forEach((el: any) => { el.orderType = "orderSheet"; el.state = "canceled" });
                result = result.concat(getCanceledOrder);
            };
            result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        };
        return result;
    };

    /**
     * 积分兑换记录页面
     */
    openExchangeHistory = async () => {
        /* let promises: PromiseLike<any>[] = [
            this.uqs.积分商城.PointExchangeSheet.mySheets(undefined, 1, -10),
            this.uqs.积分商城.PointExchangeSheet.mySheets("#", 1, -100)
        ];
        let presult = await Promise.all(promises);
        let exchangeHistory = presult[0].concat(presult[1]); */
        /* let { JkPointshop, 积分商城 } = this.uqs;
        let result = new QueryPager<any>(积分商城.SearchExchangeOrders, 1000, 1000);
        await result.first({
            customer: this.cApp.currentUser?.currentCustomer
        });
        let exchangeHistorys: any[] = result?.items || []; */
        this.openVPage(VExchangeHistory);
    }

    /**
     * 历史兑换单详情页面
     */
    openOrderDetail = async (orderId: number, orderType?: string) => {
        // let order: any = await this.uqs.积分商城.PointExchangeSheet.getSheet(orderId);
        /* if (!order || (this.user?.id !== order?.brief?.user)) {
            this.openVPage(VError);
            return;
        }; */
        /*
         * 此处判断存在问题,若是通过url访问,如何判断数据源从什么地方读取?
         * 现在根据orderId值的大小看数据源, 大于100万的id,从ExchangeMain读取,否则从sheet读取
         * 这是暂时的处理方法,后续可以再看如何调整改进.
         */
        try {
            let order: any = {};
            if (orderType === "orderSheet" || orderId <= 1000000) {
                order = await this.uqs.积分商城.PointExchangeSheet.getSheet(orderId);
            } else {
                let { JkPointshop, customer: customerUQ, JkDeliver } = this.uqs;
                order = { brief: {}, data: {} };
                let getOrder: any[] = await JkPointshop.IDDetailGet({
                    id: orderId,
                    main: JkPointshop.ExchangeMain,
                    detail: JkPointshop.ExchangeDetail,
                });
                let [mainArr, orderDetail] = getOrder;
                if (!this.user || !mainArr.length || !orderDetail.length || (mainArr.length && mainArr[0].customer !== this.cApp.currentUser?.currentCustomer?.id)) {
                    this.openVPage(VError); return;
                };
                let getDxExchagneMainState: any[] = await JkPointshop.ID<DxExchangeMainState>({ IDX: JkPointshop.DxExchangeMainState, id: orderId });
                let { id, no, createDate, shippingContact, amount, customer } = mainArr[0];
                let { state } = getDxExchagneMainState[0] || { state: undefined };
                order.brief = { id: id, no: no, date: createDate, state: state };
                customer = customerUQ.Customer.boxId(customer);
                shippingContact = customerUQ.Contact.boxId(shippingContact);
                let ids = orderDetail.map((el: any) => el.id);
                let getDeliverDetailExchangeDetail = await this.uqs.JkPointshop.IX({ IX: JkPointshop.ExchangeDetailDeliver, ix: ids });
                let promise: PromiseLike<any>[] = [customer, shippingContact];
                orderDetail.forEach((el: any) => {
                    let findD: any = getDeliverDetailExchangeDetail.find((i: any) => i.ix === el.id);
                    el.deliverDetail = findD?.xi;
                    el.product = JkPointshop.PointProductLib.boxId(el?.item);
                    let getExchangeTransportation: any = JkDeliver.GetDeliverDetailTransportation.obj({ deliverDetail: el?.deliverDetail });
                    getExchangeTransportation.then((data: any) => el.transportation = data || undefined);
                    promise.push(getExchangeTransportation);
                    promise.push(el.product);
                });
                await Promise.all(promise);
                let exchangeItems = orderDetail.map((el: any) => {
                    let { product, point, quantity, subAmount, id, transportation } = el;
                    return { param: { id: id, transportation: transportation }, point: point, product: product, quantity: quantity, subAmount: subAmount };
                });
                order.data = {
                    amount: amount,
                    customer: customer,
                    exchangeItems: exchangeItems,
                    shippingContact: shippingContact,
                };
            };
            this.openVPage(VExchangeHistoryDetail, order);
        } catch (error: any) {
            this.openVPage(VError);
        };
    };

    /**
     * 已选择的可兑换产品页面
     */
    openSelectedPointProduct = async () => {
        this.pointProductsSelected = this.pointProductsSelected.filter(v => v.quantity !== 0);
        this.openVPage(VSelectedPointProduct);
    }

    getPendingExchange = async () => {
        let data: any = localStorage.getItem(PointShopCart);
        if (!data) return;
        await this.refreshMypoint();
        let { JkPointshop } = this.uqs;
        let res: any[] = JSON.parse(data);
        res = res.filter((el: any) => el.id && el.quantity !== 0);
        res.forEach((el: any) => {
            el.product = JkPointshop.PointProductLib.boxId(el.id);
        });
        let promise: PromiseLike<any>[] = [];
        res.forEach((el: any) => {
            promise.push(el.product?.assure());
        });
        await Promise.all(promise);
        this.pointProductsSelected = res.map((el: any) => {
            return { point: el.product.obj?.point || el.point, product: el.product, quantity: el.quantity };
        });
        this.pointToExchanging = this.pointProductsSelected.reduce((pv, cv) => (pv + cv.quantity * cv.point), 0);
    };

    /**
     * 确认兑换订单预览页面
     */
    openExchangeOrder = async () => {
        /* let arr: any[] = [
            { id: 514, quantity: 50, point: 200 },
        ];
        localStorage.setItem(PointShopCart, JSON.stringify(arr)); */
        if (this.orderData.shippingContact === undefined) {
            this.orderData.shippingContact = await this.getDefaultShippingContact();
        };
        await this.getPendingExchange();
        this.pointProductsSelected = this.pointProductsSelected.filter(v => v.quantity !== 0);
        await this.cApp.cSelectShippingContact.getContactList();
        this.openVPage(VExchangeOrder);
    }

    /**
     * 已选择的可兑换产品图标
     */
    renderSelectedLable() {
        return this.renderView(VSelectedLable);
    }

    /**
     * 默认帖文
     */
    renderVDefaultPost() {
        return this.renderView(VDefaultPost);
    }

    // /*领取积分 */
    // openPointDrawing = async (credits?: string) => {
    //     /*
    //     let lastPlatformId = await this.getLastPlatformOrder();
    //     if (lastPlatformId)
    //         this.platformOrder = await this.getPlatFormOrder(this.platformOrderId);
    //     */
    //     this.openVPage(VPlatformOrderPoint, credits);
    // }

    /* 展示 积分详情*/
    /* pointDetails = async () => {
        this.getPointHistory();
        this.openVPage(VPointDetails);
    } */

    /**
     * 浏览商品(数据埋点,生产浏览量PV)
     */
    setPointProductVisits = async (pointProduct: any) => {
        return this.uqs.积分商城.SetPointProductVisits.submit({ pointProduct });
    }

    /**
     * 获取产品类型
     */
    getPointProductGenre = async () => {
        let { Genre } = this.uqs.积分商城;
        this.pointProductGenre = await Genre.all();
    }

    /**
     * 获取积分详情的数据
     */
    getPointHistory = () => {
        let { currentUser } = this.cApp;
        this.pagePointHistory = new QueryPager(this.uqs.积分商城.GetPointHistory, 15, 30);
        this.pagePointHistory.first({ customer: currentUser?.currentCustomer, key: "" });
    }

    /**
     * 获取可兑换产品(据 分类、新品推荐、热门产品)
     */
    getPointProductByDifferentPlot = async (plot: any) => {
        let { productGenre, newRecommend, hotProduct } = topicClump;
        switch (plot?.typeName) {
            case productGenre:
                return await this.filterByProductGenre(plot);
            case newRecommend:
                return await this.getNewPointProducts();
            case hotProduct:
                return await this.getHotPointProducts();
            default:
                return;
        };
    }

    /**
     * 据类型筛选商品
     */
    filterByProductGenre = async (currentGenre: any) => {
        // let pointProductFromGenre = await this.getProductsFromGenre(currentGenre);
        let pointProductFromGenre = await this.uqs.积分商城.GetPointProductByGenre.table({ genre: currentGenre });
        return pointProductFromGenre.map((v) => {
            return { genre: v.genre, product: v.pointProduct, point: v?.point, imageUrl: v?.imageUrl };
        });
    }

    /**
     * 获取指定分类所属的商品（废弃）
     */
    getProductsFromGenre = async (genre: any) => {
        return await this.uqs.积分商城.PointProductGenre.table({ genre, pointproduct: undefined });
    }

    /**
     * 获取新品推荐的产品
     */
    getNewPointProducts = async () => {
        let result = await this.uqs.积分商城.GetNewPointProducts.table({});
        return result.sort((a, b) => a.point - b.point).map((v: any) => { return { product: v.id, imageUrl: v?.imageUrl, point:v?.point } });
    }

    /**
     * 获取热门产品
     */
    getHotPointProducts = async () => {
        let result = await this.uqs.积分商城.GetHotPointProducts.table({});
        return result.sort((a, b) => a.point - b.point).map((v: any) => { return { product: v.id, imageUrl: v?.imageUrl, point:v?.point } });
    }

    /**
     * 获取积分区间的积分产品(积分划分)
     */
    getPointsIntervalProducts = async (state: any) => {
        let pointInterval = PointIntervals[state] || PointIntervals["below"];
        this.pointProducts = await this.uqs.积分商城.GetPointProduct.table(pointInterval);
        return this.pointProducts;
    }

    /**
     * 获取商品源（兑换下单可用）
     */
    getProductSources = async (pointProduct: any) => {
        return await this.uqs.积分商城.PointProductSource.obj({ pointProduct, sourceId: undefined });
    }

    onQuantityChanged = async (context: RowContext, value: any, prev: any) => {
        let { data } = context;
        let IsContain = 0;
        let nowQuantity = value - (prev ? prev : 0);
        if (Tuid.equ(this.pointProductsDetail.product,data.product))
            this.pointProductsDetail.quantity = value;
        let findSelected = this.pointProductsSelected.find((el: any) => el && el.product.id === data.product.id);
        if (findSelected) {
            IsContain = IsContain + 1;
            findSelected.quantity = data.quantity;
        };
        if (IsContain === 0) {
            data.point = data.product.obj.point;
            this.pointProductsSelected.push(data);
        };
        this.pointToExchanging = this.pointProductsSelected.reduce((pv, cv) => (pv + cv.quantity * cv.point), 0);
        this.pointProductsSelected = this.pointProductsSelected.filter((el: any) => el.quantity !== 0);

        /* let availablePoints = this.myEffectivePoints - this.pointToExchanging;
        if (availablePoints <= 0) return;    */
        // 当前产品详情的数量
        /* this.pointProductsDetail.quantity = value;
        // this.pointToExchanging = this.pointToExchanging + (data.point * nowQuantity);
        this.pointToExchanging = this.pointToExchanging + (data.product.obj.point * nowQuantity);
        this.pointProductsSelected.forEach(element => {
            if (element.product.id === data.product.id) {
                element.quantity = element.quantity + nowQuantity;
                IsContain = IsContain + 1;
            }
        });
        if (IsContain === 0) {
            data.point = data.product.obj.point;
            this.pointProductsSelected.push(data);
        } */
    }

    private createOrderFromCart = async () => {
        let { currentUser, currentSalesRegion } = this.cApp;
        this.orderData.webUser = currentUser.id;
        this.orderData.salesRegion = currentSalesRegion.id;
        if (currentUser.currentCustomer !== undefined) {
            this.orderData.customer = currentUser.currentCustomer.id;
        }
        if (this.pointProductsSelected !== undefined && this.pointProductsSelected.length > 0) {
            this.orderData.exchangeItems = this.pointProductsSelected.map(e => {
                if (e.quantity <= 0) return undefined;
                var item = new OrderItem();
                item.product = e.product;
                // item.pack = e.pack;
                item.quantity = e.quantity;
                item.point = e.point;
                return item;
            });
        }
    }
    /**
     * 生成兑换单兑换积分
     */
    submitOrder = async () => {
        this.createOrderFromCart();

        let PointExchangeSheet = this.uqs.积分商城.PointExchangeSheet;
        let getDataForSave = this.orderData.getDataForSave();
        let result: any = await PointExchangeSheet.save("PointExchangeSheet", getDataForSave);
        let { id, flow, state } = result;
        await PointExchangeSheet.action(id, flow, state, "submit");

        //暂时扣除已经兑换的积分;
        this.myEffectivePoints = this.myEffectivePoints - this.pointToExchanging;
        this.myTotalPoints = this.myTotalPoints - this.pointToExchanging;
        if (this.pointToExchanging > this.myPointTobeExpired) {
            this.myPointTobeExpired = 0;
        } else {
            this.myPointTobeExpired = this.myPointTobeExpired - this.pointToExchanging;
        }

        //兑换后清空选择的积分产品
        /* this.orderData.exchangeItems = undefined;
        this.pointProductsSelected.length = 0;
        this.pointToExchanging = 0; */
        this.clearSelectedPointsProducts();

        // 打开下单成功显示界面
        nav.popTo(this.cApp.topKey);
        this.openVPage(OrderSuccess, result);
    }

    /**
     * 获取当前webuser对应customer的最近一个订单 TODO:delete
    getLastPlatformOrder = async () => {

        let { currentCustomer } = this.cApp.currentUser;
        let validationResult = await this.uqs.积分商城.GetLastPlatFormOrder.submit({ customer: currentCustomer && currentCustomer.id });
        let { platformOrderId } = validationResult;
        this.platformOrderId = validationResult.platformOrderId;
        return platformOrderId;
    }
    */

    /*
    getPlatFormOrder = async (platformOrderId: string) => {
        return await this.uqs.积分商城.GetPlatFormOrder.table({ platformOrderId: platformOrderId });
    }
    */

    IsCouponCanUse = async (couponCode: string) => {
        this.couponId = 0;
        let { currentUser, cCoupon } = this.cApp;
        // let { salesTask } = this.uqs;
        let validationResult = await cCoupon.getCouponValidationResult(couponCode);
        // let validationResult = await salesTask.IsCanUseCoupon.submit({ code: couponCode, webUser: currentUser && currentUser.id });

        let { result, id, types } = validationResult;
        if (result === 1) {
            this.couponId = id;
            if (types !== 'credits')
                result = 0;
        }
        return result;
    }

    /**
     * 领取积分码 TODO:delete
    receivePoint = async (orderId: string) => {
        let { currentCustomer } = this.cApp.currentUser;
        let { AddPlatformOrderPoint } = this.uqs.积分商城;
        let result = await AddPlatformOrderPoint.submit({ orderId: orderId, couponId: this.couponId, customer: currentCustomer && currentCustomer.id });
        let rtn = result.result;

        return rtn;
    }
    */

    private openMeInfoOptions: any;
    /**
     * 检查客户信息是否完善（不完善需补充完善后方可领取积分）
     */
    userInfoCompletedChecking = async (options: any): Promise<boolean> => {
        await this.cApp.assureLogin();
        let { cMe, currentUser } = this.cApp;
        if (!currentUser.allowOrdering) {
            cMe.openMeInfoFirstOrder(options);
            return false;
        }
        return true;
        /*
        if (!this.isLogined) {
            this.openMeInfoOptions = options;
            nav.showLogin(this.loginCallback, true);
            return false;
        } else {
            let { cMe, currentUser } = this.cApp;
            if (!currentUser.allowOrdering) {
                cMe.openMeInfoFirstOrder(options);
                return false;
            }
            return true;
        }
        */
    }
    /*
    private loginCallback = async (user: User): Promise<void> => {
        let { cApp } = this;
        await cApp.currentUser.setUser(user);
        await cApp.loginCallBack(user);
        this.closePage(1);

        let { cMe, currentUser } = this.cApp;
        if (!currentUser.allowOrdering) {
            cMe.openMeInfoFirstOrder(this.openMeInfoOptions);
        }
    };
    */
    /**
     * TODO: delete
     */
    addUsedCoupon = async () => {
        /* let { AddUsedCoupon } = this.uqs.积分商城;
        let result = await AddUsedCoupon.submit({ couponId: this.couponId });
        let rtn = result.result;
        return rtn; */
        return;
    }

    private defaultSetting: any;
    private async getDefaultSetting() {
        if (this.defaultSetting) return this.defaultSetting;
        let { currentUser } = this.cApp;
        return this.defaultSetting = await currentUser.getSetting() || {};
    }
    private contact0: BoxId;
    private async getContact(): Promise<BoxId> {
        if (this.contact0 === null) return;
        if (this.contact0 !== undefined) return this.contact0;
        let { currentUser } = this.cApp;
        let contactArr = await currentUser.getContacts();
        if (contactArr === undefined || contactArr.length === 0) {
            this.contact0 = null;
            return;
        }
        return this.contact0 = contactArr[0].contact;
    }
    async getDefaultShippingContact() {
        let defaultSetting = await this.getDefaultSetting();
        return defaultSetting.shippingContact || await this.getContact();
    }

    selectContact = async () => {
        let cSelect = this.newC(CSelectShippingContact);
        let contactBox = await cSelect.call<BoxId>(true);
        return contactBox;
    }

    onSelectShippingContact = async () => {
        this.orderData.shippingContact = await this.selectContact()
    }
}