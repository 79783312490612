// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { BatchInputDx } from "./JkPlatformjoint";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	count: {
		"name": "count",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "Count"
	} as FieldItemInt,
	success: {
		"name": "success",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "Success"
	} as FieldItemInt,
	failed: {
		"name": "failed",
		"type": "integer",
		"isKey": false,
		"widget": "updown",
		"label": "Failed"
	} as FieldItemInt,
	enddate: {
		"name": "enddate",
		"isKey": false,
		"label": "Enddate"
	} as undefined,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.count, fields.success, fields.failed, fields.enddate, 
];

export const ui: UI = {
	label: "BatchInputDx",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: BatchInputDx):JSX.Element {
	return <>{uqStringify(item)}</>;
};
