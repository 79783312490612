import _ from "lodash";
import { ApiEntity, Hosts } from "./entity";

export enum rootBase {
    default = "api",
    hr = "HR/api/Request",
}

class ApiV2 extends ApiEntity {
    protected version: string = "v2/EB"; // localhost、shop -v2/SD
    protected root: string = rootBase.default;

    /**
     * base为接口前缀，如：https://shop.jkchemical.com/v2/SD/api
     * env.testing === true
     */
    protected get base() {
        return `${this.env.testing ? Hosts.test : Hosts.shop}/${this.version}/${
            this.root
        }`;
    }

    orderApi = {
        getOrdertrans: "/Deliver/GetDeliverInfoByNo/",
    };

    productApi = {
        getProductsByOrigin: "",
    };
}

export const apiV2 = new ApiV2();

class EBApi extends ApiV2 {
    protected version: string = "v2/EB";
    protected root: string = rootBase.default;
}

export const apiEB = new EBApi();
