// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { ResearchGroup } from "./JkCustomer";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	organization: {
		"name": "organization",
		"type": "id",
		"isKey": false,
		"label": "Organization"
	} as FieldItemId,
	name: {
		"name": "name",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Name"
	} as FieldItemString,
	managerCustomer: {
		"name": "managerCustomer",
		"type": "id",
		"isKey": false,
		"label": "ManagerCustomer"
	} as FieldItemId,
	isValid: {
		"name": "isValid",
		"isKey": false,
		"label": "IsValid"
	} as undefined,
	creator: {
		"name": "creator",
		"type": "id",
		"isKey": false,
		"label": "Creator"
	} as FieldItemId,
	createDate: {
		"name": "createDate",
		"isKey": false,
		"label": "CreateDate"
	} as undefined,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.organization, fields.name, fields.managerCustomer, fields.isValid, fields.creator, fields.createDate, 
];

export const ui: UI = {
	label: "ResearchGroup",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: ResearchGroup):JSX.Element {
	return <>{uqStringify(item)}</>;
};
